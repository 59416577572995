import React, { useEffect, useContext } from "react";
import ChatBoxTitle from "./ChatBoxTitle";
import ChatBoxContent from "./ChatBoxContent";
import { SocketContext } from "../../context/socket";
import "../../assets/css/ChatBox.css";
import {
  browserName,
  osName,
  deviceType,
  isMobile,
  isTablet,
} from "react-device-detect";
const ChatBox = () => {
  const socket = useContext(SocketContext);

  return (
    <>
      {
        isMobile ? <div className="chatbox-mobile d-flex flex-column justify-content-start">
          <ChatBoxTitle />
          <ChatBoxContent />
        </div> : <div className="chatbox">
          <div className="chatbox-inner">
            <ChatBoxTitle />
            <ChatBoxContent />
          </div>
        </div>
      }
    </>

  );
};

export default ChatBox;
