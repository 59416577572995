import React, { useEffect, useContext, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import plugin from "../../../assets/images/Plugin.svg";
import workingBoy from "../../../assets/images/workingBoy.svg";
import '../../../assets/css/WelcomeModal.css'

const WelcomeModal = () => {
    const { t } = useTranslation();
    return (
        <div style={{
            // width: '300px'
        }}>
            {/* <Modal
                show={modalShow}
                onHide={handleModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton style={{ borderBottom: 'none' }} className="mx-2">
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2 style={{
                            fontWeight: '600',
                        }}>Welcome to WP-SOFA</h2>
                    </Modal.Title>
                </Modal.Header>
                <div className="p-4">
                    <h6 className="pb-4" style={{
                        width: '274px',
                        height: '21px',
                    }}>Wordpress Gift Card Plugin Support</h6>
                    <div style={{ display: 'flex' }}>
                        <div><img style={{ alignSelf: 'top' }} className="pb-4" src={plugin} alt="gift card plugin" /></div>
                        <div><img className="pb-4" src={workingBoy} alt="gift card plugin" /></div>
                    </div>
                    <p className="pb-4">Our team of experienced WordPress developers
                        is here to assist you with any plugin-related issues you may encounter. </p>
                    <button className="new-task-button" style={{ width: '100%', borderRadius: '8px', padding: '8px, 16px, 8px, 16px' }}>Take a quick tour in WP-SOFA</button>
                </div>
            </Modal> */}
            <h1 style={{fontWeight: '600', fontSize: '30px'}}>Welcome to WP-SOFA</h1>
            <h6 style={{marginBottom: '20px', marginTop: '15px', fontWeight: '500'}}>Wordpress Gift Card Plugin Support</h6>
            <div style={{ display: 'flex' }}>
                <div><img style={{ alignSelf: 'top'}} className="pb-4" src={plugin} alt="gift card plugin" /></div>
                <div><img className="pb-4" style={{}} src={workingBoy} alt="gift card plugin" /></div>
            </div>
            <p style={{lineHeight: '20px', marginBottom: '-20px'}}>Our team of experienced WordPress developers
                is here to assist you with any plugin-related issues you may encounter. </p>
        </div>
    )
}

export default WelcomeModal