import React from 'react'
import LoginIntroImg from "../assets/IMG/login-intro.png"
import LoginIntroPerson1 from "../assets/images/login-intro-person-1.png"
import LoginIntroPerson2 from "../assets/images/login-intro-person-2.png"
import "../assets/css/LoginIntro.css"
import { ReactTyped } from "react-typed";

const LoginIntro = () => {
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 7000); 

        return () => clearTimeout(timer);
    }, []);
    return (
        <div className="login-intro">
            <div className="login-intro-container">
                <h1 className="login-intro-title">Wordpress plugin support <br /> and wordpress help for <br /> everyone</h1>
                <img src={LoginIntroImg} className="login-intro-image" />
                <div className="login-intro-message-container">
                    <div className="login-intro-message login-intro-message-1 fade-in-fade-out">
                        <img src={LoginIntroPerson2}></img>
                        <ReactTyped
                            strings={[
                                "Hello! Could you please help with a WordPress plugin?"
                            ]}
                            typeSpeed={100}
                            backSpeed={100}
                            loop
                        />
                    </div>
                    {
                        isVisible && <div id='test' className="login-intro-message login-intro-message-2 fade-in-fade-out">
                            <img src={LoginIntroPerson1}></img>
                            <ReactTyped
                                strings={[
                                    "Sure, please provide additional details for an in-depth analysis."
                                ]}
                                typeSpeed={100}
                                backSpeed={100}
                                loop
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default LoginIntro