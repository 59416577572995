import React, { useState } from "react";
import Avatar from "react-avatar";
import UserAvatar from "../../assets/images/user.png";
import { API_URL } from "../../constants/constants";

export default function User({ user, avatar }) {
  return (
    <div className="mt-2 d-flex align-items-center">
      <img className="user-avatar" src={avatar} />
      {/* <Avatar className="user-avatar" name={user} round={true} size="50px" /> */}
      <div>{user}</div>
    </div>
  );
}
