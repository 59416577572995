import React, { useEffect, useState } from "react";
import "../../assets/css/ExtraServices.css";
import api from "../../utils/axios";
import { toast } from "react-toastify";
import { API_URL } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loading-icons";

const state = {
  TO_DO: 1,
  IN_PROGRESS: 2,
  DONE: 3,
};

export default function ExtraServices() {
  const { t } = useTranslation();

  const services = [
    {
      id: "support",
      text: t("support_from_us"),
      price: 50,
    },
    { id: "speedup", text: "Speed up your website", price: 30 },
    { id: "security", text: "Security check", price: 25 },
  ];

  const [originData, setOriginData] = useState({
    support: false,
    speedup: false,
    security: false,
  });

  const [data, setData] = useState({
    support: false,
    speedup: false,
    security: false,
  });

  const [subscribedServicesLength, setSubscribedServicesLength] = useState(0);
  const [availableServicesLength, setAvailableServicesLength] = useState(3);

  const [currentState, setCurrentState] = useState(state.TO_DO);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getExtraService = async () => {
      await api
        .get(`${API_URL}/api/v1/extraservice`)
        .then((res) => {
          const { support, speedup, security } = res.data;
          setData({
            support,
            speedup,
            security,
          });
          setOriginData({
            support,
            speedup,
            security,
          });
        })
        .catch((err) => {
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    };

    getExtraService();
  }, []);

  useEffect(() => {
    let tempSubscribedLength = 0;
    let tempAvailableLength = 0;
    if (originData.support === false) tempAvailableLength++;
    else tempSubscribedLength++;
    if (originData.speedup === false) tempAvailableLength++;
    else tempSubscribedLength++;
    if (originData.security === false) tempAvailableLength++;
    else tempSubscribedLength++;

    setSubscribedServicesLength(tempSubscribedLength);
    setAvailableServicesLength(tempAvailableLength);
  }, [originData]);

  const setExtraService = async () => {
    setCurrentState(state.IN_PROGRESS);
    await api
      .post(`${API_URL}/api/v1/extraservice`, data)
      .then((res) => {
        const { support, speedup, security } = res.data;
        setData({
          support,
          speedup,
          security,
        });
        setOriginData({
          support,
          speedup,
          security,
        });
        setTotal(0);
        setCurrentState(state.DONE);
        toast.success("Successfully changed!");
      })
      .catch((err) => {
        setData({ ...originData });
        setTotal(0);
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
        setCurrentState(state.TO_DO);
      });
  };

  const onChange = async (e, id, price) => {
    setCurrentState(state.TO_DO);
    let tempData = data;
    tempData[id] = e.target.checked;
    setData(tempData);

    let tempTotal = total;
    if (e.target.checked) {
      tempTotal = tempTotal + price;
    } else tempTotal = tempTotal - price;
    setTotal(tempTotal);
  };

  const onCancel = async (id) => {
    let tempData = { ...data };
    tempData[id] = false;
    await api
      .post(`${API_URL}/api/v1/extraservice`, tempData)
      .then((res) => {
        const { support, speedup, security } = res.data;
        setData({
          support,
          speedup,
          security,
        });
        setOriginData({
          support,
          speedup,
          security,
        });
        setTotal(0);
        setCurrentState(state.DONE);
        toast.success("Successfully cancelled!");
      })
      .catch((err) => {
        setData([...originData]);
        setTotal(0);
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
        setCurrentState(state.TO_DO);
      });
  };

  return (
    <div className="extra-services">
      {subscribedServicesLength > 0 && (
        <div className="mb-5">
          <div className="title">{t("subscribed_services")}</div>
          <div>
            <table>
              <tbody>
                {services.map(
                  (service) =>
                    originData[service.id] === true && (
                      <tr key={service?.id}>
                        <td>{service.text}</td>
                        <td className="price">{service.price}$</td>
                        <td
                          className="cancel"
                          onClick={() => onCancel(service.id)}
                        >
                          {t("cancel_renewal")}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
          <div className="explain-text">
            {t("your_next_billing_date")} December 16, 2022.
          </div>
        </div>
      )}
      {availableServicesLength > 0 && (
        <div>
          <div className="title">{t("available_services")}</div>
          <div className="explain-text">{t("get_billed_monthly")}</div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>{t("service")}</th>
                  <th className="monthly-price">{t("monthly_price")}</th>
                </tr>
              </thead>
              <tbody>
                {services.map(
                  (service) =>
                    originData[service.id] === false && (
                      <tr>
                        <td className="d-flex align-items-center">
                          <input
                            className="service-check"
                            type="checkbox"
                            id={service.id}
                            checked={data[service.id]}
                            onChange={(e) => {
                              onChange(e, service.id, service.price);
                            }}
                          />
                          <label htmlFor={service.id}>{service.text}</label>
                        </td>
                        <td className="price">{service.price}$</td>
                      </tr>
                    )
                )}
                {total > 0 && (
                  <>
                    <tr className="total-tr">
                      <td></td>
                      <td className="total">
                        {t("total")} ${total}$
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {currentState == state.TO_DO ? (
                          <button
                            className="confirm-pay"
                            onClick={setExtraService}
                          >
                            {t("confirm_pay")}
                          </button>
                        ) : currentState == state.IN_PROGRESS ? (
                          <button className="confirm-pay">
                            <TailSpin
                              fill="#fff"
                              fillOpacity={1}
                              height="16px"
                              speed={1}
                              stroke="#fff"
                              strokeOpacity={1}
                              strokeWidth={3}
                              width=""
                            />
                          </button>
                        ) : (
                          <button className="confirm-pay">
                            <i className="bi bi-check2"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
