import React from 'react'
import CreditImg from '../../../assets/images/credit-img.png'
import { Modal, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next";

const AddCreditCard = ({ isShowAddCreditCard, handleCloseAddCreditCard, handleSelectedAddCreditCard }) => {
  const { t } = useTranslation();

  return (
    <Modal centered show={isShowAddCreditCard} onHide={handleCloseAddCreditCard}>
      <Modal.Header style={{ borderBottom: 'none', backgroundColor: '#E7FFFF' }} closeButton>
      </Modal.Header>
      <Modal.Body style={{ padding: '0' }}>
        <section style={{ backgroundColor: '#E7FFFF', padding: '0 10px' }}>
          {/* <h2 style={{ fontWeight: '550', fontSize: '30px', letterSpacing: '1.3px', marginLeft: '30px' }}>{t("special_offer_for_you")}</h2> */}
          <h2 style={{ fontWeight: '550', fontSize: '30px', letterSpacing: '1.3px', marginLeft: '30px' }}>Special offer for you!</h2>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <img src={CreditImg} style={{ width: '50%', height: 'auto' }} />
            <h2 style={{ fontSize: '25px', lineHeight: '33px', marginLeft: '-20px' }}>GET 20$ <br /> SIGN IN <br /> BONUS</h2>
          </div>
        </section>
        <p style={{ marginTop: '35px', lineHeight: '22px', fontWeight: '350', padding: '0 35px' }}>Make a prepayment now and receive an additional <br /> <span style={{ fontWeight: '500' }}>$20 bonus</span> for outsourcing WordPress tasks</p>
        <section style={{ marginTop: '30px', padding: '0 30px 20px' }}>
          <Button onClick={handleSelectedAddCreditCard} style={{ width: '100%', marginBottom: '15px', background: "linear-gradient('92.68deg', '#70D9DE', '0%', '#066E73', '100%')" }}>Add deposit</Button>
          <Button onClick={handleCloseAddCreditCard} style={{ width: '100%', backgroundColor: '#FFF', color: '#0D1C2E' }}>I will do it later</Button>
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default AddCreditCard