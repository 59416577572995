import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import "../../assets/css/Balance.css";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { CURRENCY } from "../../constants/constants";
import { useTranslation } from "react-i18next";

export default function Balance() {
  const { t } = useTranslation();
  // const balance = useSelector((state) => state.login.balance);
  const [user, setUser] = useState();

  const userData = getUserFromLocalStorage();
  const balance = userData.balance;

  useEffect(() => {
    const tempUser = getUserFromLocalStorage();
    setUser(tempUser);
  }, []);

  return (
    <div className="balance">
      <div className="title">{t("your_balance")}</div>
      <div className="value">
        {user && user.currency == CURRENCY.USD ? "$" : "€"}
        {balance.toFixed(2)}
      </div>
    </div>
  );
}
