import { encryptBalance, decryptBalance } from "./encryptionBalance";

export const addUserToLocalStorage = (user) => {
  const encryptedBalance = encryptBalance(user?.balance, process.env.REACT_APP_CRYPTO_SALT)
  const encryptedUser = {...user, balance: encryptedBalance }
  localStorage.setItem("user", JSON.stringify(encryptedUser));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem("user");
  if(result){
    const user = JSON.parse(result)
  const decryptedBalance = decryptBalance(user?.balance , process.env.REACT_APP_CRYPTO_SALT);
  return {...user , balance: decryptedBalance  }
  }
  return null
  // const user = result ? JSON.parse(result) : null;
  // return user;
};
export const newUser = (newUser)=>{
  localStorage.setItem("newUser", JSON.stringify(newUser));
}
export const getNewUser = ()=>{
  const newUser =  localStorage.getItem("newUser");
  return JSON.parse(newUser)
}