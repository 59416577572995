import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../../assets/css/CheckoutForm.css";
import { toast } from "react-toastify";
import { API_URL, REDIRECT_URL } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import api from "../../utils/axios";
import { setBalance } from "../../redux/loginSlice";
import { addUserToLocalStorage, getUserFromLocalStorage } from "../../utils/localStorage";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import Swal from "sweetalert2";

export default function CheckoutForm({ amount, modalShow,setModalShow, setConfettiStatus, savedCard,clientSecretId }) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // console.log(paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          toast.success("Payment succeeded!");
          break;
        case "processing":
          toast.info("Your payment is processing.");
          break;
        case "requires_payment_method":
          toast.error("Your payment was not successful, please try again.");
          break;
        default:
          toast.error("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const confirmPaymentSavedCard = async () =>{
    await stripe
   .confirmCardPayment(clientSecretId, {payment_method:getUserFromLocalStorage().paymentMethod},
   )
   .then(function(result) {
    if (result.paymentIntent) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure want to payment continue with the saved card?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      .then(confirmResult => {
        // console.log("result",confirmResult);
        if (confirmResult.isConfirmed) {
          api
          .post(`${API_URL}/api/v1/payment/payment-success`, {
            client_secret: result.paymentIntent.id, payment_method:result.paymentIntent.payment_method
          })
          .then((res) => {
            // After successful desposit change user balance in redux storage and localstorage
            const balance = res.data.balance;
            dispatch(setBalance(balance));

            const user = getUserFromLocalStorage();
            user.balance = balance;
            user.paymentMethod = result.paymentIntent.payment_method;
            addUserToLocalStorage(user);
            setConfettiStatus(true)
            setTimeout(() => {
              setConfettiStatus(false);
            }, 5000);
            setModalShow(false)
          })
          .catch((err) => {
            navigate("/chat");
            toast.error(err);
          });
        }
      })
    }
    else{
      toast.error(result.error.message);
    }
   })
   }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = modalShow ? await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: REDIRECT_URL,
      },
      redirect: "if_required",
    }) : await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: REDIRECT_URL,
      }});

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // error === "card_error" || error.type === "validation_error"
    if (error) {
      toast.error(error.message);
    } else {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent"
      );

      api
        .post(`${API_URL}/api/v1/payment/payment-success`, {
          client_secret: paymentIntent.id, payment_method:paymentIntent.payment_method
        })
        .then((res) => {
          // After successful desposit change user balance in redux storage and localstorage
          const balance = res.data.balance;
          dispatch(setBalance(balance));

          const user = getUserFromLocalStorage();
          user.balance = balance;
          user.paymentMethod = paymentIntent.payment_method;
          addUserToLocalStorage(user);
          setConfettiStatus(true)
          setTimeout(() => {
            setConfettiStatus(false);
          }, 5000);
          setModalShow(false)
        })
        .catch((err) => {
          navigate("/chat");
          toast.error(err);
        });

    }

    setIsLoading(false);
  };

  return (
    <>
    {
        (savedCard?.length > 0) && (
              <div>
                <h4 className="">{t("Saved cards")}</h4>
                <div className="d-flex align-items-center gap-4">
                  {savedCard?.map((items, index) => (
                    <div class="credit-card visa selectable" key={index} onClick={confirmPaymentSavedCard}>
                      <div class="credit-card-last4">{items.card.last4}</div>
                      <div class="credit-card-expiry">
                        {items.card.exp_month}/{items.card.exp_year}
                      </div>
                      <div class="credit-card-type">{items.card.brand}</div>
                    </div>
                  ))}
                </div>
              </div>
        )}

    <div className="payment-form">
      <PaymentElement className="payment-element" />
      <button
        className="pay-now"
        disabled={isLoading || !stripe || !elements}
        onClick={handleSubmit}
      >
        {isLoading ? <div className="dot-spin"></div> : `${t("pay")} ${amount}`}
      </button>
    </div>
    </>
  );
}
