import React, { useEffect, useState } from "react";
import "../assets/css/PaymentSuccess.css";
import { API_URL } from "../constants/constants";
import api from "../utils/axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
} from "../utils/localStorage";
import { changeBalance, login, setBalance } from "../redux/loginSlice";
import { useTranslation } from "react-i18next";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import "animate.css";

export default function PaymentSuccess() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  const [confettiStatus, setConfettiStatus] = useState(true);

  useEffect(() => {
    // const user = getUserFromLocalStorage();
    // if (user) dispatch(login(user));
    setTimeout(() => {
      setConfettiStatus(false);
    }, 5000);
  });

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    api
      .post(`${API_URL}/api/v1/payment/payment-success`, {
        client_secret: clientSecret,
      })
      .then((res) => {
        // After successful desposit change user balance in redux storage and localstorage
        const balance = res.data.balance;
        dispatch(setBalance(balance));

        const user = getUserFromLocalStorage();
        user.balance = balance;
        addUserToLocalStorage(user);
      })
      .catch((err) => {
        navigate("/billing");
      });
  }, {});

  return (
    <>
       <Confetti width={width} height={height} recycle={confettiStatus} />
    <div className="payment-success d-flex text-center align-items-center w-100 h-100">
      <div className="m-auto">
        <div className="check-mark">
          <i className="bi bi-check-circle-fill"></i>
        </div>
        {t("payment_is_done")}
      </div>
    </div>
      </>
  );
}
