import React, { useEffect, useState, useRef } from 'react'
import api from '../../utils/axios'
import { API_URL } from '../../constants/constants'
import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from "react-i18next";

const AccessDataUser = ({ show, setShow }) => {
    const { t } = useTranslation();
    const currentTask = useSelector((state) => state.current);
    const [accessData, setAccessData] = useState();
    const [provideStatus, setProvideStatus] = useState();
    const ref = useRef(null);

    const TAB = {
        WORDPRESS: 0,
        FTP: 1
    }

    const [selectedTab, setSelectedTab] = useState(TAB.WORDPRESS);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShow(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [])

    useEffect(() => {
        const fetchAccessDataUser = async () => {
            await api.get(`${API_URL}/api/v1/chatbot/${currentTask._id}`)
                .then((res) => {
                    setProvideStatus(res?.data?.task.provideData);
                    setAccessData(res?.data?.accessDetail);
                })
        }
        fetchAccessDataUser()
    }, [currentTask])
    return (
        <>
            {
                show && (
                    <div className="provide-site-form d-flex justify-content-center align-items-center">
                        <div ref={ref} style={{ width: '30%', padding: '30px 20px' }} className="contact-manager-model access-detail-form">
                            <div className="d-flex justify-content-between mb-4">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: "10px" }}>
                                    <h2 style={{ fontWeight: '500', fontSize: '22px' }}>User provided data</h2>
                                </div>
                                <button className="cancel" onClick={() => setShow(false)}>
                                    <i className="bi bi-x-lg"></i>
                                </button>
                            </div>

                            <div className="tab">
                                <ul>
                                    <li
                                        className={selectedTab == TAB.WORDPRESS ? "active" : ""}
                                        onClick={() => setSelectedTab(TAB.WORDPRESS)}
                                    >
                                        Wordpress
                                    </li>
                                    <li
                                        className={selectedTab == TAB.FTP ? "active" : ""}
                                        onClick={() => setSelectedTab(TAB.FTP)}
                                    >
                                        FTP
                                    </li>
                                </ul>
                            </div>

                            <div>
                                {
                                    selectedTab === TAB.WORDPRESS && <Form noValidate >
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='licensekey'>{t("license_key")}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control readOnly required id="licensekey" value={accessData?.licenseKeyWP} />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='domain'> {t("domain")}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control readOnly required id="domain" value={accessData?.domainWP} />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='username'> {t("username")}</Form.Label>
                                            <Form.Control readOnly required id='username' type="username" value={accessData?.usernameWP} />
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='password'>{t("password")}</Form.Label>
                                            <Form.Control readOnly required id='password' value={accessData?.passwordWP} />
                                        </Form.Group>
                                    </Form>
                                }

                                {
                                    selectedTab === TAB.FTP && <Form noValidate >
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='licensekey'>{t("license_key")}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control readOnly required id="licensekey" value={accessData?.licenseKeyFTP} />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='domain'> {t("domain")}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control readOnly required id="domain" value={accessData?.domainFTP} />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='username'> {t("username")}</Form.Label>
                                            <Form.Control readOnly required id='username' type="username" value={accessData?.usernameFTP} />
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='password'>{t("password")}</Form.Label>
                                            <Form.Control readOnly required id='password' value={accessData?.passwordFTP} />
                                        </Form.Group>
                                    </Form>
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default AccessDataUser