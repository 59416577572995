import React, { useState, useEffect } from 'react'
import '../../assets/css/response.css'
import '../../assets/css/style.css'
import Footer from './Footer'
import { ADMIN_NAME, API_URL, BASIC_USER, BASIC_PASSWORD } from "../../constants/constants";
import Logo from '../../assets/images/horizontal-logo-wp-sofa.svg'
import { BiMenu } from 'react-icons/bi'
import { BiX } from 'react-icons/bi'
import {useTranslation} from "react-i18next";

const strings = [
    "You get",
    "FAQ",
    "Contact",
    "Login",
    "Looking for an experienced",
    "WordPress developer?",
    "Welcome to WP-Sofa!",
    "Your home-from-home if you’re",
    "looking for WordPress wizards.",
    "Try it now",
    "How it works?",
    "Weekend and through-the-night support.",
    "Fast turnaround for tasks that utilizes a tried and tested booking system.",
    "Prices depend on the urgency of the task but start from 19€/$ per hour.",
    "Access to a verified talent pool of developers that are reliable, trustworthy, and experienced.",
    "Looking for a WordPress expert can be a bit of a pefield if you’re doing it yourself. There are no checks in place, the developer might be overselling their experience, or they might be too busy to deliver on time, which means an issue with late delivery.",
    "It’s perfect for remote working",
    "What makes WP-Sofa different from other platforms?",
    "The WP-Sofa system is completely different from this kind of experience.",
    "The platform has been designed to save time and be poth to work with. It’s a way of outsourcing that can be done at home, from the comfort of your sofa.",
    "It’s something you can use from the comfort of your sofa, with the promise of a smooth working experience, with fast client/developer communication via chat.",
    "The idea behind this innovative and unique platform is to create a system for talented developers and business owners to connect and work together.",
    "Developers who are interested in your task will reply within a short space of time, generally a couple of hours",
    "Simply put in a task and wait for the offers to roll in. It won’t be too long.",
    "Then, all you’ve got to do is decide on a developer and hire them to work on your task",
    "Communications are monitored 24/7 to ensure you get the best possible service.",
    "Quality checks are included in the all-inclusive price.",
    "Opportunities to leave feedback throughout the progress of each task.",
    "Reliability that no other agency can offer.",
    "Fast delivery.",
    "All our clients are important to us and you’ll receive a reply within 24 hours.",
    "If you’ve got any more questions, don’t hesitate to get in touch.",
    "To take advantage of this and more, sign up today!",
    "Message",
    "copyright © 2022 all rights reserved.",
    "To ensure all our clients' information is protected, freelance developers have to follow our data protection policy. In addition, they all sign a non-disclosure agreement.",
    "What about the security of my business information?",
    "Before a developer can become a member of the WP-Sofa team they have to undergo various security and competency tests. We hand-pick each individual.",
    "How do I know the developer is trustworthy?",
    "There’s no need for expensive phone calls because we operate a live chat communication system. Conversations are continually checked to ensure our standards are complied with and everyone is happy.",
    "How can I communicate with a developer?",
    "There is the option to leave feedback via the task manager. We encourage all clients to leave comments to ensure we continue to exceed your expectations.",
    "Can I give feedback?",
    "Yes, you most certainly can. Should you have any issues and you’ve heard nothing even though the deadline is fast approaching, reach out and we’ll make sure the task is done on time.",
    "Can I rely on the deadlines?",
    "Can your developers fix my WordPress problem?",
    "Absolutely. Our developers work around the world and are experts in all things WordPress. To ensure they stay at the top of the game we encourage personal training and development.",
    "Submit",
    "Type your message..."
].join(" =*= ");

const Imprint = () => {
    const [translateLanguage, setTranslateLanguage] = useState(strings.split(" =*= "));
    const [sidebar, setSidebar] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        var username = BASIC_USER;
        var password = BASIC_PASSWORD;
        var auth = 'Basic ' + Buffer.from(username + ':' + password).toString('base64');

        (async () => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': auth },
                body: JSON.stringify({
                    message: strings,
                    key: "home-page-message"
                })
            };

            await fetch(`${API_URL}/api/v1/translate/home-page-message`, requestOptions)
                .then(response => response.json())
                .then(({ data }) => {
                    setTranslateLanguage(() => data.split(" =*= "))
                })
        })();
    }, [])

    return (
        <div id="wrapper">
            <header className="header">
                <div className="container">
                    <div className="header__inner">
                        <div className="header_logo">
                            <a href="/">
                                <img src={Logo} alt='' />
                            </a>
                        </div>
                        <div id="header_menu" className={`header_menu ${sidebar ? 'open-show' : 'close-show'}`}>
                            <ul className="nav">
                                <li className="header_item">
                                    <a href="/#youget">{translateLanguage[1]}</a>
                                </li>
                                <li className="header_item">
                                    <a href="/#faq">FAQ</a>
                                </li>
                                <li className="header_item">
                                    <a href="/#contac">{translateLanguage[2]}</a>
                                </li>
                                <li className="header_item">
                                    <a className="btn btn-log" id="header_btnLogin" href="/login">{translateLanguage[3]}</a>
                                </li>
                            </ul>

                        </div>
                        <div className="btnMenu">
                            {
                                sidebar ?
                                    <div id="header_btnMenuClose" onClick={() => setSidebar(!sidebar)}>
                                        <BiX />
                                    </div> :
                                    <div id="header_btnMenu" className="header_btnMenu" onClick={() => setSidebar(!sidebar)}>
                                        <BiMenu />
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </header>
            <section style={{ paddingTop: '150px', textAlign: 'center', marginBottom: '120px', lineHeight: '25px' }}>
                <h1 style={{ marginBottom: '50px', fontSize: '42px' }}>{t("imprint")}</h1>
                <p style={{ fontWeight: '500' }}>Firma/Company:</p>
                <p>Codemenschen GmbH</p>
                <p style={{ fontWeight: '500' }}>Address:</p>
                <p>Anton-Hubmann-Platz 1/6 | Gössendorf | 8077 | Österreich</p>
                <p style={{ fontWeight: '500' }}>Umsatzsteueridentifikations-Nummer:</p>
                <p>ATU76037423</p>
                <p style={{ fontWeight: '500' }}>FB-Nummer:</p>
                <p>FN 543274 h</p>
                <p style={{ fontWeight: '500' }}>FB-Gericht:</p>
                <p>Landesgericht für Zivilrechtssachen Graz</p>
                <p style={{ fontWeight: '500' }}>Name der Bank:</p>
                <p>Steiermärkische Bank und Sparkassen AG</p>
                <p style={{ fontWeight: '500' }}>IBAN:</p>
                <p>AT411420020014177982</p>
                <p style={{ fontWeight: '500' }}>BIC:</p>
                <p>BAWAATWW</p>
                <p style={{ fontWeight: '500' }}>Codemenschen Team Skype:</p>
                <a href="skype:live:gdpr_22">live:gdpr_22</a>
            </section>
            <Footer translateLanguage={translateLanguage} />
        </div>
    )
}

export default Imprint