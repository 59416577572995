import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, navigate } from "react-router-dom";
import "../../assets/css/Chat.css";
import { SocketContext } from "../../context/socket";
import { addUserToLocalStorage, getUserFromLocalStorage } from "../../utils/localStorage";
import ChatBox from "./ChatBox";
import ChatBoxBackground from "./ChatBoxBackground";
import Tasks from "./Tasks";
import { addSocketEventListener } from "../../utils/socketCommon";
import api from "../../utils/axios";
import { API_URL } from "../../constants/constants";
import { toast } from "react-toastify";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import "../../assets/css/Intro.css";
import WelcomeIntro from "./Intro/WelcomeIntro";
import CreateTaskIntro from "./Intro/CreateTaskIntro";
import ChatWithDevIntro from "./Intro/ChatWithDevIntro";
import ChatbotIntro from "./Intro/ChatbotIntro";
import AddCreditCard from "./Intro/AddCreditCard";
import { ROLE } from "../../constants/constants";
import { setBillingTab, setSidebarTab } from "../../redux/loginSlice";
import UpdateImageProfileIntro from "./Intro/UpdateImageProfileIntro";
import { convertToVietnamTime } from "../../utils/dateTime";
import {
  browserName,
  osName,
  deviceType,
  isMobile,
  isTablet,
} from "react-device-detect";

export default function Chat() {
  const userData = getUserFromLocalStorage();

  const [isConnected, setIsConnected] = useState(false);

  const [steps, setSteps] = useState([
    {
      element: '#welcome',
      intro: <WelcomeIntro />
    },
    {
      element: "#task",
      intro: <CreateTaskIntro />,
      position: 'right',
    },
    {
      element: "#chat",
      intro: <ChatWithDevIntro />,
      position: 'left',
    },
    {
      element: "#img_profile",
      intro: <UpdateImageProfileIntro />,
      position: 'right',
    },
  ])
  const [stepsEnabled, setStepsEnabled] = useState(getUserFromLocalStorage().isShowIntro)
  const [hintsEnabled, setHintsEnabled] = useState(true)
  const [initialStep, setInitialStep] = useState(0)
  const [hints, setHints] = useState([
    {
      element: '#chat',
      hint: <ChatbotIntro />,
      hintPosition: "middle-right",
    }
  ])

  const [isShowAddCreditCard, setIsShowAddCreditCard] = useState(false)

  const handleCloseAddCreditCard = () => {
    setIsShowAddCreditCard(false)
    setStepsEnabled(false)
    api.post(`${API_URL}/api/v1/user/hide-show-intro`)
    .then((res) => {
      if (userData.isShowIntro == true) {
        userData.isShowIntro = false;
        addUserToLocalStorage(userData);
      }
    })
  }

  const handleSelectedAddCreditCard = () => {
    setStepsEnabled(false)
    setIsShowAddCreditCard(false)
    dispatch(setBillingTab(3))
    navigate('/billing')
    dispatch(setSidebarTab("billing"))

    api.post(`${API_URL}/api/v1/user/hide-show-intro`)
    .then((res) => {
      if (userData.isShowIntro == true) {
        // setIsShowIntro(false)
        userData.isShowIntro = false;
        addUserToLocalStorage(userData);
      }
    })
  }

  // welcome modal step function
  const onExit = () => {
    setStepsEnabled(false)
    setIsShowAddCreditCard(true)
  };

  const currentTask = useSelector((state) => state.current);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  window.addEventListener('online', () => {
    socket.connect()
  });


  useEffect(() => {
    const user = getUserFromLocalStorage("user");
    if (!user) {
      navigate("/");
    } else {
      // This is useful when user move from billing to chat
      // dispatch(login(user));
      // socket.emit("setup", {user: user, type: ""});
      let hour = new Date(convertToVietnamTime(new Date())).getHours();
      socket.emit("setup", {userData: user, hour: hour});
      setTimeout(() => socket.emit("join-chat", user._id), 1000);
      addSocketEventListener(socket, "updated-user", (user) => {
        let currentUser = getUserFromLocalStorage();
        currentUser.balance = user.balance;
        currentUser.translatelanguage = user.translatelanguage;
        addUserToLocalStorage(currentUser);
      })
    }

    if ("Notification" in window) {
      if (Notification.permission === 'granted') {
        return
      }
      if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
        })
      }
    }
  }, []);

  useEffect(() => {
    const user = getUserFromLocalStorage("user");
    socket.on("connect", () => {
      setIsConnected(true);
      setTimeout(() => socket.emit("join-chat", user._id), 1000);
      addSocketEventListener(socket, "updated-user", (user) => {
        let currentUser = getUserFromLocalStorage();
        currentUser.balance = user.balance;
        currentUser.translatelanguage = user.translatelanguage;
        addUserToLocalStorage(currentUser);
      })
    });
    socket.on("disconnect", () => {
      setIsConnected(false)
    });
  }, []);

  useEffect(() => {
    if (!isConnected) {
      socket.connect();
    }
  }, [isConnected]);

  useEffect(() => {
    const getData = async () => {
      await api
        .get(`${API_URL}/api/v1/billing`)
        .then((res) => {
          localStorage.setItem("BillingInfo", res.data ? true : false)
        })
        .catch((err) => {
          localStorage.setItem("BillingInfo", false)
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    };

    getData();
  }, []);

  return (
    <>
      {
        !isMobile ? <div
        className="row"
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          height: "100%",
          width: "100%",
        }}
      >
        {
          (userData?.isShowIntro) ? <Steps
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={onExit}
            style={{marginTop: '30px'}}
            className='introjs-helperLayer'
            options={{
              doneLabel: 'Got it'
            }}
          /> : ""
        }
  
        <Hints enabled={hintsEnabled} hints={hints} />
  
        {
          !currentTask._id && <AddCreditCard isShowAddCreditCard={isShowAddCreditCard} handleCloseAddCreditCard={handleCloseAddCreditCard} handleSelectedAddCreditCard={handleSelectedAddCreditCard} />
        }
  
        <div className="col-4">
          <Tasks socket={socket} />
        </div>
        <div className="col-8 height-100">
          {/* {(currentTask._id) ? <ChatBox /> : <ChatbotIntro />} */}
          {currentTask._id ? <ChatBox /> : stepsEnabled ? <ChatbotIntro /> : (userData.role === ROLE.ADMIN || userData.role === ROLE.DEV ? '' : <ChatBoxBackground />)}
        </div>
      </div> : <div>
      {currentTask._id ? <ChatBox /> : <Tasks socket={socket} />}

      {/* <div className="col-8 height-100">
        {currentTask._id ? <ChatBox /> : <ChatBoxBackground />}
      </div> */}
    </div> 
      }
    </>
  );
}

// <div
//   className="row"
//   style={{
//     position: "absolute",
//     top: "0px",
//     right: "0px",
//     height: "100%",
//     width: "100%",
//   }}
// >
//   <div className="col-4">
//     <Tasks socket={socket} />
   

//   </div>
//   <div className="col-8 height-100">
//     {currentTask._id ? <ChatBox /> : <ChatBoxBackground />}
//   </div>
// </div>

