import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../../assets/css/Login.css";
import { API_URL, CURRENCY } from "../../constants/constants";
import axios from "axios";
import api from "../../utils/axios";
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
} from "../../utils/localStorage";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
// import Shape1 from "../../assets/images/Shape1.png";
// import Shape2 from "../../assets/images/Shape2.png";
// import Shape3 from "../../assets/images/Shape3.png";
// import LoginImg from "../../assets/images/login.jpg";
import animationData from "../../assets/animation/sammy-line-remote-work-cdm.json";
// import Logo from "../../assets/images/horizontal-logo-wp-sofa.svg";
import Logo from "../../assets/images/sofa.svg";
import {
  browserName,
  osName,
  deviceType,
  isMobile,
  isTablet,
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  GoogleLoginButton,
  FacebookLoginButton,
} from "react-social-login-buttons";
import { supportedLanguages } from "../../constants/languages";
import { getLang } from "../../utils/common";
import { Modal } from "react-bootstrap";
import LoginIntro from "../LoginIntro";

export default function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [oauthuserdata, setOauthuserdata] = useState("");
  const [isopen, setIsopen] = useState(false);
  const [isFaceBookData, setIsFaceBookData] = useState("");
  const [isGoogle, setIsGoogle] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigning, setIsSigning] = useState(false);
  const [isRemember, setIsRemember] = useState(false);
  const [translateLanguage, setTranslateLanguage] = useState(getLang());
  const [currency, setCurrency] = useState(CURRENCY.USD);

  const [checked, setChecked] = useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const loginbuttonRef = useRef(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };

  useEffect(() => {
    const userValidCheck = async () => {
      api
        .get(`${API_URL}/api/v1/user`)
        .then((res) => {
          navigate("/chat");
        })
        .catch((err) => console.log(err));
    };

    // if (isMobile || isTablet) {
    //   window.location.replace("https://app.wp-sofa.chat");
    // } else {
    //   emailRef.current.focus();
    //   const user = getUserFromLocalStorage();
    //   if (user !== null) userValidCheck();
    // }
    emailRef.current.focus();
      const user = getUserFromLocalStorage();
      if (user !== null) userValidCheck();
  }, []);

  useEffect(() => {
    if (oauthuserdata || isFaceBookData) {
      checkUserIsRegistered()
    }
  }, [oauthuserdata, isFaceBookData])

  const onLogin = async () => {
    setIsSigning(true);
    const deviceInformation =
      deviceType + " || " + browserName + " || " + osName;
    const device = isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'PC';
    await axios
      .post(`${API_URL}/api/v1/auth/login`, {
        email,
        password,
        isRemember,
        deviceInformation,
        device,
      })
      .then((res) => {
        setIsSigning(false);
        const data = res.data;
        addUserToLocalStorage(data);
        navigate("/chat");
      })
      .catch((err) => {
        setIsSigning(false);
        emailRef.current.focus();
        const error = err.response.data.errors[0];
        toast.error(error.msg);
      });
  };


  const checkUserIsRegistered = async () => {
    let email;
    if (isGoogle) {
      email = oauthuserdata.email;
    } else {
      email = isFaceBookData.email;
    }
    await axios
      .post(`${API_URL}/api/v1/auth/check-user`, {
        email
      })
      .then((res) => {
        const data = res.data;
        if (data.UserExist) {
          createNewUser()
        } else {
          setIsopen(true);
        }
      })
      .catch((err) => {
        if (err.UserExist) {
          createNewUser();
        } else {
          setIsopen(true);
        }
        toast.error(err.msg);
      });
  };


  const goToSignup = () => {
    navigate("/register-wp-giftcard");
  };


  const createNewUser = async (userData) => {
    setIsopen(false);
    // Send a POST request to your backend server and create a new user with the received data
    if (isGoogle === true) {
      const { name, email, avatar, locale } = oauthuserdata;
      await axios
        .post(`${API_URL}/api/v1/auth/social-login`, {
          name, email, locale, currency, translateLanguage,
          avatar: oauthuserdata.picture,
          socialGoogleId: oauthuserdata.sub,
        })
        .then((res) => {
          const data = res.data;
          addUserToLocalStorage(data);
          // dispatch(login(data));
          navigate("/chat");
        })
        .catch((err) => {
          setIsSigning(false);
          emailRef.current.focus();
          const error = err.response.data.errors[0];
          toast.error(error.msg);
        });

    } else {
      const { name, email, } = isFaceBookData;
      await axios
        .post(`${API_URL}/api/v1/auth/social-login`, {
          name, email, currency, translateLanguage,
          avatar: isFaceBookData.picture.data.url,
          socialFaceBookId: isFaceBookData.userID,

        })
        .then((res) => {
          const data = res.data;
          addUserToLocalStorage(data);
          // dispatch(login(data));
          navigate("/chat");
        })
        .catch((err) => {
          setIsSigning(false);
          emailRef.current.focus();
          const error = err.response.data.errors[0];
          toast.error(error.msg);
        });

    }
  }

  const handleResetPassword = async () => {
    navigate("/reset-password")
    // // const idClient = "665ee8e8f18cfd36a37d1b49";
    // api.post(`${API_URL}/api/v1/auth/reset`, {
    //   userId: "665ee8e8f18cfd36a37d1b49",
    //   password: "creatify"
    //   // info@creatify.be
    // })
    //   .then((res) => {
    //   })
  }

  return (
    <div className="login">
      {isMobile ? (
        // <div
        //   style={{
        //     height: "100vh",
        //     display: "flex",
        //     textAlign: "center",
        //     alignItems: "center",
        //   }}
        // >
        //   <div className="w-100" style={{ fontSize: "20px" }}>
        //     Redirect to https://app.wp-sofa.chat
        //   </div>
        // </div>
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="container">
            <img className="logo" src={Logo} />
            <div className="title1">{t("sign_in")}</div>
            <div className="mt-3">
              <div>{t("your_e_mail")}</div>
              <input
                ref={emailRef}
                className="name w-100"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  const { key } = e;
                  if (key === "Enter") passwordRef.current.focus();
                }}
              />{" "}
              <div className="mt-3">
                <div>{t("password")}</div>
                <input
                  ref={passwordRef}
                  type="password"
                  className="name w-100"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (key === "Enter") loginbuttonRef.current.focus();
                  }}
                />
              </div>
              <div className="mt-4 d-flex">
                <input
                  className="remember"
                  id="rememberme"
                  type="checkbox"
                  checked={isRemember}
                  onClick={() => setIsRemember(!isRemember)}
                />
                <label className="remember-text" htmlFor="rememberme">
                  {t("remember_me")}
                </label>
              </div>
              {/* <div className="mt-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginTop: '6px', lineHeight: '20px' }}>By login you agree to the <a href="terms-of-agreement">terms and conditions</a> of WP-Sofa
                  </div>
                </div>
              </div> */}
              <div className="text-center">
                {isSigning ? (
                  <button style={{width: '100%'}} className="join">{t("sign_in")}...</button>
                ) : (
                  <button
                  style={{width: '100%'}}
                    ref={loginbuttonRef}
                    className="join"
                    onClick={onLogin}
                  >
                    {t("sign_in")}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="container d-flex text-center justify-content-center mb-5">
            {t("dont_have_account")} &nbsp;
            <button className="gotosignup" onClick={goToSignup}>
              {t("sign_up!")}
            </button>
          </div>
        </div>
      ) : (
        <>
          {/* <div className="container">
            <img className="logo" src={Logo} alt="" />
          </div> */}

          <div className="main-content">
            <div className="row">
              <div className="col-6 d-flex align-items-center" style={{ paddingLeft: '10%' }}>
                <div className="w-85">
                  <img className="logo" src={Logo} alt="" />
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="title1">{t("sign_in")}</div>
                    <div className="d-flex">
                      {t("dont_have_account")} &nbsp;
                      <button className="gotosignup" onClick={goToSignup}>
                        {t("sign_up!")}
                      </button>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div>{t("your_e_mail")}</div>
                    <input
                      ref={emailRef}
                      className="name w-100"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={(e) => {
                        const { key } = e;
                        if (key === "Enter") passwordRef.current.focus();
                      }}
                    />
                  </div>
                  <div className="mt-5">
                    <div>{t("your_password")}</div>
                    <input
                      ref={passwordRef}
                      type="password"
                      className="name w-100"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={(e) => {
                        const { key } = e;
                        if (key === "Enter") loginbuttonRef.current.focus();
                      }}
                    />
                  </div>

                  <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                    <div className="mt-4 d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                      <input
                        className="remember"
                        id="rememberme"
                        type="checkbox"
                        checked={isRemember}
                        onClick={() => setIsRemember(!isRemember)}
                      />
                      <label className="remember-text" htmlFor="rememberme">
                        {t("remember_me")}
                      </label>
                    </div>
                    <button className="reset-password" onClick={handleResetPassword}>{t("forgot_password")}</button>
                  </div>

                  <div className="mt-3">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginTop: '6px', lineHeight: '20px' }}>By login you agree to the <a href="terms-of-agreement">terms and conditions</a> of WP-Sofa
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    {isSigning ? (
                      <button className="join" style={{ width: '100%' }}>{t("signing_in")}</button>
                    ) : (
                      <button
                        ref={loginbuttonRef}
                        className="join"
                        onClick={onLogin}
                        style={{ width: '100%' }}
                      >
                        {t("sign_in")}
                      </button>
                    )}
                  </div>

                  <div>
                    <div style={{ display: 'flex' }}>
                      <div className="text-center mt-3">
                        <LoginSocialGoogle
                          client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          scope="openid profile email"
                          discoveryDocs="claims_supported"
                          access_type="offline"
                          onResolve={({ data }) => {
                            setIsGoogle(true)
                            // setIsopen(true);
                            setOauthuserdata(data);
                          }}
                          onReject={(err) => {
                          }}
                        >
                          <GoogleLoginButton />
                        </LoginSocialGoogle>
                      </div>
                      <div className="text-center  mt-3">
                        <LoginSocialFacebook
                          // appId={1016901575835-rtdqeim0doqs8tf9p1n824b9tg82ro1m.apps.googleusercontent.com}
                          appId={process.env.REACT_APP_FACEBOOK_OAUTH_ID}
                          autoLoad={true}
                          fieldsProfile={
                            "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                          }
                          onResolve={({ data }) => {
                            setIsFaceBookData(data);
                          }}
                          onReject={(err) => {
                          }}
                        >
                          <FacebookLoginButton />
                        </LoginSocialFacebook>

                        <Modal
                          show={isopen}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          <div className="p-3">
                            <div className="mt-3">
                              <div className="input-text">{t("your_language")}</div>
                              <select
                                className="name w-100"
                                value={translateLanguage}
                                onChange={(event) =>
                                  setTranslateLanguage(event.target.value)
                                }
                              >
                                {supportedLanguages.map((item, index) => {
                                  return (
                                    <option key={index} value={item.code}>
                                      {item.language}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="mt-3">
                              <div>{t("currency")}</div>
                              <div
                                className="d-flex align-items-baseline"
                                style={{ marginTop: "10px" }}
                              >
                                <input
                                  className="currency-choose"
                                  checked={currency === CURRENCY.USD ? true : false}
                                  inline
                                  id="usd"
                                  name="group1"
                                  type="radio"
                                  onChange={(e) => {
                                    if (e.target.checked) setCurrency(CURRENCY.USD);
                                  }}
                                />
                                <label className="ms-2" htmlFor="usd">
                                  {t("usd")}
                                </label>
                                <input
                                  className="ms-5 currency-choose"
                                  checked={
                                    currency === CURRENCY.EURO ? true : false
                                  }
                                  inline
                                  id="euro"
                                  name="group1"
                                  type="radio"
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      setCurrency(CURRENCY.EURO);
                                  }}
                                />
                                <label className="ms-2" htmlFor="euro">
                                  {t("euro")}
                                </label>
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                className="popup-join"
                                onClick={createNewUser}
                              >
                                {t("save_your_preference")}
                              </button>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <Modal
                      show={isopen}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <div className="p-3">
                        <div className="mt-3">
                          <div className="input-text">{t("your_language")}</div>
                          <select
                            className="name w-100"
                            value={translateLanguage}
                            onChange={(event) =>
                              setTranslateLanguage(event.target.value)
                            }
                          >
                            {supportedLanguages.map((item, index) => {
                              return (
                                <option key={index} value={item.code}>
                                  {item.language}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="mt-3">
                          <div>{t("currency")}</div>
                          <div
                            className="d-flex align-items-baseline"
                            style={{ marginTop: "10px" }}
                          >
                            <input
                              className="currency-choose"
                              checked={currency === CURRENCY.USD ? true : false}
                              inline
                              id="usd"
                              name="group1"
                              type="radio"
                              onChange={(e) => {
                                if (e.target.checked) setCurrency(CURRENCY.USD);
                              }}
                            />
                            <label className="ms-2" htmlFor="usd">
                              {t("usd")}
                            </label>
                            <input
                              className="ms-5 currency-choose"
                              checked={currency === CURRENCY.EURO ? true : false}
                              inline
                              id="euro"
                              name="group1"
                              type="radio"
                              onChange={(e) => {
                                if (e.target.checked)
                                  setCurrency(CURRENCY.EURO);
                              }}
                            />
                            <label className="ms-2" htmlFor="euro">
                              {t("euro")}
                            </label>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            className="popup-join"
                            onClick={() => createNewUser()}
                          >
                            {t("save_your_preference")}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="col-6">
                {/* <Lottie options={defaultOptions} width="100%" /> */}
                <LoginIntro />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
