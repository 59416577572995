import React from 'react'
import Logo1 from '../../assets/images/horizontal-logo-wp-sofa-white.svg'

const   Footer = ({translateLanguage}) => {
  return (
    <>
      <footer className="footer">
            <div className="container ">
                <div className="footer__inner">
                    <div className="footer_logo">
                        <img src={Logo1} alt="" />
                        {/* <img src="IMG/Logo(1).svg" alt="" /> */}
                    </div>
                    <div className="footer_tiltle" style={{display: 'flex'}}>
                        <p style={{marginRight: '30px'}}>{translateLanguage[34]}</p> 
                        <a href='imprint' style={{ color: 'white', marginRight: '30px'}}>Imprint</a>
                        <a href='terms-of-agreement' style={{ color: 'white'}}>Terms of agreement</a>
                    </div>
                </div>
            </div>
            
        </footer>
    </>
  )
}

export default Footer
