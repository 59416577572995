import React from "react";
import { API_URL } from "../../constants/constants";
import "../../assets/css/UserRead.css";

export default function UserRead({ image }) {
  return (
    <div className="d-flex justify-content-end mt-1">
      <img className="read-user-avatar" src={image} />
    </div>
  );
}
