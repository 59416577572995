import React from 'react'
import '../../assets/css/Loading.css'

const Loading = () => {
    return (
        <div className="spinner-container">
            <div className="spinner"></div>
        </div>
    )
}

export default Loading