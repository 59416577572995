import React, { useState, useEffect } from "react";
import {PayPalScriptProvider, PayPalButtons, FUNDING} from "@paypal/react-paypal-js";
import api from "../../utils/axios";
import { API_URL } from "../../constants/constants";
import { setBalance } from "../../redux/loginSlice";
import { addUserToLocalStorage, getUserFromLocalStorage } from "../../utils/localStorage";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PaypalCheckoutForm = ({amount,amountSymbol,setConfettiStatus,setModalShow, amountCurrency}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        intent: "CAPTURE",
        purchase_units: [
          {
            description: "Deposit",
            amount: {
              currency_code: `${amountCurrency.toUpperCase()}`,
              value: amount,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      api
          .post(`${API_URL}/api/v1/payment/payment-success`, {
            amountPaypal:amount})
          .then((res) => {
            // After successful desposit change user balance in redux storage and localstorage
            const balance = res.data.balance;
            dispatch(setBalance(balance));
    
            const user = getUserFromLocalStorage();
            user.balance = balance;
            // user.paymentMethod = result.paymentIntent.payment_method;
            addUserToLocalStorage(user);
            setConfettiStatus(true)
            setTimeout(() => {
              setConfettiStatus(false);
            }, 5000);
            setModalShow(false)
          })
          .catch((err) => {
            // navigate("/chat");
            toast.error(err);
          });
    }).catch(error =>{
      toast.error("An Error occured with your payment")
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    toast.error("An Error occured with your payment ");
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
          currency: `${amountCurrency.toUpperCase()}`
      }}
    >
      <div>
        <div className="">
          <div className="">
          <h4 className="">Deposit this amount {amountSymbol}{amount.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </h4>
          </div>
        </div>
        <br></br>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
            fundingSource={FUNDING.PAYPAL}
          />
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
            fundingSource={FUNDING.EPS}
          />
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
            fundingSource={FUNDING.SOFORT}
          />
     
      </div>
    </PayPalScriptProvider>
  );
};

export default PaypalCheckoutForm;
