import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { SocketContext } from "../../context/socket";
import { getUserFromLocalStorage } from '../../utils/localStorage';
import { toast } from "react-toastify";
import api from '../../utils/axios';
import { API_URL } from '../../constants/constants';
import Lock from "../../assets/images/lock.png"

const ProvideLicenseKeyForm = ({ show, setShow }) => {
  const { t } = useTranslation();
  const ref = React.useRef(null);
  const socket = React.useContext(SocketContext);
  const currenTask = useSelector((state) => state.current);
  const userData = getUserFromLocalStorage();

  const [licenseKey, setLicenseKey] = useState("");

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!licenseKey) {
      toast.error("Please provide license key");
      return;
    } else {
      // socket.emit("handle-submit-form-provide-site", { task: currenTask, userId: userData._id, type: "provide_site", info: { licenseKey } })
      // setTimeout(() => {
      //     socket.emit("handle_provide_site", { task: task, userId: userData._id, type: "provided_site", info: { domain, username, password } })
      // }, 7000);

      const clientData = {
        taskId: currenTask._id,
        licenseKey: licenseKey
      };
      try {
        await api.post(`${API_URL}/api/v1/chatbot/provide-license-key`, clientData)
          .then((res) => {
            toast.success("Saved license key");
            socket.emit("handle-provided-license-key", { task: currenTask, userId: userData._id, type: "provided_license_key" })
          })
          .catch(err => {
            toast.error("Error saving license key");
          })
      } catch (err) {
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      }

      await api.post(`${API_URL}/api/v1/chatbot/is-provided-license-key`, {
        taskId: currenTask._id
      })
        .then((res) => {
        })
        .catch((err) => {
        })

      setShow(false)
    }
  }

  return (
    <>
      {
        show && (
          <div className="provide-site-form d-flex justify-content-center align-items-center">
            <div ref={ref} style={{ width: '30%', padding: '30px 20px' }} className="contact-manager-model">
              <div className="d-flex justify-content-between mb-4">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: "10px" }}>
                  <h2 style={{ fontWeight: '500', fontSize: '22px' }}>Your data will be encrypted </h2>
                  <img src={Lock} />
                </div>
                <button className="cancel" onClick={() => setShow(false)}>
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>

              <Form >
                <Form.Group className="mb-3" >
                  <Form.Label htmlFor='domain'>License key</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control required id="domain" placeholder='ex: ABCD-1234-EFGH-5678' value={licenseKey} onChange={(e) => { setLicenseKey(e.target.value) }} />
                    <Form.Control.Feedback type="invalid">
                      Please enter license key.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Button style={{ width: '100%' }} onClick={handleSubmit} variant="primary" type='submit'>
                  Save
                </Button>
              </Form>
            </div>
          </div>
        )
      }
    </>
  )
}

export default ProvideLicenseKeyForm