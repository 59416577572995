import React from 'react'
import { useTranslation } from "react-i18next";
import linkdinIcon from "../../../assets/images/linkdin.svg"
import youtubeIcon from "../../../assets/images/youtube.svg"
import User from '../User';
import "../../../assets/css/MessageInput.css";

const ChatbotIntro = () => {
    const { t } = useTranslation();
    return (
        <div id='chat' style={{ marginTop: '50px', marginRight: '30px', height: '90vh' }}>
            <div className="chatbox-title d-flex align-items-center">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="d-flex align-items-center me-5">
                            <div className="position-relative">
                                <img style={{maxWidth: '300px'}} src='https://res.cloudinary.com/dbjfuowqq/image/upload/v1667271453/wp-sofa-avatar/rhd6fk5aggwlsifmq8ng.jpg' className="team-avatar" />
                                <div
                                    className='online-status'
                                ></div>
                            </div>
                            <div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <>
                                        <div className="d-flex align-items-center">
                                            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                                                Patrick Fuchshofer
                                            </div>
                                        </div>
                                    </>
                                    <div
                                        style={{ display: "flex", flexDirection: 'row', gap: 10, marginTop: 10 }}>
                                        <img
                                            style={{ cursor: "pointer" }}
                                            src={linkdinIcon}
                                            alt={""}
                                        />
                                        <img
                                            style={{ cursor: "pointer" }}
                                            src={youtubeIcon}
                                            alt={""}
                                        />
                                    </div>
                                    {/* } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chatbox-content d-flex flex-column justify-content-between">
                {/* <div className="d-flex justify-content-center m-1 align-items-center">
                    <div
                        className="text-center"
                        style={{
                            wordBreak: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            padding: "5px 20px",
                            background: "#29AB87",
                            color: "#fff",
                            borderRadius: "10px",
                            width: "100%",
                        }}
                    >
                        hai bach
                    </div>
                </div> */}

                <div className="flex-auto messagebox">
                    <div className="p-2">
                        <div style={{position: 'relative'}}>

                            <User
                                avatar='https://res.cloudinary.com/dbjfuowqq/image/upload/v1667271453/wp-sofa-avatar/rhd6fk5aggwlsifmq8ng.jpg'
                                user='Patrick Fuchshofer'
                            />

                            {/* <Message
                                message="Hi, I'm Patrick from the WP-Sofa WordPress Gift Card plugin team."
                                type='2'
                                isMarginLeft='true'
                            /> */}
                            <div style={{
                                position: 'absolute',
                                background: '#E4F6F6',
                                padding: '10px',
                                borderRadius: '10px',
                                border: '1px solid #B8DADD',
                                width: '70%',
                                left: '60px'
                            }}>
                                Hi, I'm Patrick from the WP-Sofa WordPress Gift Card plugin team.
                            </div>



                        </div>
                    </div>
                </div>
                {/* <MessageInputBox /> */}

                {/* message input box */}
                <hr style={{ height: '5px' }} />

                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <label htmlFor="upload-button">
                            <div
                                className="message-input d-flex justify-content-center blue-button text-white align-items-center text-center"
                                style={{
                                    cursor: "pointer",
                                    borderRadius: '50%',
                                    fontSize: '20px',
                                    height: '40px',
                                    width: '40px',
                                    background: 'linear-gradient(92.68deg, #75dee3 0%, #00676c 100%)'
                                }}
                            >
                                <i style={{ fontSize: '30px' }} className="bi bi-plus"></i>
                            </div>
                        </label>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            id="upload-button"
                            style={{ display: "none" }}
                        />
                    </div>
                    <textarea
                        // onPaste={handlePaste}
                        // ref={textField}
                        // value={message}
                        style={{
                            border: 'none',
                            // height: '28px',
                            marginLeft: '10px',
                            color: '#B7BDCB',
                            // fontWeight: '500'
                        }}

                        rows='1'
                        placeholder='Type a message here'
                        className="flex-auto"
                        disabled
                    />
                    <div className="d-flex align-items-center">
                        <button className="emoji-button" style={{
                            border: 'none',
                            background: 'transparent',
                            color: '#b7bdcb',
                            fontSize: '25px',
                        }}>
                            <i className="bi bi-emoji-smile"></i>
                        </button>
                        <div>
                            <button
                                className="blue-button text-white ms-2"
                                style={{
                                    height: '40px',
                                    width: '40px',
                                    border: 'none',
                                    borderRadius: '50%',
                                    background: 'linear-gradient(92.68deg, #75dee3 0%, #00676c 100%)',
                                    fontSize: '20px',
                                }}
                            >
                                <i className="bi bi-cursor-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default ChatbotIntro