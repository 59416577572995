import React, {useCallback, useContext, useState} from "react";
import FullScreenImage from "./FullScreenImage";
import {API_URL} from "../../constants/constants";
import "../../assets/css/Image.css";
import Popup from "reactjs-popup";
import api from "../../utils/axios";
import {deleteMessage} from "../../redux/messageSlice";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {SocketContext} from "../../context/socket";

const MY_MESSAGE = 1;
const OTHER_MESSAGE = 2;

export default function Images({message, type}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socket = useContext(SocketContext);

    const [fullScreenShow, setFullScreenShow] = useState(false);
    const [image, setImage] = useState("");

    const removeImage = useCallback(async(imageObject) => {
        const imageName = await imageObject?.[0]?.split("image/")?.[1]?.split("_")?.[2];

        await api
            .post(`${API_URL}/api/v1/message/deleteFile`, {
                messageId: message._id,
                fileName: imageName
            })
            .then((res) => {
                dispatch(deleteMessage(res.data._id));
                socket.emit("delete-message", res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) navigate("/");
                const errors = err.response.data.errors;
                errors.forEach((error) => toast.error(error.msg));
            });
    }, [message]);

    return (
        <>
            <FullScreenImage
                image={image}
                fullScreenShow={fullScreenShow}
                setFullScreenShow={setFullScreenShow}
            />
            <div className={type === MY_MESSAGE ? "d-flex justify-content-end" : ""}>
                <div className="message-images d-flex flex-auto"
                     style={type === OTHER_MESSAGE ? {justifyContent: "start"} : {justifyContent: "flex-end"}}>
                    {message?.message_deleted ?
                        <div
                            style={{margin: "0.25rem"}}
                            dangerouslySetInnerHTML={{__html: message.message_deleted && "<div style='color: #bbbbbb; font-style: italic;'>Ø message is deleted</div>"}}
                            className={type === MY_MESSAGE ? "message-text-me" : type === OTHER_MESSAGE ? "message-text-user" : "message-text-admin"}
                        />
                        :
                        <ul className="images flex-auto">
                            {(message.images || []).map((image, index) => (
                                <li key={index}>
                                    <img
                                        src={image}
                                        onClick={() => {
                                            setImage(image);
                                            setFullScreenShow(true);
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>}
                    {!message.message_deleted && <div className="popup-area">
                        <Popup
                            trigger={
                                <button className="popup-button">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </button>
                            }
                            position="bottom right"
                            arrow={false}
                        >
                            <div className="menu">
                                {type === MY_MESSAGE && (
                                    <div className="menu-item" onClick={() => removeImage(message.images)}>
                                        <i className="bi bi-x-square"></i> &nbsp; {t("remove")}
                                    </div>
                                )}
                            </div>
                        </Popup>
                    </div>}
                </div>
            </div>
        </>
    );
}