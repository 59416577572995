import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import "../../../assets/css/CreateTaskIntro.css";

const CreateTaskIntro = () => {
    const { t } = useTranslation();
    // const [persons, setPersons] = useState([]);

    return (
        <div>
            <div style={{
                display: 'flex',
                // gridTemplateColumns: '2fr 5fr 12fr'
                alignItems: 'center'
            }}>
                <div className="position-relative">
                    <img
                        style={{
                            borderRadius: '50%',
                            height: '35px',
                            width: '35px',
                            objectFit: 'cover',
                            marginRight: '15px',
                        }}
                        src='https://res.cloudinary.com/dbjfuowqq/image/upload/v1667271453/wp-sofa-avatar/rhd6fk5aggwlsifmq8ng.jpg'
                    />
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '4px',
                            right: '15px',
                            width: '8px',
                            height: '8px',
                            backgroundColor: '#2af26e',
                            borderRadius: '50%',
                            border: 'solid 1px #fff'
                        }}
                        className='online-status'
                    ></div>
                </div>
                <h6 style={{ marginRight: '10px' }}>Patrick</h6>
                <small style={{ color: '#717C97' }}>from WP-SOFA</small>
            </div>
            <h4 style={{ marginTop: '15px', marginBottom: '12px', fontSize: '20px', fontWeight: '500' }}>Create Task</h4>
            <p style={{ marginBottom: '15px' }}>Some examples of the tasks:</p>
            <ul style={{
                textAlign: 'left',
                listStyleType: 'disc',
                marginLeft: '30px',
                fontSize: '10px',
                marginBottom: '20px'
            }}>
                <li style={{ fontSize: '14px', lineHeight: '20px' }}>Plugin Installation and Configuration</li>
                <li style={{ fontSize: '14px', lineHeight: '20px' }}>Compatibility Issues</li>
                <li style={{ fontSize: '14px', lineHeight: '20px' }}>Bug Fixes and Troubleshooting</li>
                <li style={{ fontSize: '14px', lineHeight: '20px' }}>Customization Requests</li>
                <li style={{ fontSize: '14px', lineHeight: '20px' }}>Performance Optimization</li>
            </ul>
            <p style={{ fontSize: '14px', marginBottom: '-20px' }}>Our skilled developers are ready to assist!</p>
            {/* <button className="new-task-button" style={{ borderRadius: '8px', padding: '8px, 16px, 8px, 16px' }}>Take a quick tour in WP-SOFA</button> */}
        </div >
    )
}

export default CreateTaskIntro