import React, { useEffect, useContext, useState, useCallback, useMemo } from "react";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast, Flip } from "react-toastify";
import "reactjs-popup/dist/index.css";
import { deleteMessage, translateMessage } from "../../redux/messageSlice";
import { setNew, setReply, setEdit } from "../../redux/messageInputSlice";
import { newMessage, addOthersUnread } from "../../redux/taskSlice";
import { currentSlice } from "../../redux/currentSlice";
import { useDispatch, useSelector } from "react-redux";
import api from "../../utils/axios";
import axios from "axios";
import { SocketContext } from "../../context/socket";
import { TRANSLATE_API_KEY, API_URL, ROLE, LIMIT_TRANSLATE } from "../../constants/constants";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { useTranslation } from "react-i18next";
import { decodeEntities } from "../../utils/common";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../../assets/css/Message.css";
import { LANGUAGE } from '../../constants/constants'

const MY_MESSAGE = 1;
const OTHER_MESSAGE = 2;

const Message =
  React.memo(
  ({ id, message, type, deleted, replied, edited, replymessage, createdAt, task }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socket = useContext(SocketContext);

    const currentTask = useSelector((state) => state.current)

    const messages = useSelector((state) => state.message);

    const [user, setUser] = useState({});

    const [completedMessage, setCompletedMessage] = useState();
    const [hoverTranslateLanguage, setHoverTranslateLanguage] = useState("");
    const [hoverTranslateTooltip, setHoverTranslateTooltip] = useState(false);

    const [completeReplyMessage, setCompletedReplyMessage] = useState("");

    const [editedMessage, setEditedMessage] = useState("");

    const userInfo = getUserFromLocalStorage();

    useEffect(() => {
      const user = getUserFromLocalStorage();
      setUser(user);
    }, []);

    useEffect(() => {
      let tempMessage = message.replace(/\r*\n/g, "<br/>");
      tempMessage = linkify(tempMessage);
      setCompletedMessage(tempMessage);
    }, [message]);

    useEffect(() => {
      if (edited) {
        setEditedMessage(
          "<span style='font-style : italic; margin-left: 10px; font-size: 15px'>(edited)</span>"
        );
      } else {
        setEditedMessage("");
      }
    }, [edited]);

    useState(() => {
      if (replied) {
        let tempMessage = "";
        tempMessage +=
          "<div><div><button style='background : transparent; border: none; color : #fff;'><i class='bi bi-arrow-return-left mb-4'></i></button></div>";
        tempMessage +=
          "<div style='font-style : italic; word-break: break-word; overflow: hidden;text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 5; line-clamp: 5; -webkit-box-orient: vertical;'>";
        tempMessage += replymessage.message.replace(/\r*\n/g, "<br/>");
        tempMessage += "</div>";
        tempMessage += "<hr/></div>";
        setCompletedReplyMessage(tempMessage);
      }
    }, [replymessage]);

    const linkify = (text) => {
      var urlRegex =
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, function (url) {
        return (
          '<a  href="' +
          url +
          '" target="_blank" style="' +
          "color:" +
          (type == OTHER_MESSAGE ? '#4d4d4d"' : '#fff"') +
          '">' +
          url +
          "</a>"
        );
      });
    };

    const removeMessage = useCallback(async () => {
      await api
        .post(`${API_URL}/api/v1/message/delete/`, {
          messageId: id,
        })
        .then((res) => {
          dispatch(deleteMessage(res.data._id));
          socket.emit("delete-message", res.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }

          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    }, [id]);

    const onHoverTranslate =
      useCallback(
        async (isTooltip) => {
          if (!isTooltip) return setHoverTranslateTooltip(() => false);
          else {
            setHoverTranslateTooltip(() => true);
            const user = getUserFromLocalStorage();
            let translateLanguage = "en";
            if (typeof user.translatelanguage !== "undefined")
              translateLanguage = user.translatelanguage;
            else translateLanguage = "en";

            await api
            .post(`${API_URL}/api/v1/message/translate/`, {
              message: message,
              language: translateLanguage,
              key: "message" + id
            })
            .then(({ data }) => {
              setHoverTranslateLanguage(
                () => data.data.translations[0].translatedText
              )
            }
            )
            .catch((err) => console.log(err));
          }
        }
        ,
        [message]
      );

    const translate = useCallback(async () => {
      const user = getUserFromLocalStorage();
      let translateLanguage = "en";
      if (typeof user.translatelanguage !== "undefined")
        translateLanguage = user.translatelanguage;
      else translateLanguage = "en";

      await api
        .post(`${API_URL}/api/v1/message/translate/`, {
          message: message,
          language: translateLanguage,
        })
        .then((res) => {
          let translatedMessage = decodeEntities(
            res.data.data.translations[0].translatedText
          );
          dispatch(
            translateMessage({ messageId: id, message: translatedMessage })
          );
        })
        .catch((err) => console.log(err));

      // DeepL Translate
      // await axios
      //   .post(
      //     "https://api-free.deepl.com/v2/translate",
      //     {
      //       text: message,
      //       target_lang: "DE",
      //     },
      //     {
      //       headers: {
      //         Authorization: `DeepL-Auth-Key ${DEEPL_AUTH_KEY}`,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     let translatedMessage;
      //     dispatch(
      //       translateMessage({ messageId: id, messasge: translatedMessage })
      //     );
      //   })
      //   .catch((err) => console.log(err));
    }, [message]);

    const edit = useCallback(() => {
      dispatch(setEdit({ id: id, message: message }));
    }, [id, message]);

    const reply = useCallback(() => {
      dispatch(setReply({ id: id, message: message }));
    }, [id, message]);

    const sendMessage = async (message) => {
      const user = getUserFromLocalStorage();

      await api
        .post(`${API_URL}/api/v1/message/send/`, {
          message: message,
          images: [],
          files: [],
          taskId: currentTask._id,
        })
        .then((res) => {
          // dispatch(addMessage(res.data));
          dispatch(newMessage({ taskId: currentTask._id, message: res.data.message }));

          dispatch(
            addOthersUnread({
              taskId: currentTask._id,
              userId: user._id,
              senderId: user._id,
            })
          );

          // After store message, emit message sent event
          socket.emit("new-message", res.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }

          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    };

    return (
      // <div className={mine ? "m-3 d-flex justify-content-end" : "m-3"}>
      //   <div className={mine ? "message-me" : "message-user"}>
      //     <div>
      //       <div className={mine ? "message-text-me" : "message-text-user"}>
      //         {message}
      //       </div>
      //       <div className="d-flex justify-content-end">
      //         {time}
      //         {checked ? (
      //           <i className="bi bi-check-all"></i>
      //         ) : (
      //           <i className="bi bi-check"></i>
      //         )}
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div
        style={{
          height: '100%'
        }}
        className={
          type == MY_MESSAGE
            ? "m-1 d-flex justify-content-end"
            : type == OTHER_MESSAGE
              ? "m-1"
              : "m-1 d-flex justify-content-center"
        }
      >
        <div className={type == MY_MESSAGE ? "message-me" : "message-user"}>
          <div className="d-flex">
            <div className="position-relative d-flex align-items-center">
              {/* <div className="d-flex justify-content-end">{convertedTime}</div> */}

              {/* {type == OTHER_MESSAGE ?
                            <OverlayTrigger
                                show={hoverTranslateTooltip}
                                onToggle={(!deleted && type == OTHER_MESSAGE && user.role !== ROLE.CLIENT) ? onHoverTranslate : () => undefined}
                                placement="top"
                                delay={{show: 100, hide: 100}}
                                overlay={<Tooltip id="button-tooltip">{hoverTranslateLanguage}</Tooltip>}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: deleted
                                            ? "<div style='color: #bbbbbb; font-style: italic;'>Ø message is deleted</div>"
                                            : completeReplyMessage + completedMessage + editedMessage,
                                    }}
                                    className={` ${ type == OTHER_MESSAGE ? " message-text-user " : " message-text-me message-text-admin "} `}
                                />
                            </OverlayTrigger>
                            :
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: deleted
                                        ? "<div style='color: #bbbbbb; font-style: italic;'>Ø message is deleted</div>"
                                        : completeReplyMessage + completedMessage + editedMessage,
                                }}
                                style={{color: "white"}}
                                className={"message-text-me message-text-admin"}
                            />
                        } */}

              <OverlayTrigger
                show={hoverTranslateTooltip}
                onToggle={
                  !deleted && user.role !== ROLE.CLIENT
                    ? onHoverTranslate
                    : () => undefined
                }
                placement="top"
                delay={{ show: 100, hide: 100 }}
                overlay={
                  <Tooltip id="button-tooltip">
                    {hoverTranslateLanguage}
                  </Tooltip>
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: deleted
                      ? "<div style='color: #bbbbbb; font-style: italic;'>Ø message is deleted</div>"
                      : completeReplyMessage + completedMessage + editedMessage,
                  }}
                  className={` ${type == OTHER_MESSAGE
                    ? " message-text-user "
                    : " message-text-me message-text-admin "
                    } `}
                />
              </OverlayTrigger>
              {/* {
                type === OTHER_MESSAGE && userInfo.role === ROLE.ADMIN
              }
              {
                type === OTHER_MESSAGE && <button onClick={handleClick}>click me</button>
              }
              {
                isOnline ? 'online' : 'offline'
              } */}
              {/* {
                checkMessageTime(messages[messages.length - 1].createdAt)
              } */}
              {/* {!deleted &&
                type == OTHER_MESSAGE &&
                user.role !== ROLE.CLIENT && (
                  <button className="translate-button" onClick={translate}>
                    <i className="bi bi-translate"></i>
                  </button>
                )} */}
            </div>
            {!deleted && (
              <div className="popup-area">
                <Popup
                  trigger={
                    <button className="popup-button">
                      <i className="bi bi-three-dots-vertical"></i>
                    </button>
                  }
                  position="bottom right"
                  arrow={false}
                >
                  <div className="menu">
                    {type == OTHER_MESSAGE && user.role !== ROLE.CLIENT && (
                      <div className="menu-item" onClick={translate}>
                        <i className="bi bi-translate"></i> &nbsp;{" "}
                        {t("translate")}
                      </div>
                    )}
                    {type == MY_MESSAGE && (
                      <div className="menu-item" onClick={edit}>
                        <i className="bi bi-pencil"></i> &nbsp; {t("edit")}
                      </div>
                    )}
                    <CopyToClipboard
                      text={message}
                      onCopy={() =>
                        toast.success("Copied.", {
                          position: "top-center",
                          autoClose: 200,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                          transition: Flip,
                        })
                      }
                    >
                      <div className="menu-item">
                        <i className="bi bi-stickies"></i> &nbsp; {t("copy")}
                      </div>
                    </CopyToClipboard>
                    <div className="menu-item" onClick={reply}>
                      <i className="bi bi-reply"></i> &nbsp; {t("reply")}
                    </div>
                    {type == MY_MESSAGE && (
                      <div className="menu-item" onClick={removeMessage}>
                        <i className="bi bi-x-square"></i> &nbsp; {t("remove")}
                      </div>
                    )}
                  </div>
                </Popup>
              </div>
            )}

            {/* {type != 1 ? (
            <div className="d-flex justify-content-end">
              {time}
              {checked ? (
                <i className="bi bi-check-all"></i>
              ) : (
                <i className="bi bi-check"></i>
              )}
            </div>
          ) : (
            <></>
          )} */}
          </div>
        </div>
      </div>
    );
  }
);

export default Message;
