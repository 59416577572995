import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API_URL, CURRENCY, ROLE } from "../../constants/constants";
import api from "../../utils/axios";
import "../../assets/css/OnlineUser.css";
import { toast } from "react-toastify";
import { FormCheck } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { changeClientType, getUser } from "../../redux/userSlice";

const TypeUsers = () => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchUsername, setSearchUsername] = useState("")

    const usersStore = useSelector((state) => state.user)

    useEffect(() => {
        const getUsers = async () => {
            await api
                .get(`${API_URL}/api/v1/user/clients-type`)
                .then((res) => {
                    setLoading(false)
                    const data = res.data;
                    setUsers([...data]);
                    dispatch(getUser(res.data));

                })
                .catch((err) => {
                    setLoading(false);
                })
                .finally(() => setLoading(false))
        };

        getUsers();
    }, []);

    useEffect(() => {
        let tempUser = [...usersStore];
        if (searchUsername !== "") {
            tempUser = tempUser.filter((item) => item.username.toLowerCase().includes(searchUsername))
        }
        setUsers(tempUser)
    }, [searchUsername])

    const onToggleUserType = async (event, id) => {
        await api.post(`${API_URL}/api/v1/user/toggle-type`, {
            userId: id,
            isWpGiftcardClient: event.target.checked
        })
            .then((res) => {
                const data = res.data
                dispatch(changeClientType(id));
                setUsers([...users.map(item => item._id === data._id ? { ...item, isWpGiftcardClient: data.isWpGiftcardClient } : item)])
            })
            .catch(err => {
                console.error(err)
            })
    }

    return (
        <div className="online-user">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form>
                    <Form.Group hasValidation className="mb-3" >
                        <InputGroup className="mb-3">
                            <Form.Control placeholder="Search username" id="webtype" style={{ color: '#6E777D' }} value={searchUsername} onChange={e => {
                                e.preventDefault();
                                setSearchUsername(e.target.value)
                            }} />
                        </InputGroup>
                    </Form.Group>
                </Form>
            </div>
            <div>
                {loading ? (
                    <div className="mt-5 d-flex justify-content-center">
                        <div className="dot-windmill"></div>
                    </div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>User Name</th>
                                <th>Balance</th>
                                <th>Wp-Giftcard Client</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((user, index) => (
                                <tr key={index}>
                                    <td>{user._id}</td>
                                    <td>{user.username}</td>
                                    <td>
                                        {user.currency === CURRENCY.USD ? "$" : "€"}{" "}
                                        {user.balance.toFixed(2)}
                                    </td>
                                    <td>
                                        <FormCheck
                                            id="switchEnabled"
                                            type="switch"
                                            checked={user.isWpGiftcardClient ?? false}
                                            onChange={(event) => {
                                                onToggleUserType(event, user._id)
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default TypeUsers;