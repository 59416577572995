import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Register.css";
import Form from "react-bootstrap/Form";
import { API_URL, ROLE, CURRENCY } from "../../constants/constants";
import axios from "axios";
import Lottie from "react-lottie";
import Shape1 from "../../assets/images/Shape1.png";
import Shape2 from "../../assets/images/Shape2.png";
import Shape3 from "../../assets/images/Shape3.png";
import { toast } from "react-toastify";
import animationData from "../../assets/animation/sammy-line-remote-work-cdm.json";
import { addUserToLocalStorage } from "../../utils/localStorage";
import Logo from "../../assets/images/horizontal-logo-wp-sofa.svg";
import { useTranslation } from "react-i18next";
import { supportedLanguages } from "../../constants/languages";
import { getLang } from "../../utils/common";
import LoginIntro from "../LoginIntro";
import LoginIntroImg from "../../assets/IMG/login-intro.png"
import LoginIntroPerson1 from "../../assets/images/login-intro-person-1.png"
import LoginIntroPerson2 from "../../assets/images/login-intro-person-2.png"
import "../../assets/css/LoginIntro.css"
import { ReactTyped } from "react-typed";
import { isValidEmail } from "../../utils/helper";

export default function RegisterAgencyClient() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isSigningUp, setIsSigningUp] = useState(false);

  const [role, setRole] = useState(ROLE.CLIENT);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [currency, setCurrency] = useState(CURRENCY.USD);
  const [translateLanguage, setTranslateLanguage] = useState(getLang());
  const [cPassword, setCPassword] = useState("");
  const [country, setCountry] = useState("");
  const [checked, setChecked] = useState(false);
  const [userAgreementDate, setUserAgreementDate] = useState(new Date())
  const [version, setVersion] = useState('')

  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const cpasswordRef = useRef(null);
  const clientroleRef = useRef(null);
  const devroleRef = useRef(null);
  const registerbuttonRef = useRef(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  const signup = () => {
    if (!isValidEmail(email)) {
      toast.error("Email invalidate")
      return;
    }

    if (password == cPassword) {
      if (!checked) {
        toast.warning("Please agree to our terms of agreement");
        return;
      };
      setIsSigningUp(true);
      axios
        .post(`${API_URL}/api/v1/auth/agency-client-register`, {
          username,
          email,
          password,
          role,
          currency,
          translatelanguage: translateLanguage,
          country,
          userAgreementDate,
          version,
          clientType: 1
        })
        .then((res) => {
          setIsSigningUp(false)
          toast.success("Signup success!");
          addUserToLocalStorage(res.data);
          navigate("/chat");
        })
        .catch((err) => {
          setIsSigningUp(false);
          usernameRef.current.focus();
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    }

    else toast.error("Password not match");
  };

  const goBack = () => {
    navigate("/login");
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 7000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="register">
      {/* <div className="container">
        <img className="logo" src={Logo} />
      </div> */}

      <div className="main-content">
        <div className="row">
          <div className="col-6 d-flex align-items-center" style={{ paddingLeft: '10%' }}>
            <div className="w-75 mb-4">
              <img className="logo" src={Logo} />
              <div className="d-flex justify-content-between align-items-baseline">
                <div className="title1">{t("sign_up")}</div>
                <div className="d-flex">
                  {t("already_have")} &nbsp;
                  <button className="gotosignup" onClick={goBack}>
                    {t("sign_in!")}
                  </button>
                </div>
              </div>

              <div className="mt-5">
                <div>{t("your_name")}</div>
                <input
                  ref={usernameRef}
                  className="name w-100"
                  value={username}
                  placeholder="John Smith"
                  onChange={(e) => setUsername(e.target.value)}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (key === "Enter") emailRef.current.focus();
                  }}
                />
              </div>

              <div className="mt-3">
                <div>{t("your_e_mail")}</div>
                <input
                  ref={emailRef}
                  type="email"
                  className="name w-100"
                  value={email}
                  placeholder="john.smith@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (key === "Enter") passwordRef.current.focus();
                  }}
                />
              </div>

              <div className="mt-3">
                <div>{t("create_password")}</div>
                <input
                  ref={passwordRef}
                  type="password"
                  className="name w-100"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (key === "Enter") cpasswordRef.current.focus();
                  }}
                />
              </div>

              <div className="mt-3">
                <div className="input-text">{t("confirm_password")}</div>
                <input
                  ref={cpasswordRef}
                  type="password"
                  className="name w-100"
                  value={cPassword}
                  onChange={(e) => setCPassword(e.target.value)}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (key === "Enter") clientroleRef.current.focus();
                  }}
                />
              </div>
              <div className="mt-3">
                <div className="input-text">
                  {t("your_language")}
                </div>
                <select
                  className="name w-100"
                  value={translateLanguage}
                  onChange={event => setTranslateLanguage(event.target.value)}
                >
                  {supportedLanguages.map((item, index) => {
                    return (
                      <option key={index} value={item.code}>
                        {item.language}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* <div className="mt-3">
                <div>Select your role</div>
                <div
                  className="d-flex align-items-baseline"
                  style={{ marginTop: "10px" }}
                >
                  <input
                    className="currency-choose"
                    // ref={clientroleRef}
                    checked={role == ROLE.CLIENT ? true : false}
                    inline
                    name="role-group"
                    type="radio"
                    onChange={(e) => {
                      if (e.target.checked) setRole(ROLE.CLIENT);
                    }}
                    id="client"
                    onKeyDown={(e) => {
                      const { key } = e;
                      if (key === "Enter") registerbuttonRef.current.focus();
                    }}
                  />
                  <label className="ms-2" htmlFor="client">
                    Client
                  </label>
                  <input
                    className="ms-5 currency-choose"
                    // ref={devroleRef}
                    checked={role == ROLE.DEV ? true : false}
                    inline
                    name="role-group"
                    type="radio"
                    onChange={(e) => {
                      if (e.target.checked) setRole(ROLE.DEV);
                    }}
                    id="developer"
                    onKeyDown={(e) => {
                      const { key } = e;
                      if (key === "Enter") registerbuttonRef.current.focus();
                    }}
                  />
                  <label className="ms-2" htmlFor="developer">
                    Developer
                  </label>
                </div>
              </div> */}
              <div className="mt-3">
                <div>{t("currency")}</div>
                <div
                  className="d-flex align-items-baseline"
                  style={{ marginTop: "10px" }}
                >
                  <input
                    className="currency-choose"
                    ref={clientroleRef}
                    checked={currency == CURRENCY.USD ? true : false}
                    inline
                    id="usd"
                    name="group1"
                    type="radio"
                    onChange={(e) => {
                      if (e.target.checked) setCurrency(CURRENCY.USD);
                    }}
                    onKeyDown={(e) => {
                      const { key } = e;
                      if (key === "Enter") registerbuttonRef.current.focus();
                    }}
                  />
                  <label className="ms-2" htmlFor="usd">
                    {t("usd")}
                  </label>
                  <input
                    className="ms-5 currency-choose"
                    ref={devroleRef}
                    checked={currency == CURRENCY.EURO ? true : false}
                    inline
                    id="euro"
                    name="group1"
                    type="radio"
                    onChange={(e) => {
                      if (e.target.checked) setCurrency(CURRENCY.EURO);
                    }}
                    onKeyDown={(e) => {
                      const { key } = e;
                      if (key === "Enter") registerbuttonRef.current.focus();
                    }}
                  />
                  <label className="ms-2" htmlFor="euro">
                    {t("euro")}
                  </label>
                </div>
              </div>
              <div className="mt-3">
                {/* <div className="mb-2">{t("terms_of_agreement")}</div> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input style={{
                    border: '1px solid #d9ebff',
                    borderRadius: '5px',
                    width: '24px',
                    height: '24px',
                    marginRight: '10px',
                    cursor: 'pointer'
                  }} type="checkbox" value={checked} onChange={() => setChecked(!checked)} />
                  <div style={{ marginTop: '6px' }}>By checking this box, I have read and agree to the <a href="terms-of-agreement">terms and agreement </a>(*).</div>
                </div>
              </div>
              <div className="text-center">
                {
                  isSigningUp ? <button className="join" style={{ width: '100%' }}>{t("signing_up")}</button> : <button
                    ref={registerbuttonRef}
                    className="join"
                    onClick={signup}
                    style={{ width: "100%" }}
                  >
                    {t("sign_up")}
                  </button>
                }

              </div>
            </div>
          </div>
          <div className="login-intro col-6" style={{ height: "120vh" }}>
            <div className="login-intro-container">
              <h1 className="login-intro-title">Wordpress plugin support <br /> and wordpress help for <br /> everyone</h1>
              <img src={LoginIntroImg} className="login-intro-image" />
              <div className="login-intro-message-container">
                <div className="login-intro-message login-intro-message-1 fade-in-fade-out">
                  <img src={LoginIntroPerson2}></img>
                  <ReactTyped
                    strings={[
                      "Hello! Could you please help with a WordPress plugin?"
                    ]}
                    typeSpeed={100}
                    backSpeed={100}
                    loop
                  />
                </div>
                {
                  isVisible && <div id='test' className="login-intro-message login-intro-message-2 fade-in-fade-out-2">
                    <img src={LoginIntroPerson1}></img>
                    <ReactTyped
                      strings={[
                        "Sure, please provide additional details for an in-depth analysis."
                      ]}
                      typeSpeed={100}
                      backSpeed={100}
                      loop
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
