import React, { useState, useEffect } from "react";
import "../../assets/css/Billing.css";
import Balance from "./Balance";
import BillingInfo from "./BillingInfo";
import BillingHistory from "./BillingHistory";
import Deposit from "./Deposit";
import ClientBalance from "./ClientBalance";
import ClientHistory from "./ClientHistory";
import { useTranslation } from "react-i18next";
import ExtraServices from "./ExtraServices";
import { ROLE } from "../../constants/constants";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { useSelector } from "react-redux";
import TasksWithGiftCode from "../GiftCode/TasksWithGiftCode";

const TAB = {
  BALANCE: 0,
  BILLING_INFO: 1,
  BILLING_HISTORY: 2,
  DEPOSIT: 3,
  EXTRA_SERVICES: 4,
  CLIENT_BALANCE: 5,
  CLIENT_HISTORY: 6,
  GIFT_CODE: 7
};

export default function Billing() {
  const { t } = useTranslation();
  const billingTab = useSelector((state) => state.login.billingTab);
  const [selectedTab, setSelectedTab] = useState(TAB.BALANCE);
  const [role, setRole] = useState(ROLE.ADMIN);

  // Set user role state
  const user = getUserFromLocalStorage();
  useEffect(() => {
    setRole(user.role);
  }, []);

  useEffect(() => {
    const isNewUser = user.isnewuser;
    isNewUser && setSelectedTab(TAB.DEPOSIT);
  }, [billingTab])

  return (
    <div className="billing p-5 d-flex jusify-content-start flex-column">
      <div className="title">{t("billing")}</div>
      <div className="tab">
        <ul>
          <li
            className={selectedTab == TAB.BALANCE ? "active" : ""}
            onClick={() => setSelectedTab(TAB.BALANCE)}
          >
            {t("balance")}
          </li>
          <li
            className={selectedTab == TAB.BILLING_INFO ? "active" : ""}
            onClick={() => setSelectedTab(TAB.BILLING_INFO)}
          >
            {t("billing_info")}
          </li>
          <li
            className={selectedTab == TAB.BILLING_HISTORY ? "active" : ""}
            onClick={() => setSelectedTab(TAB.BILLING_HISTORY)}
          >
            {t("billing_history")}
          </li>
          <li
            className={selectedTab == TAB.DEPOSIT ? "active" : ""}
            onClick={() => setSelectedTab(TAB.DEPOSIT)}
          >
            {t("deposit")}
          </li>
          <li
            className={selectedTab == TAB.EXTRA_SERVICES ? "active" : ""}
            onClick={() => setSelectedTab(TAB.EXTRA_SERVICES)}
          >
            {t("extra_services")}
          </li>
          {role === ROLE.ADMIN && (
            <>
              <li
                className={selectedTab == TAB.CLIENT_BALANCE ? "active" : ""}
                onClick={() => setSelectedTab(TAB.CLIENT_BALANCE)}
              >
                {t("customer_balances")}
              </li>
              <li
                className={selectedTab == TAB.CLIENT_HISTORY ? "active" : ""}
                onClick={() => setSelectedTab(TAB.CLIENT_HISTORY)}
              >
                {t("customer_billing_history")}
              </li>
              {/* <li
                className={selectedTab == TAB.GIFT_CODE ? "active" : ""}
                onClick={() => setSelectedTab(TAB.GIFT_CODE)}
              >
                Gift Code
              </li> */}
            </>
          )}
        </ul>
      </div>
      <div className="flex-auto" style={{ overflowX: "hidden" }}>
        {selectedTab == TAB.BALANCE ? <Balance /> : <></>}
        {selectedTab == TAB.BILLING_INFO ? <BillingInfo /> : <></>}
        {selectedTab == TAB.BILLING_HISTORY ? <BillingHistory /> : <></>}
        {selectedTab == TAB.DEPOSIT ? <Deposit /> : <></>}
        {selectedTab == TAB.EXTRA_SERVICES ? <ExtraServices /> : <></>}
        {selectedTab == TAB.CLIENT_BALANCE ? <ClientBalance /> : <></>}
        {selectedTab == TAB.CLIENT_HISTORY ? <ClientHistory /> : <></>}
        {/* {selectedTab == TAB.GIFT_CODE ? <TasksWithGiftCode /> : <></>} */}
      </div>
    </div>
  );
}
