import React from 'react'
import { useTranslation } from "react-i18next";
import "../../../assets/css/ChatWithDevIntro.css";
import { useSelector } from 'react-redux';

const ChatWithDevIntro = () => {
    const { t } = useTranslation();
    return (
        <div>
            {/* <img src={linkdinIcon} /> */}
            {
                // currentTask
            }
            <div style={{
                display: 'flex',
                alignItems: 'center'
                // gridTemplateColumns: '1fr 5fr 12fr'
            }}>
                <div className="position-relative">
                    <img
                        style={{
                            borderRadius: '50%',
                            height: '35px',
                            width: '35px',
                            objectFit: 'cover',
                            marginRight: '15px',
                        }}
                        src='https://res.cloudinary.com/dbjfuowqq/image/upload/v1667271453/wp-sofa-avatar/rhd6fk5aggwlsifmq8ng.jpg'
                    />
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '3px',
                            right: '15px',
                            width: '8px',
                            height: '8px',
                            backgroundColor: '#2af26e',
                            borderRadius: '50%',
                            border: 'solid 1px #fff'
                        }}
                        className='online-status'
                    ></div>
                </div>
                <h6 style={{ alignSelf: 'center', marginRight: '12px' }}>Patrick</h6>
                <small style={{ alignSelf: 'center', color: '#717C97' }}>from WP-SOFA</small>
            </div>
            <h5 style={{marginTop: '10px', fontSize: '20px', fontWeight: '500', marginBottom: '15px'}}>Chat with developer</h5>
            <p style={{marginBottom: '15px', fontSize: '14px', lineHeight: '16px'}}>Ready to chat with our expert developers for plugin assistance?</p>
            <p style={{fontSize: '14px', lineHeight: '16px', marginBottom: '-30px'}}>Describe your plugin issue and our developers will join the chat to help you out.</p>
            {/* <button className="new-task-button" style={{ borderRadius: '8px', padding: '8px, 16px, 8px, 16px' }}>Take a quick tour in WP-SOFA</button> */}
        </div>
    )
}

export default ChatWithDevIntro