import axios from 'axios';

export const fetchPageSpeedData = async (url) => {
  const apiKey = 'AIzaSyBeVO9IK93vmIhLjISnKV1J3RKuqI3ZsMk';
  const apiUrl = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(url)}&key=${apiKey}&strategy=mobile`;

  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error("Error fetching PageSpeed Insights data:", error);
    return null;
  }
};