import React, { useState, useEffect } from "react";
import { API_URL } from "../../constants/constants";
import api from "../../utils/axios";
import { toast } from "react-toastify";

import "./ExtraService.css";

export default function ExtraService() {
  const [extraServices, setExtraServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      await api
        .get(`${API_URL}/api/v1/extraservice/all`)
        .then((res) => {
          setExtraServices(res.data);
          setLoading(false);
        })
        .catch((err) => {
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
          setLoading(false);
        });
    };

    getData();
  }, []);

  return (
    <div className="extra-service">
      {/* <div className="title">Extra Service</div> */}
      <div>
        {loading ? (
          <div className="mt-5 d-flex justify-content-center">
            <div className="dot-windmill"></div>
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>User</th>
                <th>Support from us</th>
                <th>Speed up your website</th>
                <th>Security check</th>
              </tr>
            </thead>
            <tbody>
              {extraServices?.map((service, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{service?.user?.username}</td>
                    <td>
                      {service?.support ? (
                        <div className="checked-status"></div>
                      ) : (
                        <div className="unchecked-status"></div>
                      )}
                    </td>
                    <td>
                      {service?.speedup ? (
                        <div className="checked-status"></div>
                      ) : (
                        <div className="unchecked-status"></div>
                      )}
                    </td>
                    <td>
                      {service?.security ? (
                        <div className="checked-status"></div>
                      ) : (
                        <div className="unchecked-status"></div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
