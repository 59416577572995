export const removeAllListener = (socket) => {
    socket.removeAllListeners("task-received");
    socket.removeAllListeners("task-deleted");
    socket.removeAllListeners("estimate-received");
    socket.removeAllListeners("changeoffer-received");
    socket.removeAllListeners("confirm-received");
    socket.removeAllListeners("assign-received");
    socket.removeAllListeners("changeprogress-received");
    socket.removeAllListeners("complete-received");
    socket.removeAllListeners("pay-received");
    socket.removeAllListeners("read-received");
    socket.removeAllListeners("submitrate-received");
    socket.removeAllListeners("change-status-for-task-done-no-response");
};

export const addSocketEventListener = async (socket, event, callback) => {
    let exist = socket.hasListeners(event)
    if (exist) {
        await socket.removeAllListeners(event);
        socket.on(event, callback)
    } else {
        socket.on(event, callback);
    }
};