import React, { useEffect, useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from 'react-player'
import NewTask from "./NewTask";
import {
  addAssign,
  addConfirm,
  addEstimate,
  addTask,
  addChangeProgress,
  addComplete,
  addPay,
  setTasks,
  addOtherRead,
  addChangeOffer,
  deleteTask,
  removeUnread,
  submitRateTask,
  updateTask,
  addOthersUnread,
  newMessage,
  fetchTasks,
  incrementPage,
  setFilters,
  setClientFilter,
  searchTasks,
  setTaskname,
  clearFilters,
  deleteAllTasks
} from "../../redux/taskSlice";
import { deleteCurrentTask, setCurrentTask } from "../../redux/currentSlice";
import { Route, useNavigate } from "react-router-dom";
import Task from "./Task";
import api from "../../utils/axios";
import { API_URL, CURRENCY, ROLE, STATUS, ADMIN_NAME, LANGUAGE } from "../../constants/constants";
import "../../assets/css/Tasks.css";
import { addUserToLocalStorage, getUserFromLocalStorage, removeUserFromLocalStorage } from "../../utils/localStorage";
import { changeBalance } from "../../redux/loginSlice";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import { addSocketEventListener, removeAllListener } from "../../utils/socketCommon";
import Autocomplete from "react-autocomplete";
import Loading from "./Loading";
import {
  browserName,
  osName,
  deviceType,
  isMobile,
  isTablet,
} from "react-device-detect";
import Logo from "../../assets/images/sofa.png";

let taskLength = 0;
let selectedTask = {};

export default function Tasks({ socket }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tasks, totalTasks, page, taskname, filters, hasMore, loading } = useSelector((state) => state.task);

  const [role, setRole] = useState(ROLE.CLIENT);
  const [showNewTask, setShowNewTask] = useState(false);
  const navigate = useNavigate();
  // const socket = useContext(SocketContext);
  const task = useSelector((state) => state.current);
  const [selectedClient, setSelectedClient] = useState("000000000000");
  const [clientInput, setClientInput] = useState("");
  const [clientList, setClientList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [searchTaskname, setSearchTaskname] = useState("");

  const [status, setStatus] = useState("All");
  const [selectedProject, setSelectedProject] = useState("");
  const [priority, setPriority] = useState("All");
  const [showSearchPane, setShowSearchPane] = useState(true);

  const currentTaskRef = useRef(null);

  // const [searchTasks, setSearchTasks] = useState([]);
  const [totalUserDue, setTotalDue] = useState(0);
  const [summary, setSummary] = useState({ total: 0, totalCost: 0 });
  const [user, setUser] = useState();
  const [userData, setUserData] = useState(() => getUserFromLocalStorage());
  // const statusList = [
  //   t("to_estimate"),
  //   t("to_confirm"),
  //   t("to_do"),
  //   t("in_progress"),
  //   t("completed"),
  //   t("finished"),
  //   "",
  //   "",
  //   "",
  //   t("archived"),
  //   t("notLastMessage"),
  //   t("all"),
  // ];

  // CREATED: 0,
  // ESTIMATED: 1,
  // CONFIRMED: 2,
  // ASSIGNED: 3,
  // COMPLETED: 4,
  // PAID: 5,
  // DELETED: 6,
  // PAID_DELETED: 7,
  // GETFEEDBACK: 8,
  // ARCHIVED: 9
  const statusList = [
    {
      // id: 7,
      text: t("all")
    },
    {
      id: 0,
      text: t("to_estimate")
    },
    {
      id: 1,
      text: t("to_confirm")
    },
    {
      id: 2,
      text: t("to_do")
    },
    {
      id: 3,
      text: t("in_progress")
    },
    {
      id: 4,
      text: t("completed")
    },
    {
      id: 5,
      text: t("finished")
    },
    {
      id: 8,
      text: t("archived")
    },
    {
      id: 10,
      text: t("notLastMessage")
    },
    // t("notLastMessage"),

  ];

  const priorityList = [t("low"), t("normal"), t("urgent"), t("all")];

  // Set user role and Get client list for admin
  useEffect(() => {
    const getClient = async () => {
      const user = getUserFromLocalStorage();
      if (user) {
        setRole(user.role)
      }

      setTimeout(() => socket.emit("join-chat", user._id), 1000);
      addSocketEventListener(socket, "updated-user", (user) => {
        let currentUser = getUserFromLocalStorage();
        currentUser.balance = user.balance;
        currentUser.translatelanguage = user.translatelanguage;
        addUserToLocalStorage(currentUser);
      })

      if (user.role == ROLE.ADMIN)
        api
          .get(`${API_URL}/api/v1/user/client`)
          .then((res) => {
            let data = res.data;
            data.sort((a, b) => {
              return new Date(b.lastseen).valueOf() - new Date(a.lastseen).valueOf();
            })
            data.push({
              _id: "",
              username: "All",
            });
            setClientList([...data]);
          })
          .catch();

      if (user.isprojectenable)
        api
          .get(`${API_URL}/api/v1/task/projects`)
          .then((res) => {
            const data = res.data;
            setProjectList([
              {
                _id: "",
                name: "",
              }
              , ...data]);
          })
          .catch();
    };
    getClient();
    const tempUser = getUserFromLocalStorage();
    setUser(tempUser);
    const filterByClientId = new URLSearchParams(window.location.search).get('clientId');
    const filterByStatus = new URLSearchParams(window.location.search).get('status');
    filterByClientId && setSelectedClient(filterByClientId);
    filterByStatus && Number.isInteger(Number(filterByStatus)) && setStatus(statusList[Number(filterByStatus)]);
  }, []);

  useEffect(() => {
    if (user && user.isprojectenable)
      api
        .get(`${API_URL}/api/v1/task/projects`)
        .then((res) => {
          const data = res.data;
          setProjectList([{
            _id: "",
            name: "",
          }, ...data]);
        })
        .catch();
  }, [showNewTask]);

  // This part is for moving to current task
  useEffect(() => {
    const goToTop = async () => {
      selectedTask = task;
      await delaytimeout(2000);
      scrollToTop();
    };

    // goToTop();
  }, [task]);

  // Task filtering and ordering
  // useEffect(() => {
  //   taskLength = tasks.length;
  //   const user = getUserFromLocalStorage();
  //   let tempTask = [...tasks];
  //   let userTask = tasks.filter(item => item?.client?._id === user._id && item.status > STATUS.CONFIRMED && item.status < STATUS.PAID);
  //   let totalDueCost = 0;
  //   userTask.forEach(item => totalDueCost += (item.hourlyrate || 0) * (item.workeffort || 0));
  //   setTotalDue(totalDueCost);
  //   let totalTaskCost = 0;

  //   // Filter by taskname
  //   // tempTask = tempTask.filter((item) =>
  //   //   item.taskname.includes(searchTaskname)
  //   // );

  //   // Filter by client
  //   // if (selectedClient !== "000000000000")
  //   //   tempTask = tempTask.filter((item) => item?.client?._id == selectedClient);

  //   // Filter by priority and
  //   // if (priority !== "All" && status !== "All")
  //   //   tempTask = tempTask.filter(
  //   //     (item) => status == statusList[item.status] && priority == item.priority
  //   //   );
  //   // else if (priority !== "All")
  //   //   tempTask = tempTask.filter((item) => priority == item.priority);
  //   // else if (status !== "All" && status !== t('notLastMessage'))
  //   //   tempTask = tempTask.filter((item) => status == statusList[item.status]);

  //   // if(status === "All")
  //   //   tempTask = tempTask.filter((item) => item.status !== statusList.indexOf(t("archived")));

  //   // if (selectedProject)
  //   //   tempTask = tempTask.filter(item => item.project && item.project._id === selectedProject);

  //   // if (status === t('to_do') || status === t('in_progress')) {
  //   //   tempTask.forEach(item => { totalTaskCost += item.hourlyrate * item.workeffort })
  //   // }

  //   // if (status === t('notLastMessage')) {
  //   //   tempTask = tempTask.filter(item => item.latestMessage && item.latestMessage.sender && item.latestMessage.sender.role == ROLE.CLIENT);
  //   // }
  //   // let unreadTempTask = [];
  //   // let readTempTask = [];
  //   // for (let i = 0; i < tempTask.length; i++) {
  //   //   let readFlag = true;
  //   //   for (let j = 0; j < tempTask[i].unread.length; j++) {
  //   //     if (
  //   //       tempTask[i].unread[j].user == user._id &&
  //   //       tempTask[i].unread[j].number != 0
  //   //     )
  //   //       readFlag = false;
  //   //   }
  //   //   if (readFlag) readTempTask.push(tempTask[i]);
  //   //   else unreadTempTask.push(tempTask[i]);
  //   // }

  //   // readTempTask.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
  //   // unreadTempTask.sort(
  //   //   (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
  //   // );

  //   // tempTask = readTempTask.concat(unreadTempTask);

  //   // Sort Task by latest change
  //   tempTask = tempTask.sort((a, b) => {
  //     return new Date(b.updatedTime).valueOf() - new Date(a.updatedTime).valueOf();
  //   });

  //   setSearchTasks(tempTask);
  //   setSummary({ total: tempTask.length, totalCost: totalTaskCost });

  //   if (
  //     task &&
  //     Object.keys(task).length === 0 &&
  //     Object.getPrototypeOf(task) === Object.prototype &&
  //     tempTask.length > 0
  //   ) {
  //     const CurrentTaskId = new URLSearchParams(window.location.search).get('taskId');

  //     const PreviousTaskId = localStorage.getItem("taskId");

  //     if (CurrentTaskId) {
  //       const selectedTask = tasks.filter(item => item._id == CurrentTaskId)[0];
  //       if (selectedTask) {
  //         dispatch(setCurrentTask(selectedTask));
  //         dispatch(removeUnread({ taskId: selectedTask._id, userId: user._id }));
  //         socket.emit("message-read", { task: selectedTask, userId: user._id });
  //       }
  //     } else if (PreviousTaskId) {
  //       const selectedTask = tasks.filter(item => item._id == PreviousTaskId)[0];
  //       if (selectedTask) {
  //         dispatch(setCurrentTask(selectedTask));
  //         dispatch(removeUnread({ taskId: selectedTask._id, userId: user._id }));
  //         socket.emit("message-read", { task: selectedTask, userId: user._id });
  //       }
  //     } else {
  //       dispatch(setCurrentTask(tempTask[tempTask.length - 1]));
  //       dispatch(
  //         removeUnread({ task: tempTask[tempTask.length - 1], user: user })
  //       );
  //       socket.emit("message-read", {
  //         task: tempTask[tempTask.length - 1],
  //         userId: user._id,
  //       });
  //     }
  //   }


  // }, [filters, page, tasks]);
  // }, [status, priority, tasks, searchTaskname, selectedClient, selectedProject]);

  const sendMessage = async (message) => {
    const user = getUserFromLocalStorage();

    await api
      .post(`${API_URL}/api/v1/message/send/`, {
        message: message,
        images: [],
        files: [],
        taskId: task._id,
      })
      .then((res) => {
        // dispatch(addMessage(res.data));
        dispatch(newMessage({ taskId: task._id, message: res.data.message }));

        dispatch(
          addOthersUnread({
            taskId: task._id,
            userId: user._id,
            senderId: user._id,
          })
        );

        // After store message, emit message sent event
        socket.emit("new-message", res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }

        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  useEffect(() => {
    addSocketEventListener(socket, "change-status-for-task-done-no-response", async (task) => {
      let taskId = task?._id
      let client = task?.client

      // pay for task
      await api
        .post(`${API_URL}/api/v1/task/pay`, {
          taskId,
        })
        .then(async (res) => {
          const data = res.data;
          const user = getUserFromLocalStorage();
          const { cost } = data;
          dispatch(addPay(data));
          dispatch(changeBalance(-1 * cost));
          user.balance += -1 * cost;
          addUserToLocalStorage(user);
          socket.emit("new-pay", data);

          let message;

          if (client.translatelanguage) {
            await api
              .post(`${API_URL}/api/v1/message/translate/`, {
                message: `Hi ${ADMIN_NAME}, I just paid for the task.`,
                language: client.translatelanguage,
              })
              .then((res) => {
                // message = decodeEntities(
                //   res.data.data.translations[0].translatedText
                // );
              });
          } else {
            if (client.language == LANGUAGE.EN) {
              message = `Hi ${ADMIN_NAME}, I just paid for the task.`;
            } else if (client.language == LANGUAGE.DE)
              message = `Hallo ${ADMIN_NAME}, ich habe gerade für die Aufgabe bezahlt.`;
            else if (client.language == LANGUAGE.ES)
              message = `Hi ${ADMIN_NAME}, I just paid for the task.`;
          }

          sendMessage(message);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });

      // task archived
      await api
        .post(`${API_URL}/api/v1/task/archived`, {
          taskId,
        })
        .then((res) => {
          dispatch(updateTask({ ...task, status: STATUS.ARCHIVED }))
          socket.emit("archive-task", { task });
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }
          const errors = err.response.data.errors;
          errors?.forEach((error) => toast.error(error.msg));
        });
    }
    )

    addSocketEventListener(socket, "task-received", (newTaskReceived) => {
      const user = getUserFromLocalStorage();

      toast.info(`Task «${newTaskReceived.taskname}» is created`, {
        theme: "colored",
      });

      dispatch(addTask(newTaskReceived));
      if (taskLength == 0 || newTaskReceived.client == user._id) {
        dispatch(setCurrentTask(newTaskReceived));
      }
      socket.emit("join-chat", newTaskReceived._id);
      addSocketEventListener(socket, "updated-user", (user) => {
        let currentUser = getUserFromLocalStorage();
        currentUser.balance = user.balance;
        currentUser.translatelanguage = user.translatelanguage;
        addUserToLocalStorage(currentUser);
      })
    });

    addSocketEventListener(socket, "task-deleted", (taskDeleteReceived) => {

      toast.info(`Task «${taskDeleteReceived.taskname}» is deleted`, {
        theme: "colored",
      });

      if (selectedTask._id == taskDeleteReceived._id)
        dispatch(deleteCurrentTask());
      dispatch(deleteTask(taskDeleteReceived._id));
    });

    addSocketEventListener(socket, "task-updated", (taskUpdateReceived) => {

      toast.info(`Task «${taskUpdateReceived.taskname}» is updated`, {
        theme: "colored",
      });
      dispatch(updateTask(taskUpdateReceived));
    });
    addSocketEventListener(socket, "task-archived", (taskArchiveReceived) => {

      toast.info(`Task «${taskArchiveReceived.taskname}» is archived`, {
        theme: "colored",
      });
      dispatch(updateTask(taskArchiveReceived));
    });

    addSocketEventListener(socket, "estimate-received", (newEstimateReceived) => {

      toast.info(`Task «${newEstimateReceived.taskname}» is estimated`, {
        theme: "colored",
      });

      dispatch(addEstimate(newEstimateReceived));
    });

    addSocketEventListener(socket, "changeoffer-received", (newChangeOfferReceived) => {

      toast.info(`Task «${newChangeOfferReceived.taskname}» offer is changed`, {
        theme: "colored",
      });

      dispatch(addChangeOffer(newChangeOfferReceived));
    });

    addSocketEventListener(socket, "confirm-received", (newConfirmReceived) => {

      toast.info(`Task «${newConfirmReceived.taskname}» is confirmed`, {
        theme: "colored",
      });

      dispatch(addConfirm(newConfirmReceived));
    });

    addSocketEventListener(socket, "changeprogress-received", (newChangeProgressReceived) => {

      toast.info(
        `Task «${newChangeProgressReceived.taskname}» is done by ${newChangeProgressReceived.percent}%`,
        {
          theme: "colored",
        }
      );

      dispatch(addChangeProgress(newChangeProgressReceived));
    });

    addSocketEventListener(socket, "assign-received", (newAssignReceived) => {
      const user = getUserFromLocalStorage();

      toast.info(`Task «${newAssignReceived.taskname}» is assigned`, {
        theme: "colored",
      });

      dispatch(addAssign({ task: newAssignReceived, role: user.role }));
    });

    addSocketEventListener(socket, "complete-received", (newCompleteReceived) => {

      toast.info(`Task «${newCompleteReceived.taskname}» is completed`, {
        theme: "colored",
      });

      dispatch(addComplete(newCompleteReceived));
    });

    addSocketEventListener(socket, "pay-received", (newPayReceived) => {
      const { cost } = newPayReceived;
      dispatch(changeBalance(cost));

      toast.info(`Task «${newPayReceived.taskname}» is paid`, {
        theme: "colored",
      });

      dispatch(addPay(newPayReceived));
    });

    addSocketEventListener(socket, "pay-fast-support-received", (newPayReceived) => {
      const { cost, task } = newPayReceived;
      dispatch(changeBalance(cost));

      toast.info(`Task «${task.taskname}» is paid for fast support`, {
        theme: "colored",
      });

      dispatch(addPay(newPayReceived));
    });

    addSocketEventListener(socket, "submitrate-received", (newSubmitRateReceived) => {
      const { cost } = newSubmitRateReceived;
      toast.info(`Task «${newSubmitRateReceived.taskname}» is get rate`, {
        theme: "colored",
      });

      dispatch(submitRateTask(newSubmitRateReceived));
    });

    addSocketEventListener(socket, "read-received", (data) => {
      const { taskId, userId } = data;
      const user = getUserFromLocalStorage();
      if (userId == user._id)
        dispatch(removeUnread({ taskId: taskId, userId }));
      else dispatch(addOtherRead({ taskId: taskId, userId: userId }));
    });

    return () => {
      removeAllListener(socket);
    };
  }, []);

  const loader = useRef(null);

  useEffect(() => {
    dispatch(fetchTasks({ page, filters }));

    // Listen for real-time updates
    const taskId = task._id;
    socket.on('task-updated', ({ taskId }) => {
      // Refetch or reorder tasks when a task is updated
      dispatch(fetchTasks({ page, filters }));
    });

    return () => {
      socket.off('task-updated');
    };
  }, [dispatch, page, filters]);

  // useEffect(() => {
  //   // Fetch tasks only when the component mounts
  //   if (!loading) {
  //     dispatch(fetchTasks({ page, filters }));
  //   }
  // }, [dispatch, loading, page, filters]); // Ensure dependencies are correct

  useEffect(() => {
    dispatch(searchTasks({ page, filters, taskname })); // Perform search with
  }, [taskname])

  // Infinite Scroll: Load more when intersecting
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          dispatch(incrementPage());
        }
      },
      { threshold: 1.0 }
    );

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [dispatch, hasMore, loading]);

  const handleFilterChange = (newFilters) => {
    dispatch(setFilters(newFilters));
    // dispatch(searchTasks({ taskname: taskname })); // Perform search with
  };

  // Getting all task
  // useEffect(() => {
  //   async function fetchData() {
  //     await api
  //       .get(`${API_URL}/api/v1/task`, {
  //         // params: { page: currentPage, limit: 10 },
  //       })
  //       .then((res) => {
  //         dispatch(setTasks(res.data));

  //         // handle delete task when client no response after 7 days
  //         let tasksTemp = [] // get tasks list done but not response after 7 days
  //         tasksTemp = res.data.filter(task => {
  //           let taskTimeLatestMessage = (new Date(task?.latestMessage?.createdAt)).getTime()
  //           const millisecondsInADay = 24 * 60 * 60 * 1000; // milliseconds

  //           // This task will be moved to paid & done in 7 days. Please reply to this chat if you have any objections.

  //           return taskTimeLatestMessage <= (new Date().getTime() - millisecondsInADay * 7) && task?.latestMessage?.sender?.role !== ROLE.CLIENT && task.status === STATUS.COMPLETED && task?.latestMessage?.message !== "This task will be moved to paid & done in 7 days, please reply to this chat if you have any objections"
  //         })
  //         // send messagebot to task no response after 7 days
  //         socket.emit("handle-task-done-no-response-after-7-days", tasksTemp)

  //         // delete task when client again no response after 14 days
  //         let tasksTemps2 // get tasks list done but not response after 14 days
  //         tasksTemps2 = res.data.filter(task => {
  //           let taskTimeLatestMessage = (new Date(task?.latestMessage?.createdAt)).getTime()
  //           const millisecondsInADay = 24 * 60 * 60 * 1000; // milliseconds
  //           return taskTimeLatestMessage <= (new Date().getTime() - millisecondsInADay * 7) && task?.latestMessage?.sender?.role !== ROLE.CLIENT && task.status === STATUS.COMPLETED && task?.latestMessage?.message === "This task will be moved to paid & done in 7 days, please reply to this chat if you have any objections"
  //         })
  //         socket.emit("handle-task-done-no-response-after-14-days", tasksTemps2)
  //       })
  //       .catch((err) => {
  //         if (err.response.status == 401) {
  //           navigate("/");
  //         }

  //         const errors = err.response.data.errors;
  //         errors.forEach((error) => toast.error(error.msg));
  //       });
  //   }
  //   fetchData();
  // }, []);

  useEffect(() => {
    tasks.forEach((item) => {
      if (item._id == task._id) dispatch(setCurrentTask(item));
    });
  }, [tasks]);

  const scrollToTop = () => {
    currentTaskRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const delaytimeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const logout = () => {
    socket.emit("logout");
    // socket.off("setup", user._id);
    dispatch(deleteAllTasks());
    dispatch(deleteCurrentTask());
    removeUserFromLocalStorage();
    navigate("/");
  };

  return (
    <>
      {
        !isMobile ?
          <div
            id="task" // id for intro
            // className={`${showNewTask ? "scroll-hidden" : ""} tasks`}
            style={{ overflowY: "scroll", height: '100vh' }}
          >
            <div className="mt-5 tasks-button">
              <div className="tasks-title">{t("tasks")}</div>
              {role !== ROLE.DEV && (
                <button
                  className="new-task-button"
                  onClick={() => setShowNewTask(true)}
                >
                  + {t("new_task")}
                </button>
              )}
            </div>
            <div className="mt-5">
              {(showNewTask) ? <NewTask setShowNewTask={setShowNewTask} /> : <></>}
              {/* {(showNewTask && userData.role === ROLE.ADMIN)  ? <NewTask setShowNewTask={setShowNewTask} /> : <></>} */}
            </div>
            {!(showNewTask || tasks.length < 3) && (
              <div
                className="text-center"
                onClick={() => setShowSearchPane(!showSearchPane)}
                style={{ cursor: "pointer" }}
              >
                {showSearchPane ? (
                  <i className="bi bi-chevron-double-up"></i>
                ) : (
                  <i className="bi bi-chevron-double-down"></i>
                )}
              </div>
            )}


            {
              showSearchPane && <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="inprogress d-flex  align-items-center">
                    <div
                      style={{
                        color: "#707C97",
                        fontSize: "24px",
                        marginRight: "10px",
                      }}
                    >
                      <i className="bi bi-funnel-fill"></i>
                    </div>
                    <div>
                      <select
                        className="estimate-select"
                        value={filters.status}
                        // onChange={(e) => setStatus(e.target.value)}
                        onChange={(e) => handleFilterChange({ ...filters, status: e.target.value })}
                      >
                        {statusList.map((item, index) => {
                          return (
                            (user && ((user.role === ROLE.CLIENT && item.text != t("notLastMessage")) || (user.role === ROLE.ADMIN || user.role === ROLE.DEV)) && item) &&
                            <option key={index} value={item.id}>
                              {item.text}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {user && user.isprojectenable &&
                      <div className="d-flex  align-items-center">
                        <div
                          style={{
                            color: "#707C97",
                            fontSize: "18px",
                            marginRight: "10px",
                          }}
                        >
                          {t("project")}:
                        </div>
                        <div>
                          <select
                            className="estimate-select"
                            // value={selectedProject}
                            value={filters.project}
                            // onChange={(e) => setSelectedProject(e.target.value)}
                            onChange={(e) => handleFilterChange({ ...filters, project: e.target.value })}
                          >
                            {projectList.map((item, index) => {
                              return (
                                item &&
                                <option key={index} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>}
                  </div>
                  <div className="d-flex  align-items-center">
                    <div
                      style={{
                        color: "#707C97",
                        fontSize: "18px",
                        marginRight: "10px",
                      }}
                    >
                      {t("priority")}:
                    </div>
                    <div>
                      <select
                        className="estimate-select"
                        value={filters.priority}
                        // onChange={(e) => setPriority(e.target.value)}
                        onChange={(e) => handleFilterChange({ ...filters, priority: e.target.value })}
                      >
                        {priorityList.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {role == ROLE.ADMIN && (
                  <>
                    <div className="inprogress d-flex  align-items-center mt-3">
                      <div
                        style={{
                          color: "#707C97",
                          fontSize: "24px",
                          marginRight: "10px",
                        }}
                      >
                        <i className="bi bi-search"></i>
                      </div>
                      <div className="flex-auto">
                        <input
                          className="task-search w-100"
                          placeholder="Search taskname"
                          value={taskname}
                          // onChange={(e) => setSearchTaskname(e.target.value)}
                          // onChange={(e) => handleFilterChange({ ...filters, taskname: e.target.value })}
                          onChange={(e) => { dispatch(setTaskname(e.target.value)) }}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="d-flex align-items-center">
                        <div
                          style={{
                            color: "#707C97",
                            fontSize: "18px",
                            marginRight: "10px",
                          }}
                        >
                          {t("client")}:
                        </div>
                        <div>
                          <Autocomplete
                            getItemValue={(item) => item.id}
                            items={clientList.map(item => ({ name: item.username, id: item._id }))}
                            value={clientInput}
                            // value={filters.client}
                            shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                            onChange={(e) => setClientInput(e.target.value)}
                            // onChange={(e) => handleFilterChange({ ...filters, client: e.target.value })}
                            inputProps={{
                              className: 'priority-select',
                              style: {
                                marginLeft: '0px'
                              }
                            }}
                            renderItem={(item, isHighlighted) =>
                              <div style={{ background: isHighlighted ? '#CBE7E9' : '#00676c', color: isHighlighted ? 'black' : 'white', paddingLeft: '10px' }}>
                                {item.name}
                              </div>
                            }
                            // onSelect={(value, item) => { setSelectedClient(value); setClientInput(item.name); navigate('/chat') }}
                            onSelect={(value, item) => { setClientInput(item.name); handleFilterChange({ ...filters, client: item.id }); navigate('/chat') }}
                          />
                        </div>
                      </div>
                      {/* Autocomplete for Client Search */}
                      {/* <div>
                    <label htmlFor="client-search">Search by Client:</label>
                    <input
                      id="client-search"
                      type="text"
                      value={clientInput}
                      onChange={(e) => {
                        setClientInput(e.target.value);
                        // fetchClientSuggestions(e.target.value);
                      }}
                      placeholder="Type to search clients..."
                    />
                    <ul>
                      {clientList?.map((client) => (
                        <li key={client._id} onClick={() => handleSelectClient(client)}>
                          {client.name}
                        </li>
                      ))}
                    </ul>
                  </div> */}
                      <button className="mt-3 clear-filters" onClick={() => { setClientInput(''); dispatch(clearFilters()); }}>{t("clear_filters")}</button>
                    </div>
                  </>
                )}
              </>
            }

            {(status === t('to_do') || status === t('in_progress')) &&
              <div className="mt-2 task-summary">{summary.total} {t('task_summary')} {summary.totalCost}{user && user.currency == CURRENCY.USD ? "$" : "€"} </div>
            }
            <h2 className="mt-4" style={{ marginBottom: '-20px', fontWeight: 500, fontSize: '17px', lineHeight: 1.3, letterSpacing: '.7px' }}>{totalTasks} Task{tasks.length > 1 && 's'} Found</h2>
            <div className={(status === t('to_do') || status === t('in_progress')) ? "mt-2" : "mt-5"}>
              {showNewTask ? (
                <></>
              ) : (
                tasks &&
                tasks
                  .map((item, index) => (
                    <div key={index}>
                      {item._id == task._id ? (
                        <div ref={currentTaskRef}></div>
                      ) : (
                        <></>
                      )}
                      <Task key={"task" + index} task={item} index={index} totalUserDue={totalUserDue} />
                    </div>
                  ))
              )}
            </div>
            {loading && <Loading />}
            {!hasMore && <p className="mt-4">{t("no_more_tasks_to_load")}</p>}

            {/* Loader element for Intersection Observer */}
            <div ref={loader} style={{ height: '20px', marginBottom: '20px' }}></div>
          </div>
          :
          // <div
          //   id="task" // id for intro
          //   // className={`${showNewTask ? "scroll-hidden" : ""} tasks`}
          //   style={{ overflowY: "scroll", height: '100vh' }}
          // >
          //   <div className="mt-5 tasks-button">
          //     <div className="tasks-title">{t("tasks")}</div>
          //     {role !== ROLE.DEV && (
          //       <button
          //         className="new-task-button"
          //         onClick={() => setShowNewTask(true)}
          //       >
          //         + {t("new_task")}
          //       </button>
          //     )}
          //   </div>
          //   <div className="mt-5">
          //     {(showNewTask) ? <NewTask setShowNewTask={setShowNewTask} /> : <></>}
          //     {/* {(showNewTask && userData.role === ROLE.ADMIN)  ? <NewTask setShowNewTask={setShowNewTask} /> : <></>} */}
          //     {/* <NewTask /> */}
          //   </div>
          //   {!(showNewTask || tasks.length < 3) && (
          //     <div
          //       className="text-center"
          //       onClick={() => setShowSearchPane(!showSearchPane)}
          //       style={{ cursor: "pointer" }}
          //     >
          //       {showSearchPane ? (
          //         <i className="bi bi-chevron-double-up"></i>
          //       ) : (
          //         <i className="bi bi-chevron-double-down"></i>
          //       )}
          //     </div>
          //   )}


          //   {
          //     showSearchPane && <>
          //       <div className="d-flex justify-content-between align-items-center">
          //         <div className="inprogress d-flex  align-items-center">
          //           <div
          //             style={{
          //               color: "#707C97",
          //               fontSize: "24px",
          //               marginRight: "10px",
          //             }}
          //           >
          //             <i className="bi bi-funnel-fill"></i>
          //           </div>
          //           <div>
          //             <select
          //               className="estimate-select"
          //               value={filters.status}
          //               // onChange={(e) => setStatus(e.target.value)}
          //               onChange={(e) => handleFilterChange({ ...filters, status: e.target.value })}
          //             >
          //               {statusList.map((item, index) => {
          //                 return (
          //                   (user && ((user.role === ROLE.CLIENT && item.text != t("notLastMessage")) || (user.role === ROLE.ADMIN || user.role === ROLE.DEV)) && item) &&
          //                   <option key={index} value={item.id}>
          //                     {item.text}
          //                   </option>
          //                 );
          //               })}
          //             </select>
          //           </div>
          //           {user && user.isprojectenable &&
          //             <div className="d-flex  align-items-center">
          //               <div
          //                 style={{
          //                   color: "#707C97",
          //                   fontSize: "18px",
          //                   marginRight: "10px",
          //                 }}
          //               >
          //                 {t("project")}:
          //               </div>
          //               <div>
          //                 <select
          //                   className="estimate-select"
          //                   // value={selectedProject}
          //                   value={filters.project}
          //                   // onChange={(e) => setSelectedProject(e.target.value)}
          //                   onChange={(e) => handleFilterChange({ ...filters, project: e.target.value })}
          //                 >
          //                   {projectList.map((item, index) => {
          //                     return (
          //                       item &&
          //                       <option key={index} value={item._id}>
          //                         {item.name}
          //                       </option>
          //                     );
          //                   })}
          //                 </select>
          //               </div>
          //             </div>}
          //         </div>
          //         <div className="d-flex  align-items-center">
          //           <div
          //             style={{
          //               color: "#707C97",
          //               fontSize: "18px",
          //               marginRight: "10px",
          //             }}
          //           >
          //             {t("priority")}:
          //           </div>
          //           <div>
          //             <select
          //               className="estimate-select"
          //               value={filters.priority}
          //               // onChange={(e) => setPriority(e.target.value)}
          //               onChange={(e) => handleFilterChange({ ...filters, priority: e.target.value })}
          //             >
          //               {priorityList.map((item, index) => {
          //                 return (
          //                   <option key={index} value={item}>
          //                     {item}
          //                   </option>
          //                 );
          //               })}
          //             </select>
          //           </div>
          //         </div>
          //       </div>
          //       {role == ROLE.ADMIN && (
          //         <>
          //           <div className="inprogress d-flex  align-items-center mt-3">
          //             <div
          //               style={{
          //                 color: "#707C97",
          //                 fontSize: "24px",
          //                 marginRight: "10px",
          //               }}
          //             >
          //               <i className="bi bi-search"></i>
          //             </div>
          //             <div className="flex-auto">
          //               <input
          //                 className="task-search w-100"
          //                 placeholder="Search taskname"
          //                 value={taskname}
          //                 // onChange={(e) => setSearchTaskname(e.target.value)}
          //                 // onChange={(e) => handleFilterChange({ ...filters, taskname: e.target.value })}
          //                 onChange={(e) => { dispatch(setTaskname(e.target.value)) }}
          //               />
          //             </div>
          //           </div>
          //           <div className="d-flex justify-content-between align-items-center mt-3">
          //             <div className="d-flex align-items-center">
          //               <div
          //                 style={{
          //                   color: "#707C97",
          //                   fontSize: "18px",
          //                   marginRight: "10px",
          //                 }}
          //               >
          //                 {t("client")}:
          //               </div>
          //               <div>
          //                 <Autocomplete
          //                   getItemValue={(item) => item.id}
          //                   items={clientList.map(item => ({ name: item.username, id: item._id }))}
          //                   value={clientInput}
          //                   // value={filters.client}
          //                   shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
          //                   onChange={(e) => setClientInput(e.target.value)}
          //                   // onChange={(e) => handleFilterChange({ ...filters, client: e.target.value })}
          //                   inputProps={{
          //                     className: 'priority-select',
          //                     style: {
          //                       marginLeft: '0px'
          //                     }
          //                   }}
          //                   renderItem={(item, isHighlighted) =>
          //                     <div style={{ background: isHighlighted ? '#CBE7E9' : '#00676c', color: isHighlighted ? 'black' : 'white', paddingLeft: '10px' }}>
          //                       {item.name}
          //                     </div>
          //                   }
          //                   // onSelect={(value, item) => { setSelectedClient(value); setClientInput(item.name); navigate('/chat') }}
          //                   onSelect={(value, item) => { setClientInput(item.name); handleFilterChange({ ...filters, client: item.id }); navigate('/chat') }}
          //                 />
          //               </div>
          //             </div>
          //             {/* Autocomplete for Client Search */}
          //             {/* <div>
          //           <label htmlFor="client-search">Search by Client:</label>
          //           <input
          //             id="client-search"
          //             type="text"
          //             value={clientInput}
          //             onChange={(e) => {
          //               setClientInput(e.target.value);
          //               // fetchClientSuggestions(e.target.value);
          //             }}
          //             placeholder="Type to search clients..."
          //           />
          //           <ul>
          //             {clientList?.map((client) => (
          //               <li key={client._id} onClick={() => handleSelectClient(client)}>
          //                 {client.name}
          //               </li>
          //             ))}
          //           </ul>
          //         </div> */}
          //             <button className="mt-3 clear-filters" onClick={() => { setClientInput(''); dispatch(clearFilters()); }}>Clear Filters</button>
          //           </div>
          //         </>
          //       )}
          //     </>
          //   }

          //   {(status === t('to_do') || status === t('in_progress')) &&
          //     <div className="mt-2 task-summary">{summary.total} {t('task_summary')} {summary.totalCost}{user && user.currency == CURRENCY.USD ? "$" : "€"} </div>
          //   }
          //   <h2 className="mt-4" style={{ marginBottom: '-20px', fontWeight: 500, fontSize: '17px', lineHeight: 1.3, letterSpacing: '.7px' }}>{totalTasks} Task{tasks.length > 1 && 's'} Found</h2>
          //   <div className={(status === t('to_do') || status === t('in_progress')) ? "mt-2" : "mt-5"}>
          //     {showNewTask ? (
          //       <></>
          //     ) : (
          //       tasks &&
          //       tasks
          //         .map((item, index) => (
          //           <div key={index}>
          //             {item._id == task._id ? (
          //               <div ref={currentTaskRef}></div>
          //             ) : (
          //               <></>
          //             )}
          //             <Task key={"task" + index} task={item} index={index} totalUserDue={totalUserDue} />

          //           </div>
          //         ))
          //     )}
          //   </div>
          //   {loading && <Loading />}
          //   {!hasMore && <p className="mt-4">No more tasks to load</p>}

          //   {/* Loader element for Intersection Observer */}
          //   <div ref={loader} style={{ height: '20px', marginBottom: '20px' }}></div>
          // </div>
          <div className="tasks-mobile d-flex flex-column">
            <div className="container">
              <div
                className="d-flex justify-content-between"
                style={{ marginTop: "20px" }}
              >
                <img className="logo" src={Logo} />
                <button className="logout" onClick={logout}>
                  <i class="bi bi-box-arrow-right"></i>
                </button>
              </div>
              <div className="tasks-title-mobile my-3">{t("tasks")}</div>
            </div>
            <div className="mt-3 container d-flex justify-content-between align-items-center">
              <div className="inprogress d-flex  align-items-center">
                <div
                  style={{
                    color: "#707C97",
                    fontSize: "24px",
                    marginRight: "10px",
                  }}
                >
                  <i className="bi bi-funnel-fill"></i>
                </div>
                {/* <div>
                <select
                  className="estimate-select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {statusList.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div> */}
                <div>
                  <select
                    className="estimate-select"
                    value={filters.status}
                    // onChange={(e) => setStatus(e.target.value)}
                    onChange={(e) => handleFilterChange({ ...filters, status: e.target.value })}
                  >
                    {statusList.map((item, index) => {
                      return (
                        (user && ((user.role === ROLE.CLIENT && item.text != t("notLastMessage")) || (user.role === ROLE.ADMIN || user.role === ROLE.DEV)) && item) &&
                        <option key={index} value={item.id}>
                          {item.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* <div className="d-flex  align-items-center">
                <div
                  style={{
                    color: "#707C97",
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                >
                  {t("priority")}:
                </div>
                <div>
                  <select
                    className="estimate-select"
                    value={priority}
                    onChange={(e) => setPriority(e.target.value)}
                  >
                    {priorityList.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div> */}
              <div className="d-flex  align-items-center">
                <div
                  style={{
                    color: "#707C97",
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                >
                  {t("priority")}:
                </div>
                <div>
                  <select
                    className="estimate-select"
                    value={filters.priority}
                    // onChange={(e) => setPriority(e.target.value)}
                    onChange={(e) => handleFilterChange({ ...filters, priority: e.target.value })}
                  >
                    {priorityList.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="" style={{ overflowY: "auto" }}>
              <div className="container">
                {showNewTask ? <NewTask setShowNewTask={setShowNewTask} /> : <></>}
              </div>
              {showNewTask ? <></> : <></>}
              {role === 1 ? (
              <div className="container mt-3">
                <button
                  className="new-task-button-mobile"
                  onClick={() => setShowNewTask(true)}
                >
                  + {t("new_task")}
                </button>
              </div>
            ) : (
              <></>
            )}
              {tasks &&
                tasks
                  .map((item, index) => (
                    <div key={index}>
                      {item._id == task._id ? (
                        <div ref={currentTaskRef}></div>
                      ) : (
                        <></>
                      )}
                      <Task key={"task" + index} task={item} />

                    </div>
                  ))}
            </div>
            {/* {role == 1 ? (
              <div className="container mt-3">
                <button
                  className="new-task-button"
                  onClick={() => setShowNewTask(true)}
                >
                  + {t("new_task")}
                </button>
              </div>
            ) : (
              <></>
            )} */}
            {loading && <Loading />}
            {!hasMore && <p className="mt-4">{t("no_more_tasks_to_load")}</p>}

            {/* Loader element for Intersection Observer */}
            <div ref={loader} style={{ height: '20px', marginBottom: '20px' }}></div>
          </div>
      }
    </>
  );
}
