export const SOCKET_URL =
  process.env.REACT_APP_PRODUCTION_MODE == "true"
  ? "https://api.wp-sofa.chat" 
  : "http://localhost:5000" 

export const API_URL =
  process.env.REACT_APP_PRODUCTION_MODE == "true"
  ? "https://api.wp-sofa.chat" 
  : "http://localhost:5000" 

export const REDIRECT_URL =
  process.env.REACT_APP_PRODUCTION_MODE == "true"
    ? "https://wp-sofa.chat/paymentsuccess"
    : "http://localhost:3000/paymentsuccess";

export const PUBLIC_KEY =
  process.env.REACT_APP_PRODUCTION_MODE == "true"
    ? "pk_live_z3Rq3GfRGp9t4aqKxBBJf75U"
    : "pk_test_51LuUuZJDtbxxZEAWCQbg3llRTobXLrnnsBB8E1zNQguaMsdZi9FalS9z22QRNjvXDmSw0AzzxVNVFsqNNkC4nUCR00RIXuXAdf";

export const STATUS = {
  CREATED: 0,
  ESTIMATED: 1,
  CONFIRMED: 2,
  ASSIGNED: 3,
  COMPLETED: 4,
  PAID: 5,
  DELETED: 6,
  PAID_DELETED: 7,
  GETFEEDBACK: 8,
  ARCHIVED: 9
};

export const ROLE = {
  ADMIN: 0,
  CLIENT: 1,
  DEV: 2,
};

export const OFFER = {
  NORMAL: 0,
  FAST: 1,
};

export const METHOD = {
  PAY: 0,
  DEPOSIT: 1,
};

export const CURRENCY = {
  USD: 0,
  EURO: 1,
};

export const CLOUD_NAME = "dbjfuowqq";

export const UPLOAD_PRESET = {
  AVATAR: "eois27fz",
  IMAGE: "bj7vxjzf",
  FILE: "jxn3llmn",
};

export const LANGUAGE = {
  EN: 0,
  DE: 1,
  ES: 2,
};

export const BASIC_USER = "wp_sofa";
export const BASIC_PASSWORD = "WP@5432";

export const ADMIN_NAME = "Patrick";

export const DEADLINE = {
  WITHIN_HOURS: 0,
  WITHIN_24HOURS: 1,
  WITHIN_48HOURS: 2,
};

export const STANDARD_AVATAR_URL =
  "https://res.cloudinary.com/dbjfuowqq/image/upload/v1667245571/wp-sofa-avatar/avatar_kuuspt.png";

export const MESSAGE_TYPE = {
  NEW: 0,
  REPLY: 1,
  EDIT: 2,
};

export const LIMIT_TRANSLATE = 997

export const FAST_SUPPORT = {
  WITHIN_1HOUR: "fastsupport_within_1h",
  WITHIN_5HOUR: "fastsupport_within_5h",
}

export const BUTTON_TYPE = {
  PROVIDE_LICENSE_KEY_BUTTON: "provide_license_key_button",
  PROVIDE_SITE_BUTTON: "provide_site_button",
  FAST_SUPPORT_OPTION_BUTTON: "fast_support_option_button",
  SUPPORT_BUTTON: "support_button"
}

export const CLIENT_TYPE = {
  WP_GIFTCARD: 0,
  AGENCY: 1
}