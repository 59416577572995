function getLang() {
    if (navigator.languages != undefined){
        if(navigator.languages[0].includes('-')){
            return navigator.languages[0].split('-')[0];
        }
        return navigator.languages[0];
    }
    if(navigator.language.includes('-'))
        return navigator.language.split('-')[0];
    return navigator.language;
}

const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
};

const decodeEntities = (s) => {
    var str, temp= document.createElement('p');
    temp.innerHTML= s;
    str= temp.textContent || temp.innerText;
    temp=null;
    return str;
}

export {
    getLang,
    validURL,
    decodeEntities
}