import React, { useState, useEffect } from "react";

const MY_MESSAGE = 1;
const OTHER_MESSAGE = 2;

export default function MessageTime({ time, type }) {
  const [convertedTime, setConvertedTime] = useState("");
  useEffect(() => {
    if (time) {
      const convertedTime =
        time.split(":")[0] +
        ":" +
        time.split(":")[1] +
        " " +
        (time.split(" ")[1] === undefined ? "" : time.split(" ")[1]);
      setConvertedTime(convertedTime);
    }
  }, [time]);

  return (
    <div className={type == MY_MESSAGE ? "d-flex justify-content-end" : ""}>
      {convertedTime}
    </div>
  );
}
