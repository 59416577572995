import React, { useState } from "react";
import UserAvatars from "../../assets/images/people.png";
import animationData from "../../assets/animation/sammy-line-big-up-arrow.json";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "../../constants/constants";

export default function ChatBoxBackground() {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };



  return (
    <div className="d-flex justify-content-center chat-background align-items-center text-center position-relative">
      <div>
        <div>
          <img src={UserAvatars} />
        </div>
        <div
          style={{
            fontWeight: "500",
            fontSize: "30px",
            lineHeight: "150%",
          }}
        >
          <div className="d-flex justify-content-center">
            <div>{t("to_start_your")}</div>
            <div
              style={{
                color: "#2A8BF2",
                marginRight: ".5rem",
                marginLeft: ".5rem",
              }}
            >
              {t("productive")}
            </div>
            <div>{t("work_and")}</div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              style={{
                color: "#A61893",
                marginRight: ".5rem",
                marginLeft: ".5rem",
              }}
            >
              {t("pleasant")}
            </div>
            {t("communication")} — {t("create_a_task")}
          </div>
        </div>

      </div>
      <div className="new-arrow">
        <Lottie options={defaultOptions} width="80%" />
      </div>
     
    </div>
  );
}
