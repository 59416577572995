import { createSlice } from "@reduxjs/toolkit";
import { ROLE } from "../constants/constants";

const initialState = {
    tasks: [],
    filter: null,
    filteredTasks: [],
}

export const referrerTaskSlice = createSlice({
    name: "referrerTask",
    initialState: [],
    reducers: {
        setReferrerTask: (state, action) => {
            state.splice(0, state.length);
            const tasks = [...action.payload];
            for (let i = 0; i < tasks.length; i++) state.push(tasks[i]);
        },
        updateTask: (state, action) => {
            const taskId = action.payload;
            for (let i = 0; i < state.length; i++) {
                if (state._id == taskId) {
                    state[i].isPaidToReferrer = true;
                    // state[i].updatedTime = new Date();
                    // state[i].updatedAt = new Date();
                }
            }
        },
        // filterTask: (state, action) => {
        //     state.filter = action.payload;
        //     if (action.payload === 0) state.filteredTasks = state.tasks;
        //     if (action.payload === 1) state.filteredTasks = state.tasks.filter((task) => 
        //         task.isPaidToReferrer === false
        //     )
        //     if (action.payload === 2) state.filteredTasks = state.tasks.filter((task) => 
        //         task.isPaidToReferrer === true
        //     )
        // }
    }
})

export const {
    setReferrerTask,
    updateTask,
    // filterTask
} = referrerTaskSlice.actions;

export default referrerTaskSlice.reducer;