import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: [],
    reducers: {
        getUser: (state, action) => {
            state.splice(0, state.length);
            const users = [...action.payload];
            for (let i = 0; i < users.length; i++) state.push(users[i]);
        },
        changeClientType: (state, action) => {
            const user = state.find((user) => user._id === action.payload);
            user.isWpGiftcardClient = !user.isWpGiftcardClient;
        }
    }
})

export const { getUser, changeClientType } = userSlice.actions;

export default userSlice.reducer;