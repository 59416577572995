import { createSlice } from "@reduxjs/toolkit";
import { getFirstTimeOfMonth, getLastTimeOfMonth } from "../utils/dateTime"

export const translateSlice = createSlice({
    name: 'translate',
    initialState: {
        count: 0,
        startTime: getFirstTimeOfMonth(),
        endTime: getLastTimeOfMonth() 
    },
    reducers: {
        increment: (state) => {
            state.count += 1;
        },
        resetCount: (state) => {
            state.count = 0;
        },
        updateDateTime: (state, action) => {
            state.startTime = action.payload.startTime;
            state.endTime = action.payload.endTime;
        },
        updateCount: (state, action) => {
            state.count = action.payload;
        }
    }
})

export const { increment, resetCount, updateDateTime, updateCount } = translateSlice.actions;

export default translateSlice.reducer;