import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Login.css";
import { API_URL } from "../../constants/constants";
import api from "../../utils/axios";
import { toast } from "react-toastify";
import Logo from "../../assets/images/horizontal-logo-wp-sofa.svg";
import {
    isMobile,
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import LoginIntro from "../LoginIntro";

export default function ResetPassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [isReseting, setIsReseting] = useState(false);

    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const cPasswordRef = useRef(null);

    const loginbuttonRef = useRef(null);

    const onReset = async () => {
        if (password !== cPassword) {
            toast.error("Passwords do not match");
            return;
        }
        setIsReseting(true);

        await api.post(`${API_URL}/api/v1/auth/reset`, {
            email,
            password
        })
            .then((res) => {
                setIsReseting(false);
                toast.success("Password reset successful");
                // setPassword("");
                // setCPassword("");
            })
            .catch((err) => {
                setIsReseting(false);
                const error = err.response.data.errors[0];
                toast.error(error.msg);
            })
            .finally(() => setIsReseting(false))
    };

    const goToSignin = () => {
        navigate("/login");
    };

    return (
        <div className="login">
            {isMobile ? (
                <div
                    style={{
                        height: "100vh",
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="w-100" style={{ fontSize: "20px" }}>
                        Redirect to https://app.wp-sofa.chat
                    </div>
                </div>
            ) : (
                <>
                    <div className="main-content">
                        <div className="row">
                            <div className="col-6 d-flex align-items-center" style={{ paddingLeft: '10%' }}>
                                <div className="w-85">
                                    <img className="logo" src={Logo} alt="" />
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <div className="title1" style={{ marginRight: '50px' }}>{t("reset_password")}</div>
                                        <div className="d-flex">
                                            <button className="gotosignup" onClick={goToSignin}>
                                                {t("sign_in!")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <div>{t("your_e_mail")}</div>
                                        <input
                                            ref={emailRef}
                                            className="name w-100"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onKeyDown={(e) => {
                                                const { key } = e;
                                                if (key === "Enter") passwordRef.current.focus();
                                            }}
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <div>{t("your_new_password")}</div>
                                        <input
                                            ref={passwordRef}
                                            type="password"
                                            className="name w-100"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onKeyDown={(e) => {
                                                const { key } = e;
                                                if (key === "Enter") loginbuttonRef.current.focus();
                                            }}
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <div>{t("re_enter_your_password")}</div>
                                        <input
                                            ref={cPasswordRef}
                                            type="password"
                                            className="name w-100"
                                            value={cPassword}
                                            onChange={(e) => setCPassword(e.target.value)}
                                            onKeyDown={(e) => {
                                                const { key } = e;
                                                if (key === "Enter") loginbuttonRef.current.focus();
                                            }}
                                        />
                                    </div>

                                    <div className="text-center">
                                        {isReseting ? (
                                            <button className="join" style={{ width: '100%' }}>{t("reseting_password")}</button>
                                        ) : (
                                            <button
                                                ref={loginbuttonRef}
                                                className="join"
                                                onClick={onReset}
                                                style={{ width: '100%' }}
                                            >
                                                {t("reset_password")}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <LoginIntro />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
