import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import "../../assets/css/ProvideAccessDetail.css"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { SocketContext } from "../../context/socket";
import { getUserFromLocalStorage } from '../../utils/localStorage';
import { toast } from "react-toastify";
import api from '../../utils/axios';
import { API_URL, DEADLINE } from '../../constants/constants';
import Lock from "../../assets/images/lock.png"
import { addTask } from '../../redux/taskSlice';

const ProvideURLForm = ({ show, setShow }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socket = React.useContext(SocketContext);
    const ref = useRef(null);
    const currenTask = useSelector((state) => state.current);
    const userData = getUserFromLocalStorage();

    const [url, setUrl] = useState("");
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShow(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!url) {
            toast.warning("Empty data");
            return;
        }

        try {
            await api.post(`${API_URL}/api/v1/chatbot/provide-url`, { taskId: currenTask._id, url })
                .then(async (res) => {
                    toast.success("Saved url");
                    socket.emit("handle-provide-url", { task: currenTask, userId: userData._id, type: "provide_url-yes" })

                    let taskNameDefault = userData.language === 0 ? `Speed up of website ${url}` : userData.language === 1 ? `Beschleunigung für die website ${url}` : "Acelera tu sitio web"
                    // if (isnewuser) {
                    await api
                        .post(`${API_URL}/api/v1/task/create-auto`, {
                            taskname: `Speed Optimization of ${url}`,
                            priority: "Normal",
                            deadline: DEADLINE.WITHIN_HOURS,
                            projectId: "",
                        })
                        .then((res) => {
                            dispatch(addTask(res.data));
                            socket.emit("new-task-auto", { task: res.data, userId: userData._id, url });
                            // setIsCreating(false);
                        })
                        .catch((err) => {
                            if (err.response.status == 401) {
                                navigate("/");
                            }
                            const errors = err.response.data.errors;
                            errors.forEach((error) => toast.error(error.msg));
                            // setIsCreating(false);
                        });
                    // }
                })
                .catch(err => {
                    toast.error("Error saving url");
                })
        } catch (err) {
            const errors = err.response.data.errors;
            errors.forEach((error) => toast.error(error.msg));
        }

        setShow(false)
    }

    return (
        <>
            {
                show && (
                    <div className="provide-site-form d-flex justify-content-center align-items-center">
                        <div ref={ref} style={{ width: '30%', padding: '30px 20px' }} className="contact-manager-model access-detail-form">
                            <div className="d-flex justify-content-between mb-4">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: "10px" }}>
                                    <h2 style={{ fontWeight: '500', fontSize: '22px' }}>{t("your_data_will_be_encrypted")}</h2>
                                    {/* <img src={Lock} /> */}
                                </div>
                                <button className="cancel" onClick={() => setShow(false)}>
                                    <i className="bi bi-x-lg"></i>
                                </button>
                            </div>

                            {/* <div className="tab">
                                <ul>
                                    <li
                                        className={selectedTab == TAB.WORDPRESS ? "active" : ""}
                                        onClick={() => setSelectedTab(TAB.WORDPRESS)}
                                    >
                                        Wordpress
                                    </li>
                                </ul>
                            </div> */}

                            <div>
                                <Form noValidate validated={validated} >
                                    <Form.Group hasValidation className="mb-3" >
                                        {/* <Form.Label htmlFor='licensekey'>URL</Form.Label> */}
                                        <InputGroup className="mb-3">
                                            <Form.Control required id="licensekey" value={url} onChange={(e) => { setUrl(e.target.value) }} placeholder="Please provide url your website" />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter url your website
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Button style={{ width: '100%' }} onClick={handleSubmit} variant="primary" type='submit'>
                                        {t("save")}
                                    </Button>
                                </Form>

                            </div>

                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ProvideURLForm