import React, {useCallback, useContext} from "react";
import fileDownload from "js-file-download";
import axios from "axios";
import {API_URL, ROLE} from "../../constants/constants";
import "../../assets/css/File.css";
import {useTranslation} from "react-i18next";
import Popup from "reactjs-popup";
import {Flip, toast} from "react-toastify";
import api from "../../utils/axios";
import {deleteMessage} from "../../redux/messageSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {SocketContext} from "../../context/socket";

const MY_MESSAGE = 1;
const OTHER_MESSAGE = 2;

export default function Files({type, message}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socket = useContext(SocketContext);

    const download = useCallback((filename, url) => {
            axios
                .get(url, {
                    responseType: "blob",
                })
                .then((res) => {
                    fileDownload(res.data, filename);
                });
        },
        []);

    const removeFile = useCallback(async (fileObject) => {
        const {filename} = fileObject;
        await api
            .post(`${API_URL}/api/v1/message/deleteFile`, {
                messageId: message._id,
                fileName: filename
            })
            .then((res) => {
                dispatch(deleteMessage(res.data._id));
                socket.emit("delete-message", res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) navigate("/");
                const errors = err.response.data.errors;
                errors.forEach((error) => toast.error(error.msg));
            });
    }, [message]);

    return (
        <>
            <div className={type === MY_MESSAGE ? "d-flex justify-content-end" : ""}>
                <div className="message-files d-flex flex-auto"
                     style={type === OTHER_MESSAGE ? {justifyContent: "start"} : {justifyContent: "flex-end"}}>
                    {message.message_deleted ?
                        <div
                            style={{margin: "0.25rem"}}
                            dangerouslySetInnerHTML={{__html: message.message_deleted && "<div style='color: #bbbbbb; font-style: italic;'>Ø message is deleted</div>"}}
                            className={type === MY_MESSAGE ? "message-text-me" : type === OTHER_MESSAGE ? "message-text-user" : "message-text-admin"}
                        />
                        :
                        <ul className="files flex-auto">
                            {(message.files || []).map((file, index) => (
                                <li key={index}>
                                    <div className="file-pane d-flex flex-column justify-content-between">
                                        <div className="flex-auto d-flex flex-column justify-content-between p-1">
                                            <div className="filename">{file.filename}</div>
                                            <div className="fileicon">
                                                <i className="bi bi-file-earmark"></i> {t("file")}
                                            </div>
                                        </div>
                                        <div
                                            className="download-button"
                                            style={{background: "transparent"}}
                                            onClick={() => download(file.filename, file.url)}
                                        >
                                            {t("download")}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>}
                    {!message.message_deleted && <div className="popup-area">
                        <Popup
                            trigger={
                                <button className="popup-button">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </button>
                            }
                            position="bottom right"
                            arrow={false}
                        >
                            <div className="menu">
                                {type === MY_MESSAGE && (
                                    <div className="menu-item" onClick={() => removeFile(message.files[0])}>
                                        <i className="bi bi-x-square"></i> &nbsp; {t("remove")}
                                    </div>
                                )}
                            </div>
                        </Popup>
                    </div>}
                </div>
            </div>
        </>
    );
}
