import React, { useEffect, useRef } from "react";
import "../../assets/css/FullScreenImage.css";
import { API_URL } from "../../constants/constants";

export default function FullScreenImage({
  image,
  fullScreenShow,
  setFullScreenShow,
}) {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setFullScreenShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      {fullScreenShow ? (
        <div className="full-screen-image d-flex align-items-center">
          <img ref={ref} src={image} />
          <button
            className="close-button"
            onClick={() => setFullScreenShow(false)}
          >
            <i className="bi bi-x-lg"></i>
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
