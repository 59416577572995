import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player'
import Popup from 'reactjs-popup'
import { deleteMessage } from '../../redux/messageSlice';
import api from '../../utils/axios';
import { SocketContext } from '../../context/socket';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_URL } from '../../constants/constants';

const MY_MESSAGE = 1;
const OTHER_MESSAGE = 2;

const VideoPlayer = ({type, message}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socket = useContext(SocketContext);

    const removeFile = useCallback(async (fileObject) => {
        const { filename } = fileObject;
        await api
            .post(`${API_URL}/api/v1/message/deleteFile`, {
                messageId: message._id,
                fileName: filename
            })
            .then((res) => {
                dispatch(deleteMessage(res.data._id));
                socket.emit("delete-message", res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) navigate("/");
                const errors = err.response.data.errors;
                errors.forEach((error) => toast.error(error.msg));
            });
    }, [message]);

    return (
        <>
            <div className={type === MY_MESSAGE ? "d-flex justify-content-end" : ""}>
                <div className="message-files d-flex flex-auto"
                style={type === OTHER_MESSAGE ? { justifyContent: "start" } : { justifyContent: "flex-end" }}
                >
                    {message.message_deleted ?
                        <div
                            style={{margin: "0.25rem"}}
                            dangerouslySetInnerHTML={{__html: message.message_deleted && "<div style='color: #bbbbbb; font-style: italic;'>Ø message is deleted</div>"}}
                            className={type === MY_MESSAGE ? "message-text-me" : type === OTHER_MESSAGE ? "message-text-user" : "message-text-admin"}
                        /> :
                    <ReactPlayer height={"100%"} width={"100%"} url={message.files[0]?.url} />
                         }
                    {!message.message_deleted && <div className="popup-area">
                        <Popup
                            trigger={
                                <button className="popup-button">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </button>
                            }
                            position="bottom right"
                            arrow={false}
                        >
                            <div className="menu">
                                {type === MY_MESSAGE && (
                                    <div className="menu-item" onClick={() => removeFile(message.files[0])}>
                                        <i className="bi bi-x-square"></i> &nbsp; {t("remove")}
                                    </div>
                                )}
                            </div>
                        </Popup>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default VideoPlayer





