import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TypingStatus from "./TypingStatus";
import { SocketContext } from "../../context/socket";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import api from "../../utils/axios";
import { MdOutlineDriveFolderUpload } from "react-icons/md";

import {
  API_URL,
  CLOUD_NAME,
  UPLOAD_PRESET,
  ROLE,
  LANGUAGE,
  MESSAGE_TYPE,
  DEADLINE,
} from "../../constants/constants";
import {
  addMessage,
  updateMessage,
  updateEditMessage,
} from "../../redux/messageSlice";
import { addOthersUnread, newMessage } from "../../redux/taskSlice";
import { addTask } from "../../redux/taskSlice";
import { setCurrentTask } from "../../redux/currentSlice";
import Upload from "rc-upload";
import Popup from "reactjs-popup";
import FullScreenImage from "./FullScreenImage";
import "../../assets/css/MessageInput.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import S3 from "react-aws-s3";
import FullScreenImageEditor from "./FullScreenImageEditor.js";
import { setNew } from "../../redux/messageInputSlice";
import { addSocketEventListener } from "../../utils/socketCommon";
import { decodeEntities, validURL } from "../../utils/common";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { fetchPageSpeedData } from "../../utils/speedCheck";
import {
  isMobile,
} from "react-device-detect";

window.Buffer = window.Buffer || require("buffer").Buffer;

let timeout;

export default function MessageInputBox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [user, setUser] = useState({});
  // const room = useSelector((state) => state.chat.currentTask);
  const task = useSelector((state) => state.current);
  const tasks = useSelector((state) => state.task);
  const messageInput = useSelector((state) => state.messageInput);
  const textField = useRef(null);
  const ref = useRef();

  const socket = useContext(SocketContext);

  const [lineCount, setLineCount] = useState(1);
  const [message, setMessage] = useState("");
  const [filesList, setFilesList] = useState([]);
  const [previewFilesList, setPreviewFilesList] = useState([]);
  const [typing, setTyping] = useState(false);
  const [progress, setProgress] = useState(100);
  const [latestMessage, setLatestMessage] = useState();
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const [fullScreenShow, setFullScreenShow] = useState(false);
  const [fullScreenEditShow, setFullScreenEditShow] = useState(false);
  const maxRow = 9;
  const [uploadProgress, setUploadProgress] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [isAdminOnline, setIsAdminOnline] = useState();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const config_image = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: "image",
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS,
    secretAccessKey: process.env.REACT_APP_SECRET,
  };

  const config_file = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: "file",
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS,
    secretAccessKey: process.env.REACT_APP_SECRET,
  };

  const languageList = [
    {
      language: t("english"),
      slanguage: "EN",
      value: LANGUAGE.EN,
      code: "en",
    },
    {
      language: t("german"),
      slanguage: "DE",
      value: LANGUAGE.DE,
      code: "de",
    },
    {
      language: "Spanish",
      slanguage: "ES",
      value: LANGUAGE.ES,
      code: "es",
    },
  ];

  useEffect(() => {
    setFlag(!flag);
  }, [filesList]);

  useEffect(() => {
    textField?.current?.focus();
    // setMessage("");
    // setImageList([]);
    // setPreviewURL([]);
  }, [task]);

  // useEffect(() => {
  //   addSocketEventListener(socket, "bot-message", (newTaskReceived) => {
  //     const user = getUserFromLocalStorage();
  //
  //     // IF User is not client, then send bot message
  //     if (newTaskReceived.client != user._id){
  //         sendBotMessage(newTaskReceived);
  //     }
  //   });
  //
  //   return () => socket.removeAllListeners("bot-message");
  // }, []);

  // Set Name
  useEffect(() => {
    const user = getUserFromLocalStorage();
    setUser(user);
  }, []);

  useEffect(() => {
    setTyping(false);
    // tasks.forEach((task) => {
    //   socket.emit("stop-typing", {
    //     room: task._id,
    //     user: { _id: user._id, username: user.username },
    //   });
    // });
  }, [task]);

  useEffect(() => {
    if (messageInput.type == MESSAGE_TYPE.EDIT) {
      setMessage(messageInput.message);
      setFilesList([]);
      setPreviewFilesList([]);
    } else {
      initializeMessageInput();
    }
  }, [messageInput]);

  // useEffect(() => {
  //   const tempTask = tasks.filter((task) => task.taskname == room);
  //   const task = { ...tempTask[0] };

  //   // socket.emit("join", { name, room, task }, (error) => {
  //   //   if (error) {
  //   //     toast.error(error);
  //   //     // navigate("/");
  //   //   }
  //   // });
  // }, [room]);

  const initializeMessageInput = () => {
    // Clean messageinput
    setMessage("");
    setFilesList([]);
    setPreviewFilesList([]);
    setLineCount(1);
  };

  // const sendBotMessage = async (newTaskReceived) => {
  //   const user = getUserFromLocalStorage();
  //   let username;
  //   const users = newTaskReceived.users;
  //   const clientLanguage = newTaskReceived.client.language;
  //   const translateLanguage = newTaskReceived.client.translatelanguage;
  //   users.forEach((item) => {
  //     if (item._id != user._id) username = item.username;
  //   });
  //
  //   let messages = [];
  //   let botMessage = "Hello | I am Patrick from Codemenschen | Please add everything to the task in this chat, my team and I will be happy to estimate the tasks for you right away. | Please share your domain & wp-admin login to shorten the solving time and describe the problem.";
  //   if(translateLanguage){
  //     await api
  //         .post(`${API_URL}/api/v1/message/translate/`, {
  //           message: botMessage,
  //           language: translateLanguage,
  //         })
  //         .then(async (res) => {
  //           let translatedMessage = decodeEntities(res.data.data.translations[0].translatedText).split('|');
  //           messages = [
  //             { message: translatedMessage[0] + `${username},`, time: 5000 },
  //             { message: translatedMessage[1], time: 3000 },
  //             {
  //               message:
  //                   translatedMessage[2],
  //               time: 4000,
  //             },
  //             {
  //               message:
  //                   translatedMessage[3],
  //               time: 4000,
  //             },
  //           ];
  //           socket.emit("typing", {
  //             room: newTaskReceived._id,
  //             user: { _id: user._id, username: user.username },
  //           });
  //
  //           for (let i = 0; i < messages.length; i++) {
  //             await delaytimeout(messages[i].time);
  //             sendDelayedBotMessage(messages[i].message, newTaskReceived._id);
  //           }
  //
  //           socket.emit("stop-typing", {
  //             room: newTaskReceived._id,
  //             user: { _id: user._id, username: user.username },
  //           });
  //         })
  //         .catch((err) => console.log(err));
  //   } else {
  //     if (clientLanguage == LANGUAGE.EN) {
  //       messages = [
  //         { message: `Hello ${username},`, time: 5000 },
  //         { message: "I am Patrick from Codemenschen", time: 3000 },
  //         {
  //           message:
  //             "Please add everything to the task in this chat, my team and I will be happy to estimate the tasks for you right away.",
  //           time: 4000,
  //         },
  //         {
  //           message:
  //             "Please share your domain & wp-admin login to shorten the solving time and describe the problem.",
  //           time: 4000,
  //         },
  //       ];
  //     } else if (clientLanguage == LANGUAGE.DE) {
  //       messages = [
  //         { message: `Hallo ${username},`, time: 5000 },
  //         { message: "ich bin Patrick von Codemenschen.", time: 3000 },
  //         {
  //           message:
  //             "Bitte füge alles zur Aufgabe in diesen Chat ein, mein Team und ich schätzen die Aufgaben gerne gleich für dich.",
  //           time: 4000,
  //         },
  //         {
  //           message:
  //             "Bitte teilen Sie Ihre Domain & wp-admin-Login mit, um die Lösungszeit zu verkürzen und das Problem zu beschreiben.",
  //           time: 4000,
  //         },
  //       ];
  //     } else if (clientLanguage == LANGUAGE.ES) {
  //       messages = [
  //         { message: `Hello ${username},`, time: 5000 },
  //         { message: "I am Patrick from Codemenschen", time: 3000 },
  //         {
  //           message:
  //             "Please add everything to the task in this chat, my team and I will be happy to estimate the tasks for you right away.",
  //           time: 4000,
  //         },
  //         {
  //           message:
  //             "Please share your domain & wp-admin login to shorten the solving time and describe the problem.",
  //           time: 4000,
  //         },
  //       ];
  //     }
  //     socket.emit("typing", {
  //       room: newTaskReceived._id,
  //       user: { _id: user._id, username: user.username },
  //     });
  //
  //     for (let i = 0; i < messages.length; i++) {
  //       await delaytimeout(messages[i].time);
  //       sendDelayedBotMessage(messages[i].message, newTaskReceived._id);
  //     }
  //
  //     socket.emit("stop-typing", {
  //       room: newTaskReceived._id,
  //       user: { _id: user._id, username: user.username },
  //     });
  //   }
  // };

  const delaytimeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const onChange = useCallback((e) => {
    const text = e.target.value;

    textField.current.style.height = "auto";
    textField.current.style.height = e.target.scrollHeight + "px";

    // if (!text) textField.current.style.height = "27px";

    // const lineNumber = text.split(/\r*\n/).length;

    // // if (lineNumber == 1) textField.current.style.height = "25px";
    // if (lineNumber <= maxRow) setLineCount(lineNumber);
    setMessage(e.target.value);

    if (!typing) {
      setTyping(true);
      socket.emit("typing", {
        room: task._id,
        user: { _id: user._id, username: user.username },
      });
      timeout = setTimeout(timeoutFunction, 5000);
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(timeoutFunction, 5000);
    }
  }, [task]);

  const timeoutFunction = () => {
    setTyping(false);
    socket.emit("stop-typing", {
      room: task._id,
      user: { _id: user._id, username: user.username },
    });
  };

  const onKeyDown = (e) => {
    if (e.code == "Enter" && e.shiftKey != true) {
      e.preventDefault();
      sendMessage();
    }
    if (e.code == "Escape") {
      e.preventDefault();
      cancelMessage();
    }
  };

  const sendDelayedBotMessage = async (message, taskId) => {
    const tempMessage = message;
    const userInfo = getUserFromLocalStorage();
    const data = {
      sender: userInfo,
      message: tempMessage,
      images: [],
      files: [],
      task: taskId,
      createdAt: new Date(),
    };

    setMessage("");
    // if (task._id == taskId) dispatch(addMessage(data));
    // dispatch(newMessage({ taskId: taskId, message: tempMessage }));

    // // Add others(except me and sender) message unread count
    // dispatch(
    //   addOthersUnread({
    //     taskId: taskId,
    //     userId: userInfo._id,
    //     senderId: userInfo._id,
    //   })
    // );

    await api
      .post(`${API_URL}/api/v1/message/send/`, {
        message: tempMessage,
        taskId: taskId,
      })
      .then((res) => {
        if (task._id == taskId) dispatch(addMessage(res.data));
        dispatch(newMessage({ taskId: taskId, message: res.data.message }));

        // Add others(except me and sender) message unread count
        dispatch(
          addOthersUnread({
            taskId: taskId,
            userId: userInfo._id,
            senderId: userInfo._id,
          })
        );

        socket.emit("new-message", res.data);
        setLineCount(1);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }

        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  useEffect(() => {
    const getAdminData = async () => {
      api.get(`${API_URL}/api/v1/user/isAdminOnline`)
        .then((res) => {
          setIsAdminOnline(res.data.isAdminOnline);
        })
    }
    getAdminData();
  }, [])

  const sendMessage = () => {
    socket.emit("is-online", `${user._id}>send`);
    if (messageInput.type == MESSAGE_TYPE.NEW) sendNewMessage();
    else if (messageInput.type == MESSAGE_TYPE.EDIT) sendEditMessage();
    else if (messageInput.type == MESSAGE_TYPE.REPLY) sendReplyMessage();
  };

  const sendNewMessage = async () => {
    if (message || filesList.length > 0) {
      setTyping(false);
      socket.emit("stop-typing", {
        room: task._id,
        user: { _id: user._id, username: user.username },
      });
      const tempMessage = message;
      const tempFilesList = [...filesList];
      let imageList = [];
      let fileList = [];
      for (let i = 0; i < tempFilesList.length; i++) {
        if (tempFilesList[i].type === "image") {
          imageList.push(tempFilesList[i].url);
        } else
          fileList.push({
            filename: tempFilesList[i].filename,
            url: tempFilesList[i].url,
          });
      }

      const userInfo = getUserFromLocalStorage();

      // Message data
      const data = {
        sender: userInfo,
        message: tempMessage,
        images: imageList,
        files: fileList,
        task: task._id,
        createdAt: new Date(),
      };

      // After sent message, make input field clear
      setMessage("");
      setFilesList([]);
      setPreviewFilesList([]);
      setLineCount(1);

      // Add new message to message redux
      // dispatch(addMessage(data));

      // Update task update time
      dispatch(newMessage({ taskId: task._id, message: tempMessage }));

      // Add others(except me and sender) message unread count
      dispatch(
        addOthersUnread({
          taskId: task._id,
          userId: userInfo._id,
          senderId: userInfo._id,
        })
      );

      await api
        .post(`${API_URL}/api/v1/message/send/`, {
          message: tempMessage,
          images: imageList,
          files: fileList,
          taskId: task._id,
        })
        .then(async (res) => {
          dispatch(updateMessage(res.data));
          // After store message, emit message sent event
          socket.emit("new-message", res.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }

          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });

      textField.current.style.height = "28px";
      // await api.post(`${API_URL}/api/v1/task/latestMessage/`, ${})

      // await api.post(`${API_URL}/api/v1/task/read/`,{taskId : task._id}).then(res => {

      // })

      if (user.role === 0) return;

      if (user.role === ROLE.CLIENT && validURL(message)) {
        const data = await fetchPageSpeedData(message);

        await api
          .post(`${API_URL}/api/v1/task/create-auto`, {
            taskname: `Speed Optimization of ${message}`,
            priority: "Normal",
            deadline: DEADLINE.WITHIN_HOURS,
            projectId: "",
          })
          .then((res) => {
            dispatch(addTask(res.data));
            socket.emit("new-task-auto", { task: res.data, userId: user._id, url: message, pageSpeedTest: data?.lighthouseResult });
          })
          .catch((err) => {
            if (err.response.status == 401) {
              navigate("/");
            }
            const errors = err.response.data.errors;
            errors.forEach((error) => toast.error(error.msg));
          });
        // }
      }

      if (user.role === ROLE.CLIENT && !isAdminOnline && !task.isPaidFastSupport) {
        socket.emit("handle-message-when-admin-offline", { task: task, type: 'admin_offline' })
      }
    }
  };

  const sendEditMessage = async () => {
    if (message) {
      setTyping(false);
      socket.emit("stop-typing", {
        room: task._id,
        user: { _id: user._id, username: user.username },
      });

      dispatch(
        updateEditMessage({
          id: messageInput.id,
          message: message,
        })
      );

      dispatch(setNew());

      await api
        .post(`${API_URL}/api/v1/message/edit/`, {
          messageId: messageInput.id,
          message: message,
        })
        .then((res) => {
          // After store message, emit message sent event
          socket.emit("edit-message", res.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }

          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    }
  };

  const sendReplyMessage = async () => {
    if (message || filesList.length > 0) {
      setTyping(false);
      socket.emit("stop-typing", {
        room: task._id,
        user: { _id: user._id, username: user.username },
      });
      const tempMessage = message;
      const tempFilesList = [...filesList];
      let imageList = [];
      let fileList = [];
      for (let i = 0; i < tempFilesList.length; i++) {
        if (tempFilesList[i].type === "image") {
          imageList.push(tempFilesList[i].url);
        } else
          fileList.push({
            filename: tempFilesList[i].filename,
            url: tempFilesList[i].url,
          });
      }

      const userInfo = getUserFromLocalStorage();

      const messageId = messageInput.id;
      const replymessage = messageInput.message;

      // Message data
      const data = {
        sender: userInfo,
        message: tempMessage,
        images: imageList,
        files: fileList,
        task: task._id,
        replymessage: {
          id: messageId,
          message: replymessage,
        },
        replied: true,
        createdAt: new Date(),
      };

      dispatch(setNew());

      // Add new message to message redux
      // dispatch(addMessage(data));

      // Update task update time
      dispatch(newMessage({ taskId: task._id, message: tempMessage }));

      // Add others(except me and sender) message unread count
      dispatch(
        addOthersUnread({
          taskId: task._id,
          userId: userInfo._id,
          senderId: userInfo._id,
        })
      );

      await api
        .post(`${API_URL}/api/v1/message/reply/`, {
          message: tempMessage,
          images: imageList,
          files: fileList,
          taskId: task._id,
          messageId: messageId,
          replymessage: replymessage,
        })
        .then((res) => {
          dispatch(updateMessage(res.data));
          // After store message, emit message sent event
          socket.emit("new-message", res.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }

          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });

      textField.current.style.height = "28px";
      // await api.post(`${API_URL}/api/v1/task/latestMessage/`, ${})

      // await api.post(`${API_URL}/api/v1/task/read/`,{taskId : task._id}).then(res => {

      // })
    }
  };

  const cancelMessage = () => {
    if (messageInput.type == MESSAGE_TYPE.NEW) {
      initializeMessageInput();
    } else if (messageInput.type == MESSAGE_TYPE.EDIT) dispatch(setNew());
    else if (messageInput.type == MESSAGE_TYPE.REPLY) dispatch(setNew());
  };

  const handleUploadClick = (e) => {
    e.preventDefault();
  };

  const handleUploadProgress = (e) => {
    setProgress(e.percent);
  };

  // const handleUploadSuccess = (body, file) => {
  //   // console.log(body, file);
  //   const filename = body;
  //   const tempImageList = [...imageList];
  //   tempImageList.push(filename);
  //   setImageList(tempImageList);
  //   // console.log(imageList);
  //   toast.success("Upload Success");
  //   setProgress(100);
  // };

  // const handleUploadError = (e, body) => {
  //   toast.error(body.error);
  //   const tempPreview = [...previewURL];
  //   tempPreview.splice(tempPreview.length - 1, 1);
  //   setPreviewURL(tempPreview);
  // };

  // const handleUploadStart = (file) => {
  //   const objectUrl = URL.createObjectURL(file);
  //   const tempPreviewUrl = [...previewURL];
  //   tempPreviewUrl.push(objectUrl);
  //   setPreviewURL(tempPreviewUrl);
  //   setProgress(0);
  // };

  const onDeleteImagesAndFiles = (index) => {
    // const tempPreviewUrl = [...previewURL];
    // tempPreviewUrl.splice(index, 1);
    // setPreviewURL(tempPreviewUrl);

    // const tempImageList = [...imageList];
    // tempImageList.splice(index, 1);
    // setImageList(tempImageList);
    const tempFilesList = [...filesList];
    tempFilesList.splice(index, 1);
    setFilesList(tempFilesList);

    const tempPreviewFilesList = [...previewFilesList];
    tempPreviewFilesList.splice(index, 1);
    setPreviewFilesList(tempPreviewFilesList);
  };

  const handleChange = async (e) => {
    const fileObject = e.target.files[0];

    if (fileObject.type.includes("image")) {
      const objectUrl = URL.createObjectURL(fileObject);
      const fileName = fileObject.name;
      // const tempPreviewUrl = [...previewURL];
      // tempPreviewUrl.push(objectUrl);
      // setPreviewURL(tempPreviewUrl);
      const tempPreviewFilesList = [...previewFilesList];
      const tempFile = {
        type: "image",
        previewurl: objectUrl,
        filename: fileName,
        url: "",
      };
      tempPreviewFilesList.push(tempFile);
      setPreviewFilesList(tempPreviewFilesList);
      setProgress(0);
      // setTimeout(() => {
      //   uploadImageToCloudinary(fileObject, index);
      // }, 5000);
      const edited = false;
      await uploadImageToS3(fileObject, tempFile, edited);
    } else {
      const fileName = fileObject.name;
      const tempPreviewFilesList = [...previewFilesList];
      const tempFile = {
        type: "file",
        filename: fileName,
        url: "",
      };
      tempPreviewFilesList.push(tempFile);
      setPreviewFilesList(tempPreviewFilesList);
      // const tempPreFileURL = [...preFileURL];
      // tempPreFileURL.push(fileName);
      // setPreFileURL(tempPreFileURL);
      setProgress(0);
      await uploadFileToS3(fileObject, tempFile);
    }
  };

  const handlePaste = async (e) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];

      if (fileObject.type.includes("image")) {
        const objectUrl = URL.createObjectURL(fileObject);
        const fileName = fileObject.name;
        // const tempPreviewUrl = [...previewURL];
        // tempPreviewUrl.push(objectUrl);
        // setPreviewURL(tempPreviewUrl);
        const tempPreviewFilesList = [...previewFilesList];
        const tempFile = {
          type: "image",
          previewurl: objectUrl,
          filename: fileName,
          url: "",
        };
        tempPreviewFilesList.push(tempFile);
        setPreviewFilesList(tempPreviewFilesList);
        setProgress(0);
        // setTimeout(() => {
        //   uploadImageToCloudinary(fileObject, index);
        // }, 5000);
        const edited = false;
        await uploadImageToS3(fileObject, tempFile, edited);
      } else {
        const fileName = fileObject.name;
        const tempPreviewFilesList = [...previewFilesList];
        const tempFile = {
          type: "file",
          filename: fileName,
          url: "",
        };
        tempPreviewFilesList.push(tempFile);
        setPreviewFilesList(tempPreviewFilesList);
        // const tempPreFileURL = [...preFileURL];
        // tempPreFileURL.push(fileName);
        // setPreFileURL(tempPreFileURL);
        setProgress(0);
        await uploadFileToS3(fileObject, tempFile);
      }
    }
  };

  const uploadImageToCloudinary = async (fileObject, tempFile) => {
    const cloudName = CLOUD_NAME;
    const unsignedUploadPreset = UPLOAD_PRESET.IMAGE;
    let formData = new FormData();
    formData.append("upload_preset", unsignedUploadPreset);
    formData.append("file", fileObject);
    setProgress(0);
    await axios
      .post(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((res) => {
        setProgress(100);
        const fileURL = res.data.secure_url;
        let tempFilesList = [...filesList];
        tempFile.url = fileURL;
        tempFilesList.push(tempFile);
        setFilesList(tempFilesList);
        toast.success("Upload Success");
        setProgress(100);
      })
      .catch((e) => {
        toast.error("Uploading Failed!");
        const tempPreviewFilesList = [...previewFilesList];
        tempPreviewFilesList.splice(tempPreviewFilesList.length, 1);
        setPreviewFilesList(tempPreviewFilesList);
        setProgress(0);
      });
  };

  const uploadFileToCloudinary = async (fileObject, tempFile) => {
    const cloudName = CLOUD_NAME;
    const unsignedUploadPreset = UPLOAD_PRESET.FILE;
    let formData = new FormData();
    formData.append("upload_preset", unsignedUploadPreset);
    formData.append("file", fileObject);
    setProgress(0);
    await axios
      .post(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((res) => {
        setProgress(100);
        const fileURL = res.data.secure_url;
        let tempFilesList = [...filesList];
        tempFile.url = fileURL;
        tempFilesList.push(tempFile);
        setFilesList(tempFilesList);
        toast.success("Upload Success");
        setProgress(100);
      })
      .catch((e) => {
        toast.error("Uploading Failed!");
        const tempPreviewFilesList = [...previewFilesList];
        tempPreviewFilesList.splice(tempPreviewFilesList.length, 1);
        setPreviewFilesList(tempPreviewFilesList);
        setProgress(0);
      });
  };

  const uploadFileToS3 = (fileObject, tempFile) => {
    setProgress(0);

    const uniqueSuffix = Date.now() + "_" + Math.round(Math.random() * 1e9);
    const filename = uniqueSuffix + "_" + fileObject.name;

    const ReactS3Client = new S3(config_file);
    // the name of the file uploaded is used to upload it to S3
    ReactS3Client.uploadFile(fileObject, filename)
      .then((data) => {
        const fileURL = data.location;
        let tempFilesList = [...filesList];
        tempFile.url = fileURL;
        tempFilesList.push(tempFile);
        setFilesList(tempFilesList);
        toast.success("Upload Success");
        setProgress(100);
      })
      .catch((err) => {
        toast.error("Uploading Failed!");
        const tempPreviewFilesList = [...previewFilesList];
        tempPreviewFilesList.splice(tempPreviewFilesList.length, 1);
        setPreviewFilesList(tempPreviewFilesList);
        setProgress(0);
      });
  };

  const uploadImageToS3 = (fileObject, tempFile, edited) => {
    setProgress(0);

    const uniqueSuffix = Date.now() + "_" + Math.round(Math.random() * 1e9);
    const filename = uniqueSuffix + "_" + fileObject.name;

    const ReactS3Client = new S3(config_image);
    // the name of the file uploaded is used to upload it to S3
    ReactS3Client.uploadFile(fileObject, filename)
      .then((data) => {
        const fileURL = data.location;
        let tempFilesList = [...filesList];
        tempFile.url = fileURL;
        if (edited) {
          tempFilesList[imageIndex] = tempFile;
          setFilesList(tempFilesList);
          toast.success("Upload Success");
          setProgress(100);
        } else {
          tempFilesList.push(tempFile);
          setFilesList(tempFilesList);
          toast.success("Upload Success");
          setProgress(100);
        }
      })
      .catch((err) => {
        toast.error("Uploading Failed!");
        const tempPreviewFilesList = [...previewFilesList];
        if (edited) {
          tempPreviewFilesList.splice(imageIndex.length, 1);
          setPreviewFilesList(tempPreviewFilesList);
        } else {
          tempPreviewFilesList.splice(tempPreviewFilesList.length, 1);
          setPreviewFilesList(tempPreviewFilesList);
          setProgress(0);
        }
      });
  };

  const uploadImageToCloudinaryViaServer = async (fileObject, index) => {
    let formData = new FormData();
    formData.append("image", fileObject);
    setProgress(0);
    await axios
      .post(`${API_URL}/api/v1/upload/cloudinary/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((res) => {
        setProgress(100);
        const fileURL = res.data.secure_url;
        const tempFilesList = [...filesList];
        tempFilesList[index].url = fileURL;
        setFilesList(tempFilesList);
        toast.success("Upload Success");
        setProgress(100);
      })
      .catch((e) => {
        toast.error("Uploading Failed!");
        const tempFilesList = [...filesList];
        tempFilesList.splice(index, 1);
        setFilesList(tempFilesList);
        setProgress(0);
      });
  };

  const uploadFileToCloudinaryViaServer = async () => { };

  const translate = async () => {
    // const clientLanguage = getLanguageFromCode(task.client.language);
    let clientLanguage = "en";
    if (typeof task.client.translatelanguage !== "undefined")
      clientLanguage = task.client.translatelanguage;
    else clientLanguage = "en";

    if (message) {
      await api
        .post(`${API_URL}/api/v1/message/translate/`, {
          message: message,
          language: clientLanguage,
        })
        .then((res) => {
          setMessage(decodeEntities(res.data.data.translations[0].translatedText));
        })
        .catch((err) => console.log(err));
    }
  };

  const getLanguageFromCode = (code) => {
    const clientLanguage = languageList.filter(
      (language) => language.value == code
    );
    return clientLanguage[0].code;
  };

  const saveEditedImage = async (blob) => {
    const tempPreviewFilesList = [...previewFilesList];

    const objectUrl = URL.createObjectURL(blob);
    const fileName = tempPreviewFilesList[imageIndex].filename + "-edited";
    const tempFile = {
      type: "image",
      previewurl: objectUrl,
      filename: fileName,
      url: "",
    };
    tempPreviewFilesList[imageIndex] = tempFile;

    setPreviewFilesList(tempPreviewFilesList);
    setProgress(0);
    const edited = true;
    await uploadImageToS3(blob, tempFile, edited);
  };

  const cancelEdit = () => {
    dispatch(setNew());
  };

  const cancelReplyMessage = () => {
    dispatch(setNew());
  };

  const handleSubmitFile = async () => {
    if (fileUrl !== "" && fileUrl !== undefined) {

      await api
        .post(`${API_URL}/api/v1/message/send/`, {
          files: [{ filename: "youTube", url: fileUrl }],
          images: [],
          message: '',
          taskId: task._id,
        })
        .then((res) => {
          setFileUrl("")
          setModalShow(false)
          socket.emit("new-message", res.data);
        })
        .catch((err) => {
          // if (err.response.status == 401) {
          //   navigate("/");
          // }

          // const errors = err.response.data.errors;
          // errors.forEach((error) => toast.error(error.msg));
        });
    }
  }

  const addEmoji = (emoji) => {
    setMessage(message + emoji.native);
  };

  const sendNewEmojiDefaultMessage = async () => {
    if (emojiDefault || filesList.length > 0) {
      setTyping(false);
      socket.emit("stop-typing", {
        room: task._id,
        user: { _id: user._id, username: user.username },
      });
      const tempMessage = emojiDefault;
      const tempFilesList = [...filesList];
      let imageList = [];
      let fileList = [];
      for (let i = 0; i < tempFilesList.length; i++) {
        if (tempFilesList[i].type === "image") {
          imageList.push(tempFilesList[i].url);
        } else
          fileList.push({
            filename: tempFilesList[i].filename,
            url: tempFilesList[i].url,
          });
      }

      const userInfo = getUserFromLocalStorage();

      // Message data
      const data = {
        sender: userInfo,
        message: tempMessage,
        images: imageList,
        files: fileList,
        task: task._id,
        createdAt: new Date(),
      };

      // After sent message, make input field clear
      setMessage("");
      setFilesList([]);
      setPreviewFilesList([]);
      setLineCount(1);

      // Add new message to message redux
      // dispatch(addMessage(data));

      // Update task update time
      dispatch(newMessage({ taskId: task._id, message: tempMessage }));

      // Add others(except me and sender) message unread count
      dispatch(
        addOthersUnread({
          taskId: task._id,
          userId: userInfo._id,
          senderId: userInfo._id,
        })
      );

      await api
        .post(`${API_URL}/api/v1/message/send/`, {
          message: tempMessage,
          images: imageList,
          files: fileList,
          taskId: task._id,
        })
        .then((res) => {
          dispatch(updateMessage(res.data));
          // After store message, emit message sent event
          socket.emit("new-message", res.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }

          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });

      textField.current.style.height = "28px";
      // await api.post(`${API_URL}/api/v1/task/latestMessage/`, ${})

      // await api.post(`${API_URL}/api/v1/task/read/`,{taskId : task._id}).then(res => {

      // })
    }
  };

  const [emojiDefault, setEmojiDefault] = useState("👍")

  const addEmojiDefault = () => {
    sendNewEmojiDefaultMessage();
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowEmojiPicker(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="message-input">
      <FullScreenImage
        image={image}
        fullScreenShow={fullScreenShow}
        setFullScreenShow={setFullScreenShow}
      />
      <FullScreenImageEditor
        image={image}
        fullScreenEditShow={fullScreenEditShow}
        setFullScreenEditShow={setFullScreenEditShow}
        saveEditedImage={saveEditedImage}
      />
      <TypingStatus />
      <hr />
      {messageInput.type == MESSAGE_TYPE.REPLY && (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <i className="bi bi-arrow-return-left"></i>
            </div>
            <div
              className="flex-auto reply-message"
              dangerouslySetInnerHTML={{
                __html: messageInput.message.replace(/\r*\n/g, "<br/>"),
              }}
            ></div>
            <div>
              <button
                className="reply-message-cancel"
                onClick={() => cancelReplyMessage()}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>

          <hr />
        </>
      )}
      {previewFilesList.length > 0 ? (
        <div className="image-input-pane">
          <div>
            <progress max="100" value={progress}></progress>
          </div>
          {previewFilesList.map((file, index) => (
            <>
              {file.type === "image" ? (
                <div key={index} className="d-inline-block position-relative">
                  <img
                    className="image-input"
                    src={file.previewurl}
                    onClick={() => {
                      setImage(file.previewurl);
                      setImageIndex(index);
                      setFullScreenShow(true);
                    }}
                  />
                  <button
                    className="image-delete"
                    onClick={() => onDeleteImagesAndFiles(index)}
                  >
                    <i className="bi bi-x-circle-fill"></i>
                  </button>
                  <button
                    className="image-edit"
                    onClick={() => {
                      setImage(file.previewurl);
                      setImageIndex(index);
                      setFullScreenEditShow(true);
                    }}
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </button>
                </div>
              ) : (
                <div key={index} className="d-inline-block position-relative">
                  <div className="file-input">
                    <div className="file-input-text">{file.filename}</div>
                  </div>
                  <button
                    className="image-delete"
                    onClick={() => onDeleteImagesAndFiles(index)}
                  >
                    <i className="bi bi-x-circle-fill"></i>
                  </button>
                </div>
              )}
            </>
          ))}
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex align-items-end justify-content-between">
        {/* <Popup
          trigger={
            <button className="blue-button text-white">
              <i className="bi bi-plus"></i>
            </button>
          }
          on="hover"
          position="top center"
          mouseLeaveDelay={100}
          mouseEnterDelay={100}
          arrow={false}
          contentStyle={{
            padding: "0px",
            border: "none",
            text: "center",
            background: "transparent",
            boxShadow: "none",
            textAlign: "center",
          }}
        >
          <div>
            <button className="pure-blue-button text-white">
              <i className="bi bi-film"></i>
            </button>
          </div>
          <div>
            <Upload
              name="image"
              onClick={handleUploadClick}
              onStart={handleUploadStart}
              onProgress={handleUploadProgress}
              onSuccess={handleUploadSuccess}
              onError={handleUploadError}
              action={`${API_URL}/api/v1/upload/cloudinary`}
              method="post"
              accept="image/png, image/jpeg"
              // multiple={true}
            >
              <button className="pure-blue-button text-white">
                <i className="bi bi-image"></i>
              </button>
            </Upload>
          </div>
          <div>
            {" "}
            <button className="pure-blue-button text-white">
              <i className="bi bi-fullscreen"></i>
            </button>
          </div>
          <div>
            <button className="pure-blue-button text-white">
              <i className="bi bi-file-earmark"></i>
            </button>
          </div>
        </Popup> */}
        {/* <Upload
          name="image"
          onClick={handleUploadClick}
          onStart={handleUploadStart}
          onProgress={handleUploadProgress}
          onSuccess={handleUploadSuccess}
          onError={handleUploadError}
          action={`${API_URL}/api/v1/upload/cloudinary/image`}
          method="post"
          accept="image/png, image/jpeg"
          // multiple={true}
        >
          <button className="blue-button text-white">
            <i className="bi bi-plus"></i>
          </button>
        </Upload> */}
        <div ref={ref}>
          <button onClick={() => setShowEmojiPicker(!showEmojiPicker)} style={{ border: "none", borderRadius: "20px", fontSize: "30px", margin: "0 8px", color: "#123" }}><i className="bi bi-emoji-laughing-fill" style={{ color: "#1F7E82" }}></i></button>
          {showEmojiPicker && (
            <Picker
              data={data}
              onEmojiSelect={addEmoji}
              style={{
                position: "absolute",
                marginTop: "465px",
                marginLeft: -40,
                maxWidth: "320px",
                borderRadius: "20px",
              }}
              theme="dark"
            />
          )}
        </div>
        <textarea
          onPaste={handlePaste}
          ref={textField}
          value={message}
          rows={lineCount}
          placeholder={t("type_a_message")}
          className="flex-auto"
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <div>
          <button onClick={addEmojiDefault} style={{ border: "none", borderRadius: "20px", fontSize: "30px", margin: "0 4px", color: "none", background: "none" }}>👍</button>
        </div>
        <div className="mx-2">
          <label htmlFor="upload-button">
            <div
              className="d-flex justify-content-center blue-button text-white align-items-center text-centerr"
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-plus"></i>
            </div>
          </label>
          <input
            type="file"
            // accept="image/png, image/jpeg"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleChange}
          />
        </div>
        {/* <textarea rows={2} /> */}
        <div className="d-flex align-items-center">
          {/* <button className="emoji-button">
            <i className="bi bi-emoji-smile"></i>
          </button> */}

          {user.role !== ROLE.CLIENT && !isMobile && (
            <button className="blue-button text-white mt-2" onClick={translate}>
              <i className="bi bi-translate"></i>
            </button>
          )}

          {(user.role === ROLE.DEV || user.role === ROLE.ADMIN) && <div className="mt-2">
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">YouTube URL Upload</Tooltip>}>
              <span className="d-inline-block">
                <button className="rounded-circle ms-2 pb-2 border-0 blue-button  shadow-lg" onClick={() => setModalShow(true)}>
                  <MdOutlineDriveFolderUpload size={25} color="white" />
                </button>
              </span>
            </OverlayTrigger>

          </div>}

          <div className="pt-2">
            {messageInput.type === MESSAGE_TYPE.EDIT ? (
              <button
                className="blue-button text-white ms-2"
                onClick={cancelEdit}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            ) : (
              <button
                className="blue-button text-white ms-2"
                onClick={sendMessage}
              >
                <i className="bi bi-cursor-fill"></i>
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="p-3">
          <div className="input-text"><h5>File Upload</h5></div>
          <div className="mt-3">
            {/* <label htmlFor="" className="">File Name</label><br /> */}
            {/* <input type="text" name="filename" value={inputs.filename || ''} placeholder="Youtube" onChange={handleChangeModal} /><br /> */}
            <label htmlFor="url">Url</label><br />
            <input type="url" name="url" id="url" value={fileUrl || ''} placeholder="Url" onChange={(e) => setFileUrl(e.target.value)} className="py-1" />
          </div>

          <div className="d-flex justify-content-center align-items-center gap-4 text-center">
            <button
              className="popup-join"
              onClick={() => handleSubmitFile()}
            >
              {t("Save")}
            </button>
            <button
              className="popup-join"
              onClick={() => setModalShow(false)}
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
