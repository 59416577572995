import React, { useEffect, useRef } from "react";
import "../../assets/css/FullScreenImage.css";
import { API_URL } from "../../constants/constants";
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";

const myTheme = {
  "header.display": "none",
};

export default function FullScreenImageEditor({
  image,
  fullScreenEditShow,
  setFullScreenEditShow,
  saveEditedImage,
}) {
  const ref = useRef(null);
  const tui = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setFullScreenEditShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const logImageContent = () => {
    let instance = tui.current.getInstance();
    let dataURL = instance.toDataURL();
    var blob = dataURLtoBlob(dataURL);
    saveEditedImage(blob);
    setFullScreenEditShow(false);
  };

  const dataURLtoBlob = (dataURL) => {
    var arr = dataURL.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  return (
    <>
      {fullScreenEditShow ? (
        <div className="full-screen-image d-flex align-items-center p-5">
          <div className="w-100 h-100 position-relative">
            <ImageEditor
              ref={tui}
              includeUI={{
                loadImage: {
                  path: image,
                  name: "SampleImage",
                },
                theme: myTheme,
                menu: [
                  "crop",
                  "flip",
                  "rotate",
                  "draw",
                  "icon",
                  "text",
                  "shape",
                  "filter",
                ],
                initMenu: "draw",
                uiSize: {
                  width: "100%",
                  height: "100%",
                },
                menuBarPosition: "bottom",
              }}
              cssMaxHeight={500}
              cssMaxWidth={700}
              selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70,
              }}
              usageStatistics={true}
            />
            <button className="image-edit-save" onClick={logImageContent}>
              Save
            </button>
          </div>
          {/* <img ref={ref} src={image} /> */}
          <button
            className="close-button"
            onClick={() => setFullScreenEditShow(false)}
          >
            <i className="bi bi-x-lg"></i>
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
