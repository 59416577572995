import React, { useState, useEffect } from "react";
import api from "../../utils/axios";
import { API_URL, CURRENCY } from "../../constants/constants";
import "../../assets/css/ClientBalance.css";
import "../../assets/css/TasksWithGiftCode.css"
import { useTranslation } from "react-i18next";
import { FormCheck } from "react-bootstrap";
import PayForReferrerModal from "./PayForReferrerModal";
import { setReferrerTask } from "../../redux/referrrerTaskSlice";
import { useDispatch, useSelector } from "react-redux";

export default function TasksWithGiftCode() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const referrerTasks = useSelector((state) => state.referrerTask);

    const [isLoading, setIsLoading] = useState(true);
    const [isShowModal, setIsShowModal] = useState(false);
    const [referrerSelected, setReferrerSelected] = useState({});
    const [costSelected, setCostSelected] = useState();
    const [isPaid, setIsPaid] = useState(false);
    const [taskId, setTaskId] = useState("");
    const [receiverId, setReceiverId] = useState("");
    // const [selectedTasks, setSelectedTasks] = useState([]);
    // const [selectedOption, setSelectedOption] = useState(0);

    // const handleSelect = (e) => {
    //     dispatch(filterTask(e.target.value));

    // }

    useEffect(() => {
        const getTasks = async () => {
            await api
                .get(`${API_URL}/api/v1/task/referrer`)
                .then((res) => {
                    dispatch(setReferrerTask(res.data));
                    setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false);
                })
                .finally(() => setIsLoading(false));
        };

        getTasks();
    }, [isShowModal]);

    const handleClickItem = (task) => {
        setReferrerSelected(task.referralCode);
        setCostSelected(task.cost);
        setIsPaid(task.isPaidToReferrer);
        setTaskId(task._id);
        setIsShowModal(true);
        setReceiverId(task.client);
    }

    return (
        <div>
            <div className="tasks-with-gife-code p-5">
                <div className="title">GIFT CODE (Referral Payment)</div>
                {
                    isLoading ? <div className="mt-5 p-5 d-flex justify-content-center">
                        <div className="dot-windmill"></div>
                    </div> : <div className="mt-3">
                        {/* <div className="mb-3">
                            <Form.Select aria-label="Default select example" style={{width: "180px"}} value={selectedOption} onChange={handleSelect} >
                                <option value="0">All</option>
                                <option value="1">Unpaid</option>
                                <option value="2">Paid</option>
                            </Form.Select>
                        </div> */}
                        {referrerTasks.length > 0 && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Task Name</th>
                                        <th>Cost</th>
                                        <th>Referral code</th>
                                        <th>{t("action")}</th>
                                        <th>Paid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {referrerTasks?.map((task, index) => (
                                        <tr key={index}>
                                            <td>{task.taskname}</td>
                                            <td style={{ textAlign: "center" }}>€{task.cost}</td>
                                            <td>{task.referralCode}</td>
                                            <td style={{ textAlign: "center" }}>
                                                <button style={!task.isPaidToReferrer ? { color: "#03656C", border: "1px solid #03656C" } : { color: "#000", border: "1px solid #000" }} className="action" onClick={() => handleClickItem(task)}>
                                                    <i class="bi bi-eye-fill"></i>
                                                </button>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <span style={{ marginLeft: "7px", fontStyle: "italic" }}>{task?.isPaidToReferrer ? <i style={{ fontSize: "15px" }} class="bi bi-check-lg"></i> : ""}</span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                }

                <PayForReferrerModal show={isShowModal} setShow={setIsShowModal} code={referrerSelected} cost={costSelected} isPaid={isPaid} taskId={taskId} receiverId={receiverId} />
            </div>
        </div>
    );
}
