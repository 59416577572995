import React from "react";
import "../assets/css/NotFound.css";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="not-found d-flex text-center align-items-center w-100">
      <div className="m-auto">
        <div className="title">{t("404_page")}</div>
        <div className="description">{t("we_are_sorry")}</div>
      </div>
    </div>
  );
}
