import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./redux/loginSlice";
import taskReducer from "./redux/taskSlice";
import currentReducer from "./redux/currentSlice";
import messageReducer from "./redux/messageSlice";
import messageInputReducer from "./redux/messageInputSlice";
import translationReducer from "./redux/translateSlice";
import userReducer from "./redux/userSlice";
import referrerTaskReducer from "./redux/referrrerTaskSlice"

export default configureStore({
  reducer: {
    login: loginReducer,
    task: taskReducer,
    current: currentReducer,
    message: messageReducer,
    messageInput: messageInputReducer,
    translation: translationReducer,
    user: userReducer,
    referrerTask: referrerTaskReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
