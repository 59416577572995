import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ROLE } from "../constants/constants";
import * as moment from "moment";
import api from "../utils/axios";
import { API_URL } from "../constants/constants";

// Async thunk to fetch tasks with pagination
export const fetchTasks = createAsyncThunk(
  'task/fetchTasks',
  async ({ page, filters }, { getState }) => {
    const { limit, taskname } = getState().task;
    const { status, priority, client, project } = filters;
    let url = `?taskname=${taskname}&status=${status}&project=${project}&priority=${priority}&client=${client}&page=${page}&limit=${limit}`;

    const resp = await api.get(`${API_URL}/api/v1/task/lazy-loading/${url}`);
    return resp.data;
  }
);

// Search tasks by name (replace the current list with new results)
export const searchTasks = createAsyncThunk(
  'task/searchTasks',
  async ({ filters, taskname }, { getState }) => {
    const { status, priority, client, project } = filters;
    let url = `?taskname=${taskname}&status=${status}&project=${project}&priority=${priority}&client=${client}&page=${1}&limit=${10}`;
    const resp = await api.get(`${API_URL}/api/v1/task/lazy-loading/${url}`);
    return resp.data;
  }
);

export const taskSlice = createSlice({
  name: "task",
  initialState: {
    tasks: [],
    totalTasks: 0,
    filters: {
      priority: 'All',
      status: 'All',
      client: '',
      project: ''
    },
    taskname: '',
    page: 1,
    limit: 10,
    hasMore: true,
    loading: false,
  },
  reducers: {
    // This is for logout
    deleteAllTasks: (state) => {
      state.tasks.splice(0, state.length);
    },
    addTask: (state, action) => {
      const result = state.tasks.filter((item) => item._id == action.payload._id);
      if (result.length == 0) state.tasks.push(action.payload);
      state.tasks.slice().sort((a, b) => moment(b.updatedTime).diff(moment(a.updatedTime)));
    },
    updateTask: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state[i]._id == task._id) {
          state.tasks[i].priority = task.priority;
          state.tasks[i].status = task.status;
          state.tasks[i].workeffort = task.workeffort;
          state.tasks[i].duedate = task.duedate;
          state.tasks[i].status = task.status;
          state.tasks[i].hourlyrate = task.hourlyrate;
          state.tasks[i].fasthourlyrate = task.fasthourlyrate;
          state.tasks[i].fastduedate = task.fastduedate;
          state.tasks[i].duringdays = task.duringdays;
          state.tasks[i].fastduringdays = task.fastduringdays;
          state.tasks[i].updatedTime = new Date();
        }
    },
    setTasks: (state, action) => {
      state.tasks.splice(0, state.length);
      const tasks = [...action.payload];
      for (let i = 0; i < tasks.length; i++) state.tasks.push(tasks[i]);
    },
    addEstimate: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == task._id) {
          state.tasks[i].workeffort = task.workeffort;
          state.tasks[i].duedate = task.duedate;
          state.tasks[i].status = task.status;
          state.tasks[i].hourlyrate = task.hourlyrate;
          state.tasks[i].fasthourlyrate = task.fasthourlyrate;
          state.tasks[i].fastduedate = task.fastduedate;
          state.tasks[i].duringdays = task.duringdays;
          state.tasks[i].fastduringdays = task.fastduringdays;
          state.tasks[i].updatedTime = new Date();
          state.tasks[i].updatedAt = new Date();
        }
    },
    addChangeOffer: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == task._id) {
          state.tasks[i].workeffort = task.workeffort;
          state.tasks[i].duedate = task.duedate;
          state.tasks[i].status = task.status;
          state.tasks[i].hourlyrate = task.hourlyrate;
          state.tasks[i].fasthourlyrate = task.fasthourlyrate;
          state.tasks[i].fastduedate = task.fastduedate;
          state.tasks[i].duringdays = task.duringdays;
          state.tasks[i].fastduringdays = task.fastduringdays;
          state.tasks[i].updatedTime = new Date();
        }
    },
    addConfirm: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == task._id) {
          state.tasks[i].selectedoffer = task.selectedoffer;
          state.tasks[i].cost = task.cost;
          state.tasks[i].status = task.status;
          state.tasks[i].duedate = task.duedate;
          state.tasks[i].fastduedate = task.fastduedate;
          state.tasks[i].updatedTime = new Date();
        }
    },
    addAssign: (state, action) => {
      const { task, role } = action.payload;

      if (role != ROLE.DEV) {
        for (let i = 0; i < state.tasks.length; i++)
          if (state.tasks[i]._id == task._id) {
            state.tasks[i].status = task.status;
            state.tasks[i].developer = task.developer;
            state.tasks[i].users = task.users;
            state.tasks[i].unread = task.unread;
            state.tasks[i].updatedTime = new Date();
          }
      } else {
        state.tasks.push(task);
      }
    },
    addChangeProgress: (state, action) => {
      const task = action.payload;

      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == task._id) {
          state.tasks[i].percent = task.percent;
          state.tasks[i].updatedTime = new Date();
        }
    },
    addComplete: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == task._id) {
          state.tasks[i].status = task.status;
          state.tasks[i].updatedTime = new Date();
        }
    },
    addPay: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == task._id) {
          state.tasks[i].status = task.status;
          state.tasks[i].updatedTime = new Date();
        }
    },
    deleteTask: (state, action) => {
      const taskId = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == taskId) {
          state.tasks.splice(i, 1);
          break;
        }
    },

    addUnread: (state, action) => {
      const { task, user } = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == task._id) {
          for (let j = 0; j < state.tasks[i].unread.length; j++)
            if (state.tasks[i].unread[j].user == user._id)
              state.tasks[i].unread[j].number++;
        }
    },
    removeUnread: (state, action) => {
      const { taskId, userId } = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == taskId) {
          for (let j = 0; j < state.tasks[i].unread.length; j++)
            if (state.tasks[i].unread[j].user == userId)
              state.tasks[i].unread[j].number = 0;
        }
    },
    newMessage: (state, action) => {
      const { taskId, message } = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == taskId) {
          {
            state.tasks[i].updatedTime = new Date();
            state.tasks[i].latestMessage = {
              message: "",
              createdAt: new Date(),
            };
            state.tasks[i].latestMessage.message = message;
            state.tasks[i].latestMessage.createdAt = new Date();
          }
        }
      state.tasks.slice().sort((a, b) => moment(b.updatedTime).diff(moment(a.updatedTime)));
    },

    addOthersUnread: (state, action) => {
      const { taskId, senderId, userId } = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == taskId) {
          for (let j = 0; j < state.tasks[i].unread.length; j++)
            if (
              state.tasks[i].unread[j].user != userId &&
              state.tasks[i].unread[j].user != senderId
            )
              state.tasks[i].unread[j].number++;
        }
    },

    addOtherRead: (state, action) => {
      const { taskId, userId } = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == taskId) {
          for (let j = 0; j < state.tasks[i].unread.length; j++)
            if (state.tasks[i].unread[j].user == userId)
              state.tasks[i].unread[j].number = 0;
        }
    },
    submitRateTask: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.tasks.length; i++)
        if (state.tasks[i]._id == task._id) {
          state.tasks[i].status = task.status;
          state.tasks[i].rating = task.rating;
          state.tasks[i].comment = task.comment;
          state.tasks[i].updatedTime = new Date();
        }
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.tasks = []; // Reset items for new filter
      state.page = 1; // Reset page for new filter
    },
    setTaskname: (state, action) => {
      state.taskname = action.payload;
    },
    setClientFilter: (state, action) => {
      state.filters.client = action.payload; // Update client filter with selected client ID
      state.tasks = []; // Reset tasks for new client
      state.page = 1; // Reset pagination
    },
    clearFilters: (state) => {
      return {
        ...state, filters: {
          priority: 'All',
          status: 'All',
          client: '',
          project: ''
        },
        taskname: '',
        page: 1,
        limit: 10,
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        // Sort Task by latest change
        let tempTask;
        tempTask = [...state.tasks].sort((a, b) => {
          return new Date(b.updatedTime).valueOf() - new Date(a.updatedTime).valueOf();
        });
        state.tasks = [...tempTask, ...action.payload.tasks];
        state.totalTasks = action.payload.totalTasks;
        state.hasMore = action.payload.hasMore;
        state.loading = false;
      })
      .addCase(fetchTasks.rejected, (state) => {
        state.loading = false;
      })
      // Handle searching/filtering (replace tasks)
      .addCase(searchTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchTasks.fulfilled, (state, action) => {
        state.tasks = [...action.payload.tasks];
        state.totalTasks = action.payload.totalTasks;
        state.hasMore = action.payload.hasMore;
        state.loading = false;
      })
      .addCase(searchTasks.rejected, (state) => {
        state.loading = false;
      })
  }
});

export const {
  deleteAllTasks,
  addTask,
  updateTask,
  setTasks,
  addEstimate,
  addChangeOffer,
  addConfirm,
  addAssign,
  addChangeProgress,
  addComplete,
  addPay,
  deleteTask,
  addUnread,
  removeUnread,
  newMessage,
  addOtherRead,
  addOthersUnread,
  submitRateTask,
  incrementPage,
  setFilters,
  setTaskname,
  setClientFilter,
  clearFilters
} = taskSlice.actions;

export default taskSlice.reducer;
