import { createSlice } from "@reduxjs/toolkit";
import { MESSAGE_TYPE } from "../constants/constants";

export const messageInputSlice = createSlice({
  name: "messageInput",
  initialState: {
    id: "",
    type: MESSAGE_TYPE.NEW,
    message: "",
  },
  reducers: {
    setNew: (state) => {
      state._id = "";
      state.type = MESSAGE_TYPE.NEW;
      state.message = "";
    },
    setReply: (state, action) => {
      const { id, message } = action.payload;
      state.id = id;
      state.type = MESSAGE_TYPE.REPLY;
      state.message = message;
    },
    setEdit: (state, action) => {
      const { id, message } = action.payload;
      state.id = id;
      state.type = MESSAGE_TYPE.EDIT;
      state.message = message;
    },
  },
});

export const { setNew, setReply, setEdit } = messageInputSlice.actions;

export default messageInputSlice.reducer;
